<template>
    <v-container grid>
    <v-row >
        <v-col >
            <v-app-bar dark color="success">
                <v-spacer></v-spacer>
                <v-toolbar-title> {{capitalize($t('your grade is'))+" " +currentGrade}}</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-app-bar>
        </v-col>
    </v-row>
    <v-row>
        <v-col
         v-for="(question,index) in contestData.questionInstances"  v-bind:key="index"
        class="d-flex child-flex"
        :cols="getCols()"
        >
        <v-card 
        class="mx-auto"
        width="374"
        dark
        :color="getColor(question)"
        >
             <v-img
                :src="question.imagePath"
                aspect-ratio="1"
                class="grey lighten-2"
            >
                <v-chip :color="getColor(question)">{{getInfluence(question)}}</v-chip>
                <v-spacer/>
                <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                        ></v-progress-circular>
                    </v-row>
                </template>
            </v-img>
             <v-card-actions>
                <v-spacer/>
                <v-btn
                color="grey darken-1"
                v-if="question.infoLink != ''" @click="getDetailsSOR(question)">
                 
                <v-icon>
                    mdi-dots-vertical
                </v-icon>
               
            </v-btn>
            </v-card-actions>
            <v-card-title>
                <v-container fill-height fluid>
                <v-row>
                    <v-col >
                        {{getCorrectName(question.correctAnswer)}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                    {{getCorrectNameLatin(question.correctAnswer)}}
                    </v-col>
                </v-row>
                </v-container>
            </v-card-title>
            <v-card-text>
            {{getDetails(question)}}
            </v-card-text>
            
           
        </v-card>
        </v-col>
    </v-row>
    <v-dialog v-model="detailsDialog"  width="800px" height="100%" >
        <v-card>
        <iframe  height="100%" style="width:100%" :src="detailsUrl"></iframe>
        </v-card>
    </v-dialog>
    </v-container>
</template>

<script>

export default {
    props: ['contestData'],
    data: () => ({
        detailsDialog: false,
        detailsUrl: ''
    }),
    mounted: function () {
        this.timer = null
    },
    computed: {
        currentGrade: function () {
            var grade = 0
            if(this.contestData.questionInstances)
            {
            this.contestData.questionInstances.forEach((question,index) => {
                grade += this.getInfluence(question)
            })
            }
            return grade
        }
    },
    methods: {
        getCols() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 12
                case 'sm': return 12
                case 'md': return 6
                case 'lg': return 4
                case 'xl': return 4
            }
        },
        getCorrectName(name) {
            if (name)
            return name.split("(")[0]
        },
        getCorrectNameLatin(name) {
            var res =  name.split("(")
            if(res.length > 1)
            {
                return res[1].replace(")","")
            }
            return ""
        }
        ,
        getDetailsSOR(q) {
            //this.detailsDialog = true
            this.detailsUrl = q.infoLink
            window.open(this.detailsUrl);
        },
        getInfluence(q) {
            var dontKnowAnswer  = this.capitalize(this.$t("don't know"))
           
            if(q.userAnswer == q.correctAnswer)
            {
                return 10.0/this.contestData.questionInstances.length
            }
            else
            if(q.userAnswer === dontKnowAnswer || !q.userAnswer)
            {
                return 0.0
            }
            else
            {
                return -10.0/this.contestData.questionInstances.length
            }
        },
        getColor(q) {
            var dontKnowAnswer  = this.capitalize(this.$t("don't know"))
           
            if(q.userAnswer == q.correctAnswer)
            {
                return "success"
            }
            else
            if(q.userAnswer === dontKnowAnswer || !q.userAnswer)
            {
                return "warning"
            }
            else
            {
                return "error"
            }
        },
        getTitle(q) {
            if(q.userAnswer == q.correctAnswer)
            {
                return q.userAnswer
            }
            else
            {
                return q.userAnswer + "-" + q.correctAnswer
            }
        },
        getDetails(q)
        {
            var dontKnowAnswer  = this.capitalize(this.$t("don't know"))
            if(q.userAnswer == q.correctAnswer)
            {
                return this.capitalize(this.$t('you were correct'))
            }
            else
            if(q.userAnswer === dontKnowAnswer || !q.userAnswer)
            {
                var str = this.capitalize(this.$t('the correct answer was ')) + q.correctAnswer
                return str
            }
            else
            {
                var str = this.capitalize(this.$t('you were wrong, correct answer is')) + " "
                + q.correctAnswer+ " "+ 
                this.$t('but you answered') + 
                " "+q.userAnswer
                return str
            }
        }
    },
    watch: {
        contestData: function (newVal /*,oldVal*/) { 
        
        }
    }


}
</script>
