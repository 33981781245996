<template>
    <v-card max-width="800" class="mx-auto">
        <v-card-title class="headline font-weight-regular justify-space-between">
            <span>{{currentTitle}}</span>
            <v-avatar
                color="primary lighten-2"
                class="subheading white--text"
                size="24"
                v-text="step"
            ></v-avatar>
        </v-card-title>
        <v-window v-model="step">
            <v-window-item :value="0">
                <general-info 
                :model="receivePayload.GeneralInfo" 
                v-on:saveContent="onSaveContent"/>
            </v-window-item>
            <v-window-item :value="1">
                <activatate-harnitoare 
                :model="receivePayload.Hranitori" 
                v-on:saveContent="onSaveContent"/>
            </v-window-item>
            <v-window-item :value="2">
                <activitate-coliziune 
                :model="receivePayload.Coliziune" 
                v-on:saveContent="onSaveContent"/>
            </v-window-item>
            <v-window-item :value="3">
                <activitate-spring-alive 
                :model="receivePayload.SpringAlive" 
                v-on:saveContent="onSaveContent"/>
            </v-window-item>
            <v-window-item :value="4">
                <activitate-mediu 
                :model="receivePayload.Mediu" 
                v-on:saveContent="onSaveContent"/>
            </v-window-item>
            <v-window-item :value="5">
                <activitate-olimpiada
                :model="receivePayload.Olimpiada" 
                v-on:saveContent="onSaveContent"/>
            </v-window-item>
            <v-window-item :value="6">
                <activitate-alte-detalii 
                :model="receivePayload.AlteDetalii" 
                v-on:saveContent="onSaveContent"/>
            </v-window-item>
        </v-window>
        <v-divider></v-divider>
        <v-card-actions>
            <v-container grid>
                <v-row>
                    <v-col cols = 2>
                        <v-btn
                            :disabled="step === 0"
                            text
                            @click="step--"
                        >
                            Back
                        </v-btn>
                    </v-col>
                    <v-col cols =8>
                        <v-item-group
                        v-model="step"
                        class="text-center"
                        mandatory
                        >
                        <v-spacer></v-spacer>
                        <v-item
                        v-for="n in length"
                        :key="`btn-${n}`"
                        v-slot="{ active, toggle }"
                        >
                            <v-btn
                                :input-value="active"
                                icon
                                @click="toggle"
                            >
                            <v-icon>mdi-record</v-icon>
                            </v-btn>
                        </v-item>
                    </v-item-group>
                    </v-col>
                    <v-col>
                        <v-btn
                            :disabled="isNextDisabled()"
                            color="primary"
                            depressed
                            @click="onPressNext()"
                        >
                            {{capitalize($t('next'))}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
    
</template>
<script>
import GeneralInfo from '@/components/activitati/0GeneralInfo'
import ActivitateHranitoare from '@/components/activitati/1ActivitateHranitoare'
import ActivitateColiziune from '@/components/activitati/2ActivitateColiziune'
import ActivitateSpringAlive from '@/components/activitati/3ActivitateSpringAlive'
import ActivitateMediu from '@/components/activitati/4ActivitateMediu'
import ActivitateOlimpiada from '@/components/activitati/5ActivitateOlimpiada'
import ActivitateAlteDetalii from '@/components/activitati/6ActivitateAlteDetalii'
import APIService from '@/services/APIService'

export default {
  props:['schoolId'],
  components: {
    'general-info':GeneralInfo,
    'activatate-harnitoare': ActivitateHranitoare,
    'activitate-coliziune': ActivitateColiziune,
    'activitate-spring-alive':ActivitateSpringAlive,
    'activitate-mediu': ActivitateMediu,
    'activitate-olimpiada': ActivitateOlimpiada,
    'activitate-alte-detalii': ActivitateAlteDetalii
  },
  mounted: function () {
      if(this.schoolId == -1)
      {
          this.localSchoolId = this.User.schoolId
      }
      else
      {
          this.localSchoolId = this.schoolId
      }
     this.refreshContent()
  },
  methods: {
      refreshContent(){
          APIService.getSchoolForm(this.UserAuthToken, this.localSchoolId).then(
          r => {
            this.receivePayload.GeneralInfo = JSON.parse(r.generalInfo)
            this.receivePayload.Hranitori = JSON.parse(r.hranitori)
            this.receivePayload.Coliziune = JSON.parse(r.coliziune)
            this.receivePayload.SpringAlive = JSON.parse(r.springAlive)
            this.receivePayload.Mediu = JSON.parse(r.mediu)
            this.receivePayload.Olimpiada = JSON.parse(r.olimpiada)
            this.receivePayload.AlteDetalii = JSON.parse(r.alteDetalii)
            this.receivePayload.schoolId = r.schoolId

            this.payload.SchoolId = r.schoolId
            this.payload.GeneralInfo = JSON.stringify(JSON.stringify(JSON.parse(r.generalInfo)))
            this.payload.Hranitori = JSON.stringify(JSON.stringify(JSON.parse(r.hranitori)))
            this.payload.Coliziune = JSON.stringify(JSON.stringify(JSON.parse(r.coliziune)))
            this.payload.SpringAlive = JSON.stringify(JSON.stringify(JSON.parse(r.springAlive)))
            this.payload.Mediu = JSON.stringify(JSON.stringify(JSON.parse(r.mediu)))
            this.payload.Olimpiada = JSON.stringify(JSON.stringify(JSON.parse(r.olimpiada)))
            this.payload.AlteDetalii = JSON.stringify(JSON.stringify(JSON.parse(r.alteDetalii)))

          }
      )
      },
        onSaveContent(data) {
            var type = data.contentType 
            delete data.contentType
            this.payload.LastModified = new Date()
            var str = JSON.stringify(JSON.stringify(data))
            this.payload[type] = str
            this.payload.SchoolId = this.localSchoolId
            var sendPayload = JSON.stringify(this.payload)
            APIService.createSchoolForm(this.UserAuthToken, sendPayload).then(
                response => {
                    this.receivePayload.GeneralInfo = JSON.parse(response.GeneralInfo)
                    this.receivePayload.Hranitori = JSON.parse(response.Hranitori)
                    this.receivePayload.Coliziune = JSON.parse(response.Coliziune)
                    this.receivePayload.SpringAlive = JSON.parse(response.SpringAlive)
                    this.receivePayload.Mediu = JSON.parse(response.Mediu)
                    this.receivePayload.Olimpiada = JSON.parse(response.Olimpiada)
                    this.receivePayload.AlteDetalii = JSON.parse(response.AlteDetalii)
                }
            ) 
        },
        onPressNext(){
            this.step++
        },
        isNextDisabled(){
          if(this.step === this.length)
            return true;
        return false; 
      }
  },
  computed: {
  },
  watch: {
      step: function(newValue) {
          this.refreshContent()
      }
  },
  data: () => ({
     payload:{
         id: -1,
         LastModified: new Date(),
         GeneralInfo:"",
         Hranitori:"",
         Coliziune:"",
         SpringAlive:"",
         Mediu: "",
         Olimpiada: "",
         AlteDetalii: "",
         Edition: 1,
         SchoolId:-1
     },
    receivePayload: {
         id: -1,
         LastModified: new Date(),
         GeneralInfo:"",
         Hranitori:"",
         Coliziune:"",
         SpringAlive:"",
         Mediu: "",
         Olimpiada: "",
         AlteDetalii: "",
         Edition: 1,
         SchoolId:-1
     },
    isLoading: false,
    step: 0,
    localSchoolId: -1,
    length: 7
  }),
  computed: {
      currentTitle () {
        switch (this.step) {
          case 0: return this.capitalize(this.$t('project team'))
          case 1: return this.capitalize(this.$t('feeders'))
          case 2: return this.capitalize(this.$t('colision'))
          case 3: return this.capitalize(this.$t('spring alive'))
          case 4: return this.capitalize(this.$t('mediu'))
          case 5: return this.capitalize(this.$t('olimpiada'))
          case 6: return this.capitalize(this.$t('detalii'))
          default: return this.capitalize(this.$t('nimic'))
        }
      },
    }
}
</script>
