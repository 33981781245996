import axios from 'axios'

export default {
  getRegions() {
    return axios.get('/schools/region').then(response => {
      return response.data
    })
  },
  getCounties(id) {
    return axios.get('/schools/countybyregion/'+id).then(response => {
      return response.data
    })
  },
  getCities(id) {
    return axios.get('/schools/citybycounty/'+id).then(response => {
      return response.data
    })
  },
  getSchools(id) {
    return axios.get('/schools/schoolsbycity/'+id).then(response => {
      return response.data
    })
  },
  getAllCities(authToken) {
    return axios.get('/schools/cities', {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(response => {
      return response.data
    })
  },
  getAllCounties(authToken) {
    return axios.get('/schools/county', {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(response => {
      return response.data
    })
  },
  getAllSchools(authToken) {
    return axios.get('/schools', {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(response => {
      return response.data
    })
  },
  getSchoolLink(id) {
    return axios.get('/schools/link/'+id).then(response => {
        if (response)
          return response.data
        return null
    })
  },
  getLocalizations () {
    return axios.get('/localization').then(response => {
      return response.data
    })
  },
  getGdprLocalization(lang) {
    return axios.get('/localization/gdpr?lang='+lang).then(response => {
      return response.data
    })
  },
  getSpecies (authToken) {
    return axios.get('/species/', {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(response => {
      return response.data
    })
  },
  getSpeciesWithImages (authToken) {
    return axios.get('/species?withImages=true', {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(response => {
      return response.data
    })
  },
  getSpeciesDetail (authToken, id) {
    return axios.get('/species/' + id + '/',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  updateSpeciesDetail (authToken, data) {
    return axios.post('/species/' + data.id + '/', data,
      {
        headers:
      { Authorization: `Bearer ${authToken}`
      }
      }).then(response => {
      return response.data
    })
  },
  updateSpeciesImage (authToken, data) {
    return axios.patch('/species/' + data.id + '/', data,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  createSpecies (authToken, data) {
    return axios.put('/species/', data,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  deleteSpecies (authToken, data) {
    return axios.delete('/species/' + data.id + '/',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  createSpeciesImage (authToken, data) {
    return axios.put('/image/', data,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  deleteSpeciesImage (authToken, data) {
    return axios.delete('/image/' + data.id + '/',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  createLocalization (authToken, data) {
    return axios.put('/localization/', data,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  updateLocalization (authToken, data) {
    return axios.post('/localization/' + data.id + '/', data,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  deleteLocalization (authToken, data) {
    return axios.delete('/localization/' + data.id + '/',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  getAuthToken (_username, _password) {
    var data = { email: _username, password: _password }

    return axios.post('user/authenticate', data).then(response => {
      return response.data
    })
  },
  getAuthTokenRefresh (authToken, refreshToken) {
    var data = { token: authToken, refreshToken: refreshToken }

    return axios.post('user/refresh', data)
  },
  getLanguages () {
    return axios.get('/language/').then(response => {
      return response.data
    })
  },
  getExercise(data) {
    return axios.patch(`contestinstance/exercise/`, data).then(response => {
        return response.data
      })
  },
  deleteContestResults(authToken, id){
    return axios.delete(`contestinstance/forcontest/${id}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(response => {
      return response.data
    })
  },
  getContestResults(authToken, id) {
    return axios.get(`contestinstance/${id}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(response => {
      return response.data
    })
  },
  getContestResultsForUser(authToken, userId, contestId) {
    return axios.get(`contestinstance/${contestId}/user/${userId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(response => {
      return response.data
    })
  },
  answerQuestion (authToken,contestId, questionId, data) {
    return axios.put(`contestinstance/${contestId}/answerquestion/${questionId}/`, data,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      })
  },
  getContestsResults (authToken, id) {
    return axios.get('/contest/'+id+'/results',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  giveTry(authToken, contestId, userId) {
    return axios.post(`/contestinstance/${contestId}/givetry/${userId}`, {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  getContests (authToken) {
    return axios.get('/contest/',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  getContestsForUser (authToken, userId) {
    return axios.get('/contest/all/'+userId,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  createContest (authToken, data) {
    return axios.put('/contest/', data,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  createUser (data) {
    return axios.post('/user/register', data, 
      {
        validateStatus: function (status) {
        return true; 
      }
      })
  },
  deleteLoadAgents (authToken){
    return axios.delete(`/user/agents`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  deleteInactiveUsers (authToken){
    return axios.delete(`/user/inactive`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  deleteUser (authToken, id){
    return axios.delete(`/user/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  getUserInfo (authToken, id) {
    return axios.get(`/user/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  getAllUsersInfo (authToken, page, itemsPerPage, search, searchBy) {
    return axios.get(`/user`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        params: {
          page: page,
          itemsPerPage: itemsPerPage,
          search: search,
          searchBy: searchBy
        }
      }).then(response => {
      return response.data
    })
  },
  setUserLanguage (authToken, payload) {
    return axios.patch(`/user/lang`, payload,
    {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(response => {
    return response.data
    })
  },
  updateGDPRUserInfo (authToken, payload) {
    return axios.patch(`/user/gdpr`, payload,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  updateUserInfo (authToken, payload, id) {
    return axios.post(`/user/${id}`, payload,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  startActivateAccount (authToken) {
    return axios.post(`/user/activate`, {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  activateAccount (authToken, token) {
    return axios.patch(`/user/activate`, {'token':token},
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response
    })
  },
  startResetPassword (authToken, email) {
    return axios.post(`/user/reset`, {'email':email},
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  resetPassword (token, newPassword) {
    return axios.patch(`/user/reset`, {'token':token, 'newPassword':newPassword},
      {
      }).then(response => {
      return response
    })
  },
  createContestUser (authToken, contestid) {
    var payload = {'ContestId': contestid}
    return axios.put('/contestinstance/', payload,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  getContestUser (authToken, contestid) {
    var payload = {'ContestId': contestid}
    return axios.post('/getcontest/', payload,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  getContest (authToken, id) {
    return axios.get(`/contest/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  finishContest (authToken, id) {
    var payload = {}
    return axios.patch(`/contestinstance/${id}`, payload,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  deleteContest (authToken, id) {
    return axios.delete(`/contest/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  duplicateContest (authToken, id) {
    return axios.post(`/contest/${id}/duplicate`, {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  passwordResetRequest (email) {
    return axios.post('/password_reset/', email).then(response => {
      return response
    })
  },
  passwordUpdateRequest (payload) {
    return axios.post('/password_reset/confirm/', payload).then(response => {
      return response
    })
  },
  getCladas (authToken) {
    return axios.get('/clada/',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  createClada (authToken, payload) {
    return axios.put('/clada/', payload,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  updateClada (authToken, data) {
    return axios.patch('/clada/' + data.id + '/', data,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  deleteClada (authToken, data) {
    return axios.delete('/clada/' + data.id + '/',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  createAudienceGroup(authToken, payload) {
    return axios.put('/audiencegroup', payload,
    {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(response => {
    return response.data
  })
  },
  updateAudienceGroup (authToken, data) {
    return axios.post('/audiencegroup/'+ data.id + '/', data,
    {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(response => {
    return response.data
  })
  },
  deleteAudienceGroup (authToken, data) {
    return axios.delete('/audiencegroup/' + data.id + '/',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  createAudienceGroupItem(authToken, payload) {
    return axios.put('/audiencegroup/item', payload,
    {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(response => {
    return response.data
  })
  },
  updateAudienceGroupItem (authToken, data) {
    return axios.post('/audiencegroup/item/'+ data.id + '/', data,
    {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(response => {
    return response.data
  })
  },
  deleteAudienceGroupItem (authToken, data) {
    return axios.delete('/audiencegroup/item/' + data.id + '/',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  getAudienceGroupItems (authToken) {
    return axios.get('/audiencegroup/item/',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  getAudienceGroups (authToken) {
    return axios.get('/audiencegroup/',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  getFamilies (authToken) {
    return axios.get('/family/',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  createFamily (authToken, payload) {
    return axios.put('/family/', payload,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  updateFamily (authToken, data) {
    return axios.patch('/family/' + data.id + '/', data,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  deleteFamily (authToken, data) {
    return axios.delete('/family/' + data.id + '/',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  getContestDetails (authToken, id) {
    return axios.get('/contest/' + id,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  getQuestionFreeMode (difficulty, nomenclature) {
    var data = { difficultyType: difficulty, nomenclatureType: nomenclature }
    return axios.get('/question', {
      params: {
        ...data
      }
    }).then(response => {
      return response.data
    })
  },
  getExerciseTest (difficulty, nomenclature, numQuestions) {
    var data = { difficultyType: difficulty, nomenclatureType: nomenclature, num_questions: numQuestions }
    return axios.get('/exercise', {
      params: {
        ...data
      }
    }).then(response => {
      return response.data
    })
  },
  getAllSchoolForms(authToken) {
    return axios.get('/schoolform/',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  getSchoolForm(authToken, schoolId) {
    return axios.get('/schoolform/' + schoolId,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  },
  uploadSchoolFormImage(authToken, image) {
    return axios.put('/schoolform/images', image,
    {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(response => {
      return response.data
    })
  },
  createSchoolForm (authToken, payload) {
    return axios.put('/schoolform', payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
        return response.data
    })
  },
  createSchool (authToken, payload) {
    return axios.put('/schools', payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
        return response.data
    })
  },
  deleteSchool (authToken, data) {
    return axios.delete('/schools/' + data.id + '/',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(response => {
      return response.data
    })
  },
  getUserLogs (queryParams) {
    return axios.get("https://loki.raduangelescu.com/loki/api/v1/query_range", {params: queryParams,})
  }
}
