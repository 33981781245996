<template>
    <v-container>
      <v-card    :loading="isLoading">
        <v-card-title>
          {{capitalize($t('users'))}}
          <v-spacer></v-spacer>
              
          </v-card-title>
          <v-container>
            <v-row>
                <v-col>
            <v-text-field
                  v-model="search"
                  append-icon="mdi-text-search"
                  :label="capitalize($t('search'))"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-select :label="capitalize($t('by'))" v-model="searchBy" :items="searchCateogries"></v-select>
              </v-col>
              <v-col>
                <v-btn color="success"  @click="searchContent()" >{{capitalize($t('search'))}}</v-btn>
              </v-col>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="users"
                class="elevation-1"
                :loading="isLoading"
                hide-default-footer
                :items-per-page="itemsPerPage"
                dense
              >
               <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="700px" >
                      <profile-data-component mode="adminedit" :userid="selectedUserId"></profile-data-component>
                    </v-dialog>
                </v-toolbar>
              </template>
                <template v-slot:[`item.name`]="props">
                    <v-edit-dialog
                    :return-value.sync="props.item.name"
                    @save="save(props.item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                    > {{ props.item.name }}
                      <template v-slot:input>
                        <div class="mt-4 title">{{capitalize($t('update name'))}}</div>
                        <v-text-field
                          v-model="props.item.name"
                          :label="$t('edit')"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                    <v-btn icon fab small>
                    <v-icon
                        small
                        color="red"
                        @click="deleteItem(item)"
                        align-end
                    >
                        mdi-delete
                    </v-icon>
                    </v-btn>
                     <v-btn icon fab small>
                    <v-icon
                        small
                        color="warning"
                        @click="editItem(item)"
                        align-end
                    >
                        mdi-pencil
                    </v-icon>
                    </v-btn>
                    <v-btn icon fab small>
                    <v-icon
                        small
                        color="success"
                        @click="userLogs(item)"
                        align-end
                    >
                        mdi-history
                    </v-icon>
                    </v-btn>
                </template>
                
              </v-data-table>
              <div class="text-center pt-2">
              <v-row>
                <v-col>
              <v-pagination
                v-model="page"
                :length="pageCount"
                total-visible=5
              >
            </v-pagination>
          </v-col>
          <v-col>
            <v-select
                    v-model="itemsPerPage"
                    
                    :label="$t('Items per page')"
                    :items="itemsPerPageCategories"
              ></v-select>  
            </v-col>
            </v-row>
          </div>
              <v-btn color="error" @click="deleteLoadAgents()" :disabled="isLoading"> {{capitalize($t('delete load test accounts'))}}</v-btn>
              <v-btn color="error" @click="deleteInactiveUsers()" :disabled="isLoading"> {{capitalize($t('delete inactive accounts'))}}</v-btn>
              
              <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                {{ snackText }}
                <v-btn text @click="snack = false" >{{capitalize($t('close'))}}</v-btn>
              </v-snackbar>
            </v-container>
   </v-card>

    </v-container>
</template>
<script>
import APIService from '@/services/APIService'
import ProfileDataComponent from '@/components/ProfileDataComponent'

export default {
  components: {'profile-data-component': ProfileDataComponent},
  mounted: function () {
    this.refreshContent()
  },
  watch: {
      page: {
        handler () {
          this.refreshContent()
        },
        deep: true,
      },
      itemsPerPage: {
        handler () {
          this.refreshContent()
        },
        deep: true,
      },
  },
  methods: {
    close () {
      this.dialog = false
    },
    editItem(item) {
        this.selectedUserId = item.id
        this.dialog = true
    },
    userLogs(item) {
      this.$router.push({ path: 'userhistory/' + item.id, params: { id: item.id } }).catch(err => {})
    },
    searchContent() {
      this.page = 1;
      this.refreshContent();
    },
    refreshContent() {
        this.isLoading = true
        APIService.getAllUsersInfo(this.UserAuthToken, this.page-1, this.itemsPerPage, this.search, this.searchBy).then((data) => {
          this.users = data.users
          this.pageCount = data.numberOfTotalPages;
          this.userCount = data.numberOfTotalUsers;
          this.isLoading = false
        })
    },
    deleteInactiveUsers() {
      APIService.deleteInactiveUsers(this.UserAuthToken).then(response => {
        this.isLoading = true
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.capitalize(this.$t('deleting inactive users'))
        this.refreshContent()
      })
    },
    deleteLoadAgents() {
      APIService.deleteLoadAgents(this.UserAuthToken).then(response => {
        this.isLoading = true
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.capitalize(this.$t('deleting load test agents'))
        this.refreshContent()
      })
    },
 
    deleteItem (item) {
      APIService.deleteUser(this.UserAuthToken, item.id).then(response => {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.capitalize(this.$t('data deleted'))
        this.refreshContent()
      })
      this.snack = true
      this.snackColor = 'warning'
      this.snackText = this.capitalize(this.$t('deleting'))
    },
   
    cancel () {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = this.capitalize(this.$t('canceled'))
    },
    open () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = this.capitalize(this.$t('dialog opened'))
    }
  },
  computed: {
    headers: function () {
      return [
        {text: this.$t('id'), value: 'id', sortable: false},
        {text: this.$t('email'), value: 'email', sortable: false},
        {text: this.$t('first name'), value: 'firstName', sortable: false},
        {text: this.$t('last name'), value: 'lastName', sortable: false},
        {text: this.$t('is activated'), value: 'isActivated', sortable: false},
        {text: this.$t('is admin'), value: 'isAdmin', sortable: false},
        {text: this.$t('newsletter'), value: 'newsletter', sortable: false},
        {text: this.$t('telephone number'), value: 'telephoneNumber', sortable: false},
        
        {text: this.$t('actions'), value: 'action', sortable: false, align: 'right'}]
    }
  },
  data: () => ({
    isLoading: false,
    users: [],
    selectedUserId: -1,
    snack: false,
    snackColor: '',
    snackText: '',
    search: '',
    searchBy: '',
    searchCateogries: ['', 'id', 'email', 'name', 'telephone_number'],
    itemsPerPageCategories: [10, 50, 100, 500],
    dialog: false,
    page: 1,
    pageCount: 0,
    userCount: 0,
    itemsPerPage: 10,
  })
}
</script>
