<template>
<v-card :loading="isLoading">
    <v-container>
    <v-btn color="warning" @click="back()">{{capitalize($t('back'))}}</v-btn>
  
      <v-card v-for="item in resultsDisplay" :key="item.name">
        <v-card-actions>
        <v-btn color="primary" @click="download(item)">{{capitalize($t('download'))}}</v-btn>
        <v-btn color="primary" @click="downloadCensored(item)">{{capitalize($t('download_censored'))}}</v-btn>
        </v-card-actions>
  
        <v-card-title>
          {{item.name}}
          <v-spacer></v-spacer>
           
          <v-text-field
                v-model="item.search"
                append-icon="mdi-text-search"
                :label="capitalize($t('search'))"
                single-line
                hide-details
              ></v-text-field>
          </v-card-title>
            <v-container >

              <v-data-table
                :headers="headers"
                :items="item.results"
                :items-per-page.sync="itemsPerPage"
                :search="item.search"
                class="elevation-1"
                multi-sort
                :sort-by="['time', 'grade']"
                :sort-desc="[false, true]"
                :footer-props="itemsPerPageOptions"
              >
              <v-text-field
                v-model="item.search"
                append-icon="mdi-text-search"
                :label="capitalize($t('search'))"
                single-line
                hide-details
              ></v-text-field>
               <template v-slot:[`item.description`]="{ item }">
              <v-icon :color="item.status.color" >{{item.status.icon}}</v-icon>
              </template>
              <template v-slot:[`item.action`]="{ item }">

              </template>
              </v-data-table>

            </v-container>
   </v-card>
          
    </v-container>

    </v-card>
</template>
<script>
import APIService from '@/services/APIService'
import XLSX from 'xlsx'
import { saveAs } from 'file-saver'

export default {
  mounted: function () {
    this.contestId = this.$route.params.id
    this.isLoading = true
    this.refreshResults()
  },
  methods: {
    back(){
      this.$router.push({ name: 'AdminContestListLayout'}).catch(err => {})
    },
       s2ab: function(s) { 
                var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
                var view = new Uint8Array(buf);  //create uint8array as viewer
                for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
                return buf;    
     },
      download: function(item) {
            var wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "Rezultate concursuri QUIZZ SOR",
                Author: "Sor.ro",
                CreatedDate: new Date()
            }
            for(var itemNum in this.resultsDisplay)
            { 
                var results = []
                var textHeaders = this.headers.map(x => x.text)
                results= [textHeaders]
                var item = this.resultsDisplay[itemNum]
                var contestName = item.name.substring(0, 30);
                
                for(var rs in item.results) {
                    results.push(Object.values(item.results[rs]))
                }
                wb.SheetNames.push(contestName);
                var ws_data = results;
                var ws = XLSX.utils.aoa_to_sheet(ws_data);
                wb.Sheets[contestName] = ws;
            }
            var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
            saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), 'rezultate.xlsx');
      },
      downloadCensored: function(item) {
            var wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "Rezultate concursuri QUIZZ SOR publice",
                Author: "SOR.ro",
                CreatedDate: new Date()
            }
            var textHeaders = this.headers.filter(x => x.censored == false).map(x => x.text);
            var filteredHeaders = this.headers.filter(x => x.censored == false);
            console.log(filteredHeaders)
            for(var itemNum in this.resultsDisplay)
            { 
                var results = []
                
                results= [textHeaders]
                var item = this.resultsDisplay[itemNum]
                var contestName = item.name.substring(0, 30);
                
                for(var rs in item.results) {
                    var object = item.results[rs]
                    var info = []
                    for (var headerId in filteredHeaders)
                    {  
                      info.push(object[filteredHeaders[headerId].value])
                    }
                    results.push(info)
                }
                wb.SheetNames.push(contestName);
                var ws_data = results;
                var ws = XLSX.utils.aoa_to_sheet(ws_data);
                wb.Sheets[contestName] = ws;
            }
            var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
            saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), 'rezultate_cenzurate.xlsx');
      },
    refreshResults: function () {
      this.isLoading = true
      APIService.getContestsResults(this.UserAuthToken, this.contestId).then(results => {
        this.resultsDisplay = []
        var map = {}
        results.forEach(result => {
          if(result == null)
          {
            return
          }
          if(!(result.contestName in map))
          {
              map[result.contestName] = []
          }
          map[result.contestName].push({
            id: result.userId,
            name: result.name +" "+result.surname,
            telephone: result.telephoneNumber,
            city: result.city,
            county: result.county,
            school: result.school,
            dateOfBirth: result.dateOfBirth,
            class: result.class,
            actingTeacher: result.actingTeacher,
            grade: result.grade,
            time: result.time,
            region: result.region,
            solvingDate: result.solvingDate,
            ocupation: this.possibleRoles[result.ocupation].text
          })
        })
        for (var contestName in map) 
        {
            results = map[contestName]
            results.sort((a, b) => {
                if(a.grade == b.grade){
                    return a.time - b.time
                }
                return b.grade - a.grade
            });
            this.resultsDisplay.push({
                name:contestName,
                results:results,
                search:''
            })
        }
        this.isLoading = false
      })
    }
  },
  computed: {
    headers: function () {
      return [
        {text: this.capitalize(this.$t('userId')), censored: false, value: 'id'},
        {text: this.capitalize(this.$t('name')), censored: true, value: 'name'},
        {text: this.capitalize(this.$t('ocupation')), censored: true, value: 'ocupation'},
        {text: this.capitalize(this.$t('telephone')), censored: true, value: 'telephone'},
        {text: this.capitalize(this.$t('city')), censored: false, value: 'city'},
        {text: this.capitalize(this.$t('county')), censored: false, value: 'county'},
        {text: this.capitalize(this.$t('school')), censored: false, value: 'school'},
        {text: this.capitalize(this.$t('date of birth')), censored: true, value: 'dateOfBirth'},
        {text: this.capitalize(this.$t('class of student')), censored: false, value: 'class'},
        {text: this.capitalize(this.$t('acting teacher')), censored: true, value: 'actingTeacher'},
        {text: this.capitalize(this.$t('grade')), censored: false, value: 'grade'},
        {text: this.capitalize(this.$t('time')), censored: false, value: 'time'},
        {text: this.capitalize(this.$t('region')), censored: false, value: 'region'},
        {text: this.capitalize(this.$t('solvingDate')), censored: false, value: 'solvingDate'}
        ]
    },
  },
  data: () => ({
    isLoading: true, 
    resultsDisplay: [],
    search: '',
    contestId: 0,
    itemsPerPageOptions: {"items-per-page-options": [50, 100, 1000, -1]},
    itemsPerPage: 50,
  })
}
</script>
