<template>
  <v-container>
    <profile-data-component mode="profile" userid=-1></profile-data-component>
  </v-container>
</template>
<script>
import ProfileDataComponent from '@/components/ProfileDataComponent'
export default {
  components: {'profile-data-component': ProfileDataComponent},
  mounted: function () {

  }
}
</script>
