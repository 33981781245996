<template>
    <v-container>
      <v-card>
          <v-dialog v-model="dialog">

              <form-component :schoolId = "localSchoolId"></form-component>
          </v-dialog>
        <v-card-title>
          {{capitalize($t('check lists'))}}
          <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-text-search"
                  :label="capitalize($t('search'))"
                  single-line
                  hide-details
                ></v-text-field>
          </v-card-title>
          <v-container>
              <v-data-table
                :headers="headers"
                :items="schoolForms"
                :items-per-page="100"
                :search="search"
                :loading="isLoading"
                class="elevation-1"
              >
         
              <template v-slot:[`item.name`]="props">
                    <v-edit-dialog
                    :return-value.sync="props.item.name"
                    @save="save(props.item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                    > {{ props.item.name }}
                      <template v-slot:input>
                        <div class="mt-4 title">{{$t('update name')}}</div>
                        <v-text-field
                          v-model="props.item.name"
                          :label="$t('edit')"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                    <v-icon
                        small
                        color="warning"
                        @click="editItem(item)"
                        align-end
                    >
                        mdi-pencil
                    </v-icon>
                </template>

              </v-data-table>

             <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false">{{capitalize($t("close"))}}</v-btn>
    </v-snackbar>
            </v-container>
   </v-card>

    </v-container>
</template>
<script>
import APIService from '@/services/APIService'
import FormComponent from '@/components/FormComponent'

export default {
    components: {
    'form-component':FormComponent,
    },
  mounted: function () {
      
    this.refreshContent()
  },
  methods: {
    refreshContent() {
      this.isLoading = true
      APIService.getAllSchoolForms(this.UserAuthToken).then((data) => {
            this.schoolForms = data
            this.isLoading = false
          })
    },
    close () {
      this.dialog = false
    },
    createNewClada: function () {
      APIService.createClada(this.UserAuthToken, {name: this.newCladaName}).then((/*response*/) => {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.capitalize(this.$t('data created'))
        this.dialog = false
        this.refreshContent()
      })
      this.snack = true
      this.snackColor = 'warning'
      this.snackText = this.capitalize(this.$t('creating clada'))
    },
    editItem(item) {
        this.dialog = true
        this.localSchoolId=  item.schoolId
    },
    open () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = this.capitalize(this.$t('dialog opened'))
    }
  },
  computed: {
    headers: function () {
      return [
        {text: this.capitalize(this.$t('email')), value: 'email', align: 'left'},
        {text: this.capitalize(this.$t('school')), value: 'school', sortable: false, align: 'right'},
        {text: this.capitalize(this.$t('actions')), value: 'action', sortable: false, align: 'right'}]
    }
  },
  data: () => ({
    dialog: false,
    localSchoolId: -1,
    isLoading: false,
    schoolForms: [],
    snack: false,
    snackColor: '',
    snackText: '',
    search: ''
  })
}
</script>
