<template>
    <v-container>
      <v-card>
        <v-card-title>
          {{capitalize($t('family'))}}
          <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-text-search"
                  :label="capitalize($t('search'))"
                  single-line
                  hide-details
                ></v-text-field>

          </v-card-title>
          <v-container>
              <v-data-table
                :headers="headers"
                :items="strings"
                :items-per-page="5"
                :search="search"
                :loading="isLoading"
                class="elevation-1"
              >
               <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px" >
                        <template v-slot:activator="{ on }">
                            <v-btn iclass="mx-2" fab small color="primary" v-on="on">
                              <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ capitalize($t('create new family')) }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                          <v-text-field :label="capitalize($t('clada name'))" v-model="newFamilyName"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">{{capitalize($t('cancel'))}}</v-btn>
                                <v-btn color="blue darken-1" text @click="createNewFamily">{{capitalize($t('save'))}}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
              </template>
                <template v-slot:[`item.name`]="props">
                    <v-edit-dialog
                    :return-value.sync="props.item.name"
                    @save="save(props.item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                    > {{ props.item.name }}
                      <template v-slot:input>
                        <div class="mt-4 title">{{capitalize($t('update name'))}}</div>
                        <v-text-field
                          v-model="props.item.name"
                          :label="$t('edit')"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                    <v-icon
                        small
                        color="red"
                        @click="deleteItem(item)"
                        align-end
                    >
                        mdi-delete
                    </v-icon>
                </template>
              </v-data-table>

             <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false">{{capitalize($t('close'))}}</v-btn>
    </v-snackbar>
            </v-container>
   </v-card>

    </v-container>
</template>
<script>
import APIService from '@/services/APIService'

export default {
  mounted: function () {
    this.refreshContent()
  },
  methods: {
    close () {
      this.dialog = false
    },
    refreshContent() {
      this.isLoading = true
      APIService.getFamilies(this.UserAuthToken).then((data) => {
      this.strings = data
      this.isLoading = false
      })
    },
    createNewFamily: function () {
      APIService.createFamily(this.UserAuthToken, {name: this.newFamilyName}).then(respons => {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.capitalize(this.$t('data created'))
        this.dialog = false
        this.refreshContent()
      })
    },
    deleteItem (item) {
      APIService.deleteFamily(this.UserAuthToken, item).then(response => {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.capitalize(this.$t('data deleted'))
        this.refreshContent()
      })
      this.snack = true
      this.snackColor = 'warning'
      this.snackText = this.capitalize(this.$t('deleting'))
    },
    save (item) {
      APIService.updateFamily(this.UserAuthToken, item).then(response => {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.capitalize(this.$t('data saved'))
      })
      this.snack = true
      this.snackColor = 'warning'
      this.snackText = this.capitalize(this.$t('saving'))
    },
    cancel () {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = this.capitalize(this.$t('canceled'))
    },
    open () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = this.capitalize(this.$t('dialog opened'))
    }
  },
  computed: {
    headers: function () {
      return [
        {text: this.$t('name'), value: 'name'},
        {text: this.$t('actions'), value: 'action', sortable: false, align: 'right'}]
    }
  },
  data: () => ({
    isLoading: false,
    strings: [],
    snack: false,
    snackColor: '',
    snackText: '',
    search: '',
    dialog: false,
    newFamilyName: 'newFamily'
  })
}
</script>
