<template>

<div>
<v-app-bar light v-if="!IsInContest" :collapse="collapseOnScroll" :short="short" fixed app>
      <v-app-bar-nav-icon @click.stop="flipDrawer()" ></v-app-bar-nav-icon>
      <img v-show="!drawer" width="200"
        src="/logo.png"
        alt="logo"
        @click="goHome()"
      />
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="!isSmall">{{User.email}}</v-toolbar-title>
       <v-menu  left
        bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small elevation="0" >
            <country-flag  :country="languagesDisp[CurrentLanguage].icon" >
            </country-flag>
            <span v-if="!isSmall">
            {{languagesDisp[CurrentLanguage].code.toUpperCase()}}
            </span>
          </v-btn>
         </template>
        <v-list>
          <v-list-item v-for="language in languagesDisp" @click="changeLanguage(language)" v-bind:key="language.code">
            <v-list-item-title>
              <country-flag :country="language.icon" v-bind:squared=false />
              </v-list-item-title>
              <v-list-item-action-text>{{language.code.toUpperCase()}}</v-list-item-action-text>
          </v-list-item>
        </v-list>

      </v-menu>

      <v-scale-transition>
      <v-btn elevation="0"  small  v-show="IsLoggedIn" @click="logout()"> <v-icon >mdi-logout</v-icon>
       <span v-if="!isSmall">{{$t("logout")}} </span></v-btn>
      </v-scale-transition>
      <v-scale-transition>
      <v-btn elevation="0" small  v-show="!IsLoggedIn" @click="login()"> <v-icon> mdi-login </v-icon> 
      <span v-if="!isSmall">{{$t("login")}} </span></v-btn>
      </v-scale-transition>
      
</v-app-bar>

<v-navigation-drawer  v-model="drawer" app v-if="!IsInContest">
    <v-list-item align="center" justify = "center">
        <v-list-item-content >
            <v-list-item-title @click="goHome()" class="title">
                <v-avatar size="80">
                <img width="300"
                      src="/logoSimple.png"
                    alt="QUIZZ SOR" 
                />
              </v-avatar>
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense nav>
        <v-list-item
          v-for="page in pages"
          :key="page.title"
          :to="page.route"
        >
        
         <v-btn
            v-if ="page.icon == 'mdi-login'"
            block
            outlined
            color="success"
            elevation="2"
            @click = "login()"
            >
            <v-icon 
              color="blue">
              {{ page.icon }}
            </v-icon>
            <div>{{ capitalize($t(page.title)) }}</div>
          </v-btn>
          <v-btn
            v-else
            block
            outlined
            color="success"
            elevation="2"
            >
            <v-icon 
              color="blue">
              {{ page.icon }}
            </v-icon>
            <div>{{ capitalize($t(page.title)) }}</div>
          </v-btn>
          
        </v-list-item>
      </v-list>
</v-navigation-drawer>

</div>

</template>

<script>
import APIService from '@/services/APIService'

export default {
  components: {
  },
  computed: {
     isSmall: function() {
        switch (this.$vuetify.breakpoint.name) {
            case 'xs': return true
            case 'sm': return true
            case 'md': return false
            case 'lg': return false
            case 'xl': return false
        }
    },
    pages: function () {
      var pages = [{ title: 'home', icon: 'mdi-home', route: '/' }]
      if (!this.IsUserActive) {
          pages = pages.concat([{
              title: 'create account',
              icon: 'mdi-login',
              route: '/contest'}])
        }
      pages = pages.concat([{
          title: 'exercise',
          icon: 'mdi-chair-school',
          route: '/exercise'
        }])

      if (this.IsUserActive) {
       pages = pages.concat([{
          title: 'participate in contest',
          icon: 'mdi-head-question',
          route: '/contest'
        },
        {
          title: 'profile',
          icon: 'mdi-account',
          route: '/profile'
        },
        ])
      }
     
      if (this.IsUserActive &&
        this.IsUserAdmin) {
        pages = pages.concat([
          {
            title: 'strings',
            icon: 'mdi-text',
            route: '/strings'
          },
          {
            title: 'audiencegroup',
            icon: 'mdi-select-group ',
            route: '/audiencegroup'
          },
          {
          title: 'contests',
          icon: 'mdi-podium ',
          route: '/contest_edit'
          },
          {
            title: 'species',
            icon: 'mdi-account-group',
            route: '/species'
          },
          {
            title: 'family',
            icon: 'mdi-human-male-female',
            route: '/family'
          },
          {
            title: 'clada',
            icon: 'mdi-home-group',
            route: '/clada'
          },
          {
            title: 'users',
            icon: 'mdi-account-supervisor',
            route: '/users'
          },
          {
            title: 'schools',
            icon: 'mdi-school',
            route: '/schools'
          },
          {
            title: 'forms',
            icon: 'mdi-clipboard-list-outline ',
            route: '/checklists'
          }
        ])
        }
        if (this.IsUserActive && this.IsUserSchoolAccount)
        {
        pages = pages.concat([ 
          {
            title: 'check form',
            icon: 'mdi-check',
            route: '/checkform'
          }
        ])
        }
      
      return pages
    }
  },
  data: () => ({
    collapseOnScroll: false,
    drawer: true,
    tile: '',
    router: null,
    short: true
  }),
  mounted: function () {
    this.drawer = this.sideBarShow
    this.updateLocalisation()
  },
  methods: {
    goHome: function () {
      this.$router.push({ name: 'HomeLayout' }).catch(err => {})
    },
    changeLanguage: function (language) {
        this.$store.dispatch('setUserCurrentLanguage', 
          {currentLanguage: language.code})
    },
    login: function () { 
      this.$store.commit("SET_DIALOG_AUTH_SHOW", true); 
      this.$store.dispatch('refreshUser') },
    logout: function () { this.$store.dispatch('logoutUser') },
    flipDrawer () {
      this.drawer = !this.drawer
    },
    updateLocalisation () {
      this.$store.dispatch('fetchLanguages')
    }
  },
  watch: {
    drawer: function (newVal /*,oldVal*/) {
      this.$store.commit('SET_SIDE_BAR_SHOW', newVal)
    },
    IsInContest: function(newVal) {
    },
    CurrentLanguage: function(newVal) {

    }
  },

  props: {
  }
}

</script>
