<template>

  <div class="fullheight" block>
    <div class="background-video" block>
  
      <div class="pic-wrapper">
        <transition-group
        :duration="1000"
        tag="div"
        enter-active-class="enterActive"
        enter-class="enter"
        enter-to-class="enterTo"
        leave-active-class="leaveActive"
        leave-class="leave"
        leave-to-class="leaveTo"
      >
      <img
            v-for="(image, index) in images"
            v-show="image === prevImage"
            :key="index"
            :src="prevImage"
            class="pic1"
            alt=""
          >
         <img
            v-for="(image, index) in images"
            v-show="activeIndex === index"
            :key="index + '_other'"
            :src="image"
            class="pic2"
            alt=""
          >
        </transition-group>
      
      </div>
    </div>
    <v-main class="fullheight" >
      
      <v-dialog
      v-model="dialog"
      max-width="600px" 
      :loading="dialog"
      persistent
      transition="dialog-bottom-transition">
        <v-card >
          <v-progress-linear v-show="isActivating" indeterminate color="warning"></v-progress-linear>
          <v-alert :type="dialogType">
           {{getDialogMessage()}}
          </v-alert>
        </v-card>
      </v-dialog>

      <v-dialog
      v-model="resetPasswordDialog"
      max-width="600px" 
      persistent
      transition="dialog-bottom-transition">
        <v-card >
          <v-card-title>
            <v-layout>
            <v-container grid>
              <v-row>{{capitalize($t('pick a new password'))}}</v-row>
              <v-row>
                <v-text-field :error-messages="validPasswords()" prepend-icon="mdi-lock" type="password" :label="capitalize($t('new password'))" v-model="newPassword"></v-text-field></v-row>
              <v-row>
                <v-text-field :error-messages="validPasswords()" prepend-icon="mdi-lock" type="password" :label="capitalize($t('repeat password'))" v-model="retypePassword"></v-text-field></v-row>
              <v-row><v-spacer/><v-btn color="success" v-show="validPasswords().length ==0" @click="updatePassword">{{capitalize($t('update password'))}}</v-btn></v-row>
              </v-container>
              </v-layout>
            </v-card-title>
          
        </v-card>
      </v-dialog>
    
      <v-container align="center" fill-height fluid> 
      
        <v-row align="center" justify="center">
          <v-col  align="center" justify="center">
            <v-alert 
                align="center"
                border="left"
                v-show="IsLoggedIn && !IsUserActive"
                transition="scale-transition"
                prominent
                :width="!isSmallSize() ? 600: '100%'"
                type="error"
                    > 
                    <p class="text-h6">{{capitalize($t('pentru activarea contului verifica adresa de email'))}} </p>
                    <p class="text-h5"> {{User.email}} </p>  
              
            </v-alert>
          </v-col>
        </v-row>
        

       
      </v-container>
      <v-container>
          <v-snackbar v-model="errorSnack" :timeout="10000" color="error">
                {{MsgError}}
                <v-btn text @click="errorSnack = false" >{{capitalize($t('close'))}}</v-btn>
          </v-snackbar>
      </v-container>
    </v-main>

  </div>
</template>

<script>
import APIService from '@/services/APIService'

export default {
  components: {},
  created() {
    this.startInterval();
  },
  data: () => ({
    images: [
    'https://cdn.raduangelescu.com/file/quizzsor/1.JPG',
    'https://cdn.raduangelescu.com/file/quizzsor/2.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/3.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/4.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/5.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/6.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/7.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/8.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/9.JPG',
    'https://cdn.raduangelescu.com/file/quizzsor/10.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/11.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/12.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/13.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/14.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/15.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/16.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/17.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/18.JPG',
    'https://cdn.raduangelescu.com/file/quizzsor/19.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/21.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/22.jpg',
    'https://cdn.raduangelescu.com/file/quizzsor/23.jpg'
    ],
    index: 0,
    prevImage: 'https://cdn.raduangelescu.com/file/quizzsor/22.jpg',
    activeIndex: 0,
    interval: 10000,
    time: 10000,
    errorSnack: false,
    background_color:0,
    renderFrames: [],
    alertExerseaza: false,
    alertCreeazaCont: false,
    TokenId: '',
    width: 100,
    height: 100,
    startup: true,
    dialog: false,
    resetPasswordDialog: false,
    isResetingPassword: false,
    dialogType: "warning",
    isActivating: false,
    multipleTestTypes: false,
    collapseOnScroll: false,
    accountActivated: false,
    newPassword: "",
    retypePassword:"",
    copyright: 'Radu Angelescu @Copyright 2019',
    difficulties: [
      { lookup: 'easy' },
      { lookup: 'medium' },
      { lookup: 'hard' },
      { lookup: 'very_hard' },
      { lookup: 'expert' }
    ],
    testTypes: ['imagini+sunete', 'imagini', 'sunete'],
    allContests: []
  }),
  beforeDestroy () {
   clearInterval(this.interval);
    if (typeof window === 'undefined') return
    
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  mounted: function () {
    this.width = window.innerWidth
    this.height = window.innerHeight
    
    this.TokenId = this.$route.params.id
    var that = this
    this.errorSnack = false
    setTimeout(() => {
                    that.alertExerseaza = true
                    that.alertCreeazaCont = true
                  }, 100)
  },
  watch: {
    TokenId: function (newVal/*,oldVal*/) {
      this.checkId(newVal)
    },
    MsgError: function (newVal) {
      if(newVal == null) {
        this.errorSnack  = false
      }
      else
      {
        this.errorSnack = true
        var that = this
        setTimeout(() => {
          that.$store.commit('SET_ERROR_MESSAGE', null)
        }, 10000)
      }
    }
  },
  methods: {
      goToIndex(index) {
      this.activeIndex = index;
      this.time = this.interval;
    },
    next() {
      this.prevImage = this.images[this.activeIndex];
      let nextIndex = this.activeIndex + 1;

      // Go to the first image if the active
      // image ist the last one.
      if (!this.images[nextIndex]) {
        nextIndex = 0;
      }

      this.goToIndex(nextIndex);
    },
    prev() {
      let nextIndex = this.activeIndex - 1;

      // Go to the last image if the active
      // image is the first one.
      if (!this.images[nextIndex]) {
        nextIndex = this.images.length - 1;
      }

      this.goToIndex(nextIndex);
    },
    startInterval() {
      const precision = 100;
      const clock = setInterval(() => {
        this.time -= precision;
        if (this.time <= 0) this.next();
      }, precision);

      // Clear the interval if the component
      // is destroyed to prevent memory leaks.
      this.$once('hook:destroyed', () => clearInterval(clock));
    },
    onResize () {
      this.width = window.innerWidth
      this.height = window.innerHeight
        for(var i = 0; i< this.renderFrames.length; i++){
          var f = this.renderFrames[i]
          f.setAttribute("width", ""+this.width+"px")
          f.setAttribute("height", ""+this.height+"px")
          var rect = f.getElementById('svgrect')
          if(rect)
          {
            rect.setAttribute("width", ""+this.width+"px")
            rect.setAttribute("height", ""+this.height+"px") 
          }
          var x = 30
          var scale = 6.0
  
          if(this.isSmallSize())
          {
              x = -80
              scale = 5.0
          }
          var trf = f.getElementById('trf')
          if(trf)
          {
            trf.setAttribute('transform', "scale("+scale+") translate("+x+" 20.0)")
          }
        }
    },
    clearSvg(prnt){
      if(prnt) {
        let children = prnt.children;
        for (let i=0;i<children.length;){
            let el = children[i];
            if (el.tagName!=='defs'){
                el.remove();
            }else(i++);
        }
      }
  },
    getTriangleArea(tri) {
      var x1 = tri[0][0]
      var x2 = tri[1][0]
      var x3 = tri[2][0]
      var y1 = tri[0][1]
      var y2 = tri[1][1]
      var y3 = tri[2][1]
      return 1/2 * Math.abs(x1*(y2-y3)+ x2*(y3-y1)+x3*(y1-y2)) 
    },
    generateFrame(idx, frames) {
        var s = this.sn('svg',  {id:'svgitself', width:this.width, height:this.height})
        var rect = this.sn('rect', {id:'svgrect',x:0, y:0, width:this.width, height:this.height, fill:'#'+this.background_color.toString(16) })
        s.appendChild( rect );
        var tf = this.sn('g', {id:'trf', transform:"scale(6.000000) translate(30.0 20.0)"})
        s.appendChild(tf)
        var currentFrame = frames[idx]
        var nextFrame = null
        if(idx+1 >= frames.length) {
          nextFrame = frames[0]  
        }
        else {
          nextFrame = frames[idx+1]
        }
        var tris = currentFrame.triangles
        var ntris = nextFrame.triangles
        var usedIdx = new Array(tris.length).fill(false);
        for(var i = 0; i < tris.length; i++) {
            var pts = tris[i].points
            var min = {dist:9999999999, idx:i}

            /*for(var j = 0; j< ntris.length; j++) {
              var np = ntris[j].points
              var mx = (pts[0][0] + pts[1][0] + pts[2][0])/3
              var my = (pts[0][1] + pts[1][1] + pts[2][1])/3
              var nmx = (np[0][0] + np[1][0] + np[2][0])/3
              var nmy = (np[0][1] + np[1][1] + np[2][1])/3
              var a = this.getTriangleArea(pts)
              var na = this.getTriangleArea(np)
              var dst = (mx - nmx)*(mx - nmx) + (my-nmy) * (my-nmy) +
              Math.abs(na - a)
              if(dst < min.dist && usedIdx[j] == false) {
                min.dist = dst
                min.idx = j
              }
            }
            usedIdx[min.idx] = true
               */
            var npts = ntris[min.idx].points
            var p = `${pts[0][0]},${pts[0][1]} ${pts[1][0]},${pts[1][1]} ${pts[2][0]},${pts[2][1]}`
            var np = `${npts[0][0]},${npts[0][1]} ${npts[1][0]},${npts[1][1]} ${npts[2][0]},${npts[2][1]}`
           
            var tri = this.sn('polygon', 
            { 'fill-opacity': tris[i].opacity,
              fill:'#'+tris[i].color.toString(16),
              points:p
              })
            /*var animatePoints = this.sn('animate', {
              attributeName:"points",
              dur:"100ms",
              to: np
            })
             var animateFill = this.sn('animate', {
              attributeName:"fill",
              dur:"100ms",
              to: '#'+ntris[min.idx].color.toString(16)
            })
            var animateFillOpacity = this.sn('animate', {
              attributeName:"fill-opacity",
              dur:"100ms",
              to: ntris[min.idx].opacity
            })
            //tri.appendChild(animatePoints)
            //tri.appendChild(animateFill)
            //tri.appendChild(animateFillOpacity)
            */
            tf.appendChild(tri)
      
        }
        return s;
    },
    sn(n, v) {
      n = document.createElementNS("http://www.w3.org/2000/svg", n);
      for (var p in v)
        n.setAttributeNS(null, p, v[p]);
      return n
    },
    isSmallSize() {
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': return true
          case 'sm': return true
          case 'md': return false
          case 'lg': return false
          case 'xl': return false
        }
    },
    updatePassword(){
      APIService.resetPassword(this.TokenId, this.newPassword).then(data =>{
            this.resetPasswordDialog = false
            this.$store.dispatch('logout')           
      })
    },
    creeazaCont() {
       this.$store.commit("SET_DIALOG_AUTH_SHOW", true)
    },
    exerseaza() {
      this.$router.push({ name: 'ExerciseLayout' }).catch(err => {})
    },
    validPasswords(){
      var errors =[]

      if(this.newPassword !== this.retypePassword)
      {
        errors.push(this.capitalize(this.$t('passwords do not match')))
      }
      if(this.newPassword.length < 8)
      {
        errors.push(this.capitalize(this.$t('password must be bigger than 8 characters')))
      }
      return errors
    },
    getDialogMessage(){
      if(this.accountActivated == false && this.isActivating == true)
      {
        return this.capitalize(this.$t('activating account'))
      }
      else if(this.accountActivated == false && this.isActivating == false)
      {
        return this.capitalize(this.$t('Error: bad account activation token. It was already used'))
      }
      return this.capitalize(this.$t('account activated'))
      
    },
    checkId(id) {
      if(id != undefined)
        {
          if(typeof id === 'string') {
            if(id.length == 32)
            {
              var tokenType = id[31]
              if(tokenType == '0')
              {
                this.dialog = true
                this.isActivating = true
                this.dialogType ="warning"
                
                APIService.activateAccount (this.UserAuthToken, id).then(data =>{
                    this.isActivating = false
                    if(data ===undefined)
                    {
                      this.dialogType ="error"
                    }
                    else
                    {
                      this.dialogType = "success"
                      this.accountActivated  = true
                    }
                    var self = this
                    setTimeout(() => {
                        self.dialog = false
                        this.$store.dispatch("logoutUser")
                      }, 200)
                })
              }
              else if (tokenType == '1')
              {
                this.resetPasswordDialog = true
              }
            }
          }
        }
    }
  },
  props: {
    source: String
  }
}
</script>
<style>

.pic-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


figurecaption {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
}

.pic1{
  position: absolute;
  object-fit: cover;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  
  transform: scale3d(1.10, 1.10, 1.10);
}

.pic2 {
  position: absolute;
  object-fit: cover;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-image: url("https://cdn.raduangelescu.com/file/quizzsor/1.JPG");
  animation: kenburns 10s;
  animation-fill-mode: forwards;
}

.enterActive
{
  transition: opacity 1s;
}

.enter {
  opacity: 0.0;
}

.enterTo {
  opacity: 0.0;
}

.leave {
  opacity: 0.0;
}

.leaveTo {
    opacity: 0.0;
  }
 

  @keyframes kenburns {
    0% { 
      opacity: 0.0;
    }
    30% {
      opacity: 1.0;
    }
    100% {
      opacity: 1.0;
      transform: scale3d(1.10, 1.10, 1.10);
    }
  }

.background-video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
}
.copyright {
  position: fixed;
  top: 100%;
  left: 50%;
  min-width: 100%;
  min-height: 50px;
  width: auto;
  height: auto;
  z-index: 1;
  transform: translateX(-50%) translateY(-50%);
  transition: 1s opacity;
  background-color: rgba(1.0,1.0,1.0,0.4);
  text-align: right;
  margin-right: 150px;
  color:white;
}
.copyright-text{
  margin-right:50px;
}

.fullheight {
  height: 100%;
}

</style>
