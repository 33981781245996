<template>
 <v-card :loading="isLoading">
    <v-form
    ref="formColiziune" 
    v-model="valid"
    :disabled="multiButton.state == 'edit' || isLoading"
    >
    <v-card-text>
        <v-container grid>
             <v-row>
                <v-col cols=9>
                </v-col>
                <v-col>
                <v-btn :color="multiButton.states[multiButton.state].color"
                :disabled="!canPress()"
                @click="onPress()">
                    {{capitalize($t(multiButton.states[multiButton.state].text))}}
                    <v-icon color="white">{{multiButton.states[multiButton.state].icon}}</v-icon>
                </v-btn>
                </v-col>
            </v-row>
             <v-row>
                <v-col>
                <image-upload-component 
                :parent="coliziuneInfo"
                :disabled="multiButton.state == 'edit' || isLoading"
                v-on:modifyImage="changeImage"
                v-model="coliziuneInfo.image" />
                 <v-text-field 
                read-only
                :rules="[required]" 
                v-model="coliziuneInfo.imageURL"></v-text-field>
                
                </v-col>
               
    
            </v-row>
            <v-row>
                <v-col>
                <v-select v-model="locatieColant" :items="locatiiColant" :label="capitalize($t('lipit autocolante'))"/>
                </v-col>
                <v-col :cols = "6">
                <v-text-field numeric v-model="numarClase" v-if="locatieColant!=2" :label="capitalize($t('numarul claselor'))"/>
                </v-col>
            </v-row>
        </v-container>
    </v-card-text>
    </v-form>
</v-card>
</template>
<script>
import APIService from '@/services/APIService'
import ImageUploadComponent from '@/components/ImageUploadComponent'

export default {
    components: {
    'image-upload-component':ImageUploadComponent
    },
    props:['model'],
    watch: {
        model: function (newVal) {
            this.numarClase = newVal.numarClase
            this.locatieColant = newVal.locatieColant
            this.coliziuneInfo.imageURL = newVal.coliziuneInfo
            this.isLoading = false
        }
    },
    mounted: function () {
        this.refreshData()
    },
    data: () => ({
        coliziuneInfo:{
            valid:true,
            image: null,
            imageURL:''
        },
        multiButton:{
            states :{
                'edit':{
                    color: 'warning',
                    text: 'edit',
                    icon: 'mdi-pencil'
                },
                'save':{
                    color: 'success',
                    text:'save',
                    icon: 'mdi-floppy'
                }
            },
            state: 'edit'
        },
        valid: false,
        isLoading: false,
        numarClase: 0,
        locatieColant:0,
        }),
    methods: {
        required (value) {
            if (!value) return this.capitalize(this.$t('required'))
            if (value == ' ') return this.capitalize(this.$t('required'))
            return true
        },
        changeImage(data){
            data.parent.imageURL = data.imageURL
        },
        onPress() {
            if(this.multiButton.state == 'edit') {
                this.multiButton.state = 'save'
            }
            else {
                var data ={
                    contentType: 'Coliziune',
                    numarClase: this.numarClase,
                    locatieColant: this.locatieColant,
                    coliziuneInfo: this.coliziuneInfo.imageURL
                }
                this.isLoading = true
                this.$emit("saveContent", data)
                this.multiButton.state = 'edit'
            }
        },
        canPress(){
            if(this.multiButton.state == 'edit') {
                return true
            }
            if(this.multiButton.state == 'save') {    
                return this.valid
            }
            return true
        },  
        refreshData() {
        }
    },
    computed: {
      locatiiColant() {
            return [
            {text:this.capitalize(this.$t("geam fiecare clasa")), value:0},
            {text:this.capitalize(this.$t("geam in cateva clase")), value:1},
            {text:this.capitalize(this.$t("geamurile mari de pe hol")), value:2}
            ]
        }
    }
}
</script>
