<template>
    <v-container>
      <v-card>
        <v-card-title>
          {{capitalize($t("species details"))}}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text v-if="loaded">
            <v-row>
                <v-col>
                    <v-list-item-title class="headline mb-1"><v-text-field v-model="specie.englishName" :label="$t('english')"> {{$t('english')}} </v-text-field></v-list-item-title>
                </v-col>
                <v-col>
                    <v-list-item-title class="headline mb-1"><v-text-field v-model="specie.hungarianName" :label="$t('hungarian')"> {{$t('hungarian')}} </v-text-field></v-list-item-title>
                </v-col>
                <v-col>
                    <v-list-item-title class="headline mb-1"><v-text-field v-model="specie.romanianName" :label="$t('romanian')"> {{$t('romanian')}} </v-text-field></v-list-item-title>
                </v-col>
                <v-col>
                    <v-list-item-title class="headline mb-1"><v-text-field v-model="specie.scientificName" :label="$t('scientific')"> {{$t('scientific')}} </v-text-field></v-list-item-title>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-select v-model="specie.cladaId" :label="$t('clada')" :items="allCladas" />
                </v-col>
                <v-col>
                    <v-select v-model="specie.familyId" :label="$t('family')" :items="allFamilies" />
                </v-col>
            </v-row>

                 <v-data-iterator
                    :items="items"
                    :items-per-page.sync="itemsPerPage"
                    :page="page"
                    :search="search"
                    :sort-by="sortBy.toLowerCase()"
                    :sort-desc="sortDesc"
                    hide-default-footer
                    >
                    <template v-slot:header>
                        <v-toolbar
                        dark
                        color="blue darken-3"
                        class="mb-1"
                        >
                        <v-text-field
                            v-model="search"
                            clearable
                            flat
                            solo-inverted
                            hide-details
                            prepend-inner-icon="mdi-text-search"
                            :label="capitalize($t('search'))"
                        ></v-text-field>
                        <template v-if="$vuetify.breakpoint.mdAndUp">

                            <v-spacer></v-spacer>
                            <v-select
                            v-model="sortBy"
                            flat
                            solo-inverted
                            hide-details
                            :items="keys"
                            prepend-inner-icon="mdi-text-search"
                            :label="capitalize($t('sort by'))"
                            ></v-select>
                            <v-spacer></v-spacer>
                            <v-btn-toggle
                            v-model="sortDesc"
                            mandatory
                            >
                            <v-btn
                                depressed
                                color="blue"
                                :value="false"
                            >
                                <v-icon>mdi-arrow-up</v-icon>
                            </v-btn>
                            <v-btn
                                depressed
                                color="blue"
                                :value="true"
                            >
                                <v-icon>mdi-arrow-down</v-icon>
                            </v-btn>

                            </v-btn-toggle>
                            <v-spacer/>
                            <v-btn color="green" @click="onCreateImageClick"> <v-icon>mdi-plus</v-icon> </v-btn>

                        </template>
                        </v-toolbar>
                    </template>

                    <template v-slot:default="props">
                        <v-row>
                        <v-col
                            v-for="item in props.items"
                            :key="item.name"
                            cols="12"
                            sm="6"
                            md="4"
                            lg="3"
                        >
                            <v-card>
                            <v-card-actions>
                                <v-btn icon color="red" @click="deleteImage(item)">  <v-icon>mdi-delete</v-icon> </v-btn>
                            </v-card-actions>
                            <v-card-title class="subheading font-weight-bold">
                                <v-img  class="white--text align-end"
                                height="200px"
                                :src="item.imagePath">

                                </v-img>
                            </v-card-title>

                            <v-divider></v-divider>

                            <v-list dense>
                                <v-list-item
                                v-for="(key, index) in filteredKeys"
                                :key="index"
                                >
                                <v-list-item-content :class="{ 'blue--text': sortBy === key }">{{ key }}:</v-list-item-content>
                                <v-list-item-content class="align-end" :class="{ 'blue--text': sortBy === key }">{{ item[key] }}</v-list-item-content>
                                </v-list-item>
                            </v-list>

                            </v-card>
                        </v-col>
                        </v-row>
                    </template>

                    <template v-slot:footer>
                        <v-row class="mt-2" align="center" justify="center">
                        <span class="grey--text">Items per page</span>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on }">
                            <v-btn
                                dark
                                text
                                color="primary"
                                class="ml-2"
                                v-on="on"
                            >
                                {{ itemsPerPage }}
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                            </template>
                            <v-list>
                            <v-list-item
                                v-for="(number, index) in itemsPerPageArray"
                                :key="index"
                                @click="updateItemsPerPage(number)"
                            >
                                <v-list-item-title>{{ number }}</v-list-item-title>
                            </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-spacer></v-spacer>

                        <span
                            class="mr-4
                            grey--text"
                        >
                            Page {{ page }} of {{ numberOfPages }}
                        </span>
                        <v-btn
                            fab
                            dark
                            color="blue darken-3"
                            class="mr-1"
                            @click="formerPage"
                        >
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn
                            fab
                            dark
                            color="blue darken-3"
                            class="ml-1"
                            @click="nextPage"
                        >
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                        </v-row>
                    </template>
                    </v-data-iterator>

        </v-card-text>
        <v-card-actions>
            <v-spacer/>
            <v-btn color="success" @click="updateSpecies()"> {{capitalize($t('update'))}}</v-btn>
        </v-card-actions>
        </v-card>
        <v-dialog  v-model="createDialog"  max-width="800px">
          <v-card>
            <v-card-text>
                   <v-form>
                    <v-container>
                      <v-row>
                        <v-col>
                  
                            <image-upload-component
                            ref="imageUploadComponent"
                            :species="true"
                            :parent="newImage.imageObject"
                            v-on:modifyImageData="onFilePicked"
                            v-model="newImage.imageObject.image" 
                            :disabled="false"
                            />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-img :src="newImage.imageObject.imageURL" v-if="newImage.imageObject.imageURL"/>
                      </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="newImage.copyright" :label="$t('copyright')"> {{$t('copyright')}} </v-text-field>
                        </v-col>
                        <v-col>
                          <v-slider
                          v-model="newImage.difficulty"
                          color="orange"
                          :label="$t('difficulty')"
                          hint="Be honest"
                          min="0"
                          max="10"
                          thumb-label
                          ></v-slider>
                        </v-col>

                    </v-row>
                    <v-row>
                      <v-col>
                        <v-spacer/>
                      </v-col>
                      <v-col>
                        <v-btn color="success" :disabled="newImage.imageObject.imageData==null || newImage.imageObject.imageData==''" @click="addImage(newImage)">{{capitalize($t('upload'))}}</v-btn>
                      </v-col>
                    </v-row>
                    </v-container>
                    </v-form>
            </v-card-text>
              </v-card>
              
        </v-dialog>
    </v-container>
</template>

<script>
import APIService from '@/services/APIService'
import ImageUploadComponent from '@/components/ImageUploadComponent'
export default{
   components: {
    'image-upload-component':ImageUploadComponent,
  },
  data () {
    return {
      multiButton:{
            states :{
                'edit':{
                    color: 'warning',
                    text: 'edit',
                    icon: 'mdi-pencil'
                },
                'save':{
                    color: 'success',
                    text:'save',
                    icon: 'mdi-floppy'
                }
            },
            state: 'edit'
        },
      id: -1,
      specie: {},
      families: [],
      allCladas: [],
      allFamilies: [],
      loaded: false,
      itemsPerPageArray: [4, 8, 12],
      search: '',
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      sortBy: 'name',
      keys: [
        'copyright',
        'imagePath',
        'difficulty'
      ],
      items: [],
      createDialog: false,
      newImage: {
        copyright: '',
        difficulty: '',
        macauley_id: -1,
        imageObject: {
          imageURL:null,
          image:null,
          imageData:''
        }
      }
    }
  },
  computed: {
    numberOfPages () {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    filteredKeys () {
      return this.keys.filter(key => key !== `Name`)
    }
  },
  methods: {
    onFilePicked(imageData, parent){
      this.newImage.imageObject.imageData = imageData.imageData
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    },
    updateImage (itemImage) {
      APIService.updateSpeciesImage(this.UserAuthToken, itemImage).then(data => {

      })
    },
    deleteImage (itemImage) {
      APIService.deleteSpeciesImage(this.UserAuthToken, itemImage).then(data => {
        APIService.getSpeciesDetail(this.UserAuthToken, this.id).then(data => {
          this.specie = data.species
          this.items = data.specieImages
          this.loaded = true
        })
      })
    },
    addImage (item) {
      var data = {
        'speciesId': this.specie.id,
        'copyright': this.newImage.copyright,
        'image': this.newImage.imageObject.imageData,
        'difficulty': this.newImage.difficulty
      }
      var that = this
      APIService.createSpeciesImage(this.UserAuthToken, data).then(data => {
        APIService.getSpeciesDetail(this.UserAuthToken, this.id).then(data => {
          that.specie = data.species
          that.items = data.specieImages
          that.loaded = true
          that.createDialog = false
        })
      })
    },
    onCreateImageClick() {
      this.createDialog = true
      this.newImage.copyright = ''
      this.newImage.difficulty = ''
      this.newImage.macauley_id = -1
      this.newImage.imageObject.imageURL = null
      this.newImage.imageObject.image = null
      this.newImage.imageObject.imageData = ''
      this.$refs.imageUploadComponent.resetOutside();
    },
    updateSpecies () {
      this.loaded = false
      var newSpecies = this.specie
      delete newSpecies['images']

      APIService.updateSpeciesDetail(this.UserAuthToken, newSpecies).then(id => {
        APIService.getSpeciesDetail(this.UserAuthToken, this.id).then(data => {
          this.specie = data.species
          this.items = data.specieImages
          this.loaded = true
        })
      })
    }
  },
  mounted: function () {
    APIService.getFamilies(this.UserAuthToken).then(families => {
      this.allFamilies = []
      families.forEach(family => {
        this.allFamilies.push({
          text: family.name,
          value: family.id
        })
      })
    })
    APIService.getCladas(this.UserAuthToken).then(cladas => {
      this.allCladas = []
      cladas.forEach(clada => {
        this.allCladas.push({
          text: clada.name,
          value: clada.id
        })
      })
    })
    this.id = this.$route.params.id
    APIService.getSpeciesDetail(this.UserAuthToken, this.id).then(data => {
      this.specie = data.species
      this.items = data.specieImages
      this.loaded = true
    })
  }
}
</script>
