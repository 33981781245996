  <template>
  <v-bottom-sheet v-model="notAgreedGDPR" persistent>
      <template v-slot:activator="{}">
      </template>
        <v-dialog v-model="notAgreedGDPR" width = "800px">
          <v-card :loading="loading">
            <v-card-title class="text-h5 grey lighten-2">
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon >
                      <country-flag  :country="languagesDisp[CurrentLanguage].icon" >
                      </country-flag>
                      {{languagesDisp[CurrentLanguage].code.toUpperCase()}}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="language in languagesDisp" @click="changeLanguage(language)" v-bind:key="language.code">
                      <v-list-item-title>
                        <country-flag :country="language.icon" v-bind:squared=false />
                        </v-list-item-title>
                        <v-list-item-action-text>{{language.code.toUpperCase()}}</v-list-item-action-text>
                    </v-list-item>
                  </v-list>
                </v-menu>
              <v-spacer/>
               {{capitalize($t('politica de confidentialitate'))}}
               <v-spacer/>
             
            </v-card-title>
            <v-card-text v-html="gdprtext">
            
             </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn
              color="primary"
              text
              @click="agreeGdpr()"
            >{{capitalize($t('i agree'))}}</v-btn>
            </v-card-actions>
          </v-card>    
        </v-dialog>
    </v-bottom-sheet>
</template>
<script>
import APIService from '@/services/APIService'
export default {
  data: () => ({
    gdprtext: '',
    loading: true
  }),
  mounted: function() {
    this.refreshContent()
  },
  computed: {
    notAgreedGDPR: function () {
      return this.AgreeGdpr === false
    }
  },
  watch: {
    CurrentLanguage: function(newVal) {
      this.refreshContent()
    }
  },
  methods: {
    refreshContent: function () {
      this.loading = true
      APIService.getGdprLocalization(this.languagesDisp[this.CurrentLanguage].code).then(data => {
        this.gdprtext = data
        this.loading = false
      })
       
    },
    changeLanguage: function (language) {
        this.$store.dispatch('setUserCurrentLanguage', 
          {currentLanguage: language.code})
    },
    agreeGdpr () {
      this.$store.dispatch('setUserGDPR', true)
      this.$router.push({ name: 'HomeLayout' }).catch(err => {})
    }
  }
}
</script>
