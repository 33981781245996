<template>
    <v-container>
        <form-component :schoolId="-1"></form-component>
    </v-container>
</template>
<script>
import FormComponent from '@/components/FormComponent'

export default {
  components: {
    'form-component':FormComponent,
    },
  mounted: function () {
  
  },
  methods: {

  },
  computed: {
  },
  watch: {
     
  },
  data: () => ({
    })
}
</script>
