import { mapGetters } from 'vuex'

let globalMixin = {
  computed: {
    ...mapGetters([
      'IsLoggedIn',
      'User',
      'CurrentLanguage',
      'UserAuthStatus',
      'dialogAuthShow',
      'sideBarShow',
      'Languages',
      'languagesDisp',
      'IsUserActive',
      'IsUserAdmin',
      'IsUserSchoolAccount',
      'UserRefreshToken',
      'UserAuthToken',
      'IsInContest',
      'MsgError',
      'AgreeGdpr'
    ]),
    possibleRoles: function () {
      return [
        {value: 0, text: this.$t('cadru_didactic')},
        {value: 1, text: this.$t('elev')}]
    },
    nomenclaturesItems: function () {
      return [
        {
          text: this.$t('scientific'),
          value: 0
        },
        {
          text: this.$t('english'),
          value: 1
        },
        {
          text: this.$t('romanian'),
          value: 2
        },
        {
          text: this.$t('hungarian'),
          value: 3
        }
      ]
    }
  },
  methods: {
    capitalize (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    locArray (array) {
      return array.map((x, id) => {
        return { text: this.$t(x.lookup), value: id, lookup: x.lookup }
      })
    }
  }
}

export default globalMixin
