<template>
    <v-container>
        <v-row v-if="parent.valid">
            <v-col v-for="(field, idx) in fields" v-bind:key="idx">
                <v-text-field v-model="newItem[field]" :label="$t(field)"/>
            </v-col>
            <v-col cols=2>
                <v-btn icon fab small @click="addItem()">
                    <v-icon>
                        mdi-plus
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row  v-if="parent.valid">
            <v-col>
                <v-list dense>
                <v-list-item
                    v-for="(item, i) in list"
                    :key="i">
                <v-list-item-action-text>
                    <v-btn fab small icon :disabled="!valid" @click="removeItem(i)">
                        <v-icon>
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </v-list-item-action-text>
                <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <span v-for="(field,i) in fields" v-bind:key="i">{{item[field]}}</span>
                </v-list-item-content>
                </v-list-item>
                
                </v-list>
            </v-col>
            
        </v-row>
    </v-container>
</template>
<script>

export default {
    props:["parent", "fields", "listName", "valid"],
    data() {
        return {
            newItem: {
                name:'',
                email:'',
                specie:''
            },
            list: [],
        }
    },
    watch: {
        parent: {
            deep: true,
            handler: function (newVal, oldValue) {
                this.list = newVal[this.listName]
            }
        }

    },
    mounted() {
        this.fields.forEach(el => {
            this.newItem[el] = ''
        })
        this.parent[this.listName].forEach(el =>
        {
            this.list.push(el)
        })
        
    },
    methods:{
       addItem(){
            var isValid = true
            this.fields.forEach(el => {
                if(!this.newItem[el]) {
                    isValid = false
                }
            })
            if(isValid)
            {
                var addobj = JSON.parse(JSON.stringify(this.newItem))
                this.list.push(addobj)
                this.fields.forEach(el => {
                    this.newItem[el] = ''
                })
            }
            this.$emit("modifyList", this.list, this.parent, this.listName)
       },
       removeItem(i){
            this.list.splice(i,1)
            this.$emit("modifyList", this.list, this.parent, this.listName)
       }
    }
}
</script>
