<template>
    <v-container>
      <fullscreen-countdown :time="5" :enabled="shouldDoTimer" v-on:doneTimer="onDoneTimer()"/>
      <question-data-component v-show="showQuestion" :contestData="contestData" type="contest" />
    </v-container>
</template>
<script>
import APIService from '@/services/APIService'
import FullScreenCountdown from '@/components/FullScreenCountdown'
import QuestionComponent from '@/components/QuestionComponent'
export default {
  components: {'question-data-component': QuestionComponent,
     'fullscreen-countdown': FullScreenCountdown
 },
  mounted: function () {
    var contestId = parseInt(this.$route.params.id)
    var isClosed = this.$route.params.isClosed
    this.isLoading = true
    var that = this
    that.shouldDoTimer = isClosed
    if(!that.shouldDoTimer) {
        that.onDoneTimer()
      }
    APIService.createContestUser(this.UserAuthToken, contestId).then(contest => {
      if (contest == "")
      {
        that.isLoading = false
        that.$router.push({ name: 'HomeLayout'}).catch(err => {})
        return;
      }
      contest.questionInstances.map(q => {
        if (q.type === 0) {
          var choicesArray = JSON.parse(q.choices)
          choicesArray.push(this.capitalize(this.$t("don't know")))
          q.choices = choicesArray
        }
      })
      

      this.contestData = contest
      this.contestName = contest.contestInstance.name
      this.isLoading = false
    }, error => { console.log('create contest error ' + JSON.stringify(error)) })
  },
  methods: {
    onDoneTimer() {
      this.$store.commit("SET_IS_IN_CONTEST", true)
      this.showQuestion = true
    },
    getTitleText() {
      if(this.isLoading) {
        return this.capitalize(this.$t("please wait while we generate test"))
      }
      return ""
    }
  },
  data: () => ({
    isLoading: false,
    contestName: '',
    contestData: null,
    showQuestion: false,
    shouldDoTimer: false
  })
}
</script>
