<template>
<v-card>
    <v-card-title>
        
    </v-card-title>
    <v-card-text>
        <v-form
        ref="formOlimpiada" 
        v-model="valid"
        :disabled="multiButton.state == 'edit' || isLoading">
        <v-container>
            <v-row>
                <v-col cols=9>
                </v-col>
                <v-col>
                <v-btn :color="multiButton.states[multiButton.state].color"
                :disabled="!canPress()"
                @click="onPress()">
                    {{capitalize($t(multiButton.states[multiButton.state].text))}}
                    <v-icon color="white">{{multiButton.states[multiButton.state].icon}}</v-icon>
                </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-checkbox v-model="olimpiadaSpeciilor" :label="capitalize($t('participare olimpiada speciilor'))"/>
                </v-col>
            </v-row>
        </v-container>
        </v-form>
    </v-card-text>
</v-card>
</template>
<script>
import APIService from '@/services/APIService'
export default {
    props:['model'],
    watch: {
        model: function (newVal) {
            this.isLoading = false
            this.olimpiadaSpeciilor = newVal.olimpiadaSpeciilor
        }
    },
    mounted: function () {
        this.refreshData()
    },
    data: () => ({
        multiButton:{
            states :{
                'edit':{
                    color: 'warning',
                    text: 'edit',
                    icon: 'mdi-pencil'
                },
                'save':{
                    color: 'success',
                    text:'save',
                    icon: 'mdi-floppy'
                }
            },
            state: 'edit'
        },
        valid: false,
        isLoading: false,
        olimpiadaSpeciilor: false,
        image: {
            hasImage:false,
            image:null
        }
        
        }),
    methods: {
        setImage(output) {
            this.image.hasImage = true
            this.image = output
        },
        startImageResize() {
        },
        endImageResize() {
        },
        onPress() {
            if(this.multiButton.state == 'edit') {
                this.multiButton.state = 'save'
            }
            else {
                var data ={
                    contentType: 'Olimpiada',
                    olimpiadaSpeciilor: this.olimpiadaSpeciilor
                }
                this.isLoading = true
                this.$emit("saveContent", data)
                this.multiButton.state = 'edit'
            }
        },
        canPress(){
            if(this.multiButton.state == 'edit') {
                return true
            }
            if(this.multiButton.state == 'save') {    
                return this.valid
            }
            return true
        },  
        refreshData() {
         
        },
        
    },
    computed: {
       
    }
}
</script>
