<template>
 <v-dialog v-model="dialog" max-width="800px" transition="dialog-bottom-transition">
    <template v-slot:activator="{ on }">
        <v-btn iclass="mx-2" fab small color="primary" 
        @click="createNewContest">
            <v-icon dark>mdi-plus</v-icon>
        </v-btn>
    </template>
                        
    <v-card >
        <v-container fluid>
            <v-container>
                <v-row justify="center" class="mb-6">
                    <div class="headline mb-4" v-if="localContestId==-1">{{capitalize($t('create new contest'))}}</div>
                    <div class="headline mb-4" v-else>{{capitalize($t('modify contest'))}} {{contestName}}</div>
                
                </v-row >
                <v-row>
                    <v-col>
                        <v-list-item-title class="headline mb-1"><v-text-field v-model="contestName" :label="capitalize($t('name'))"> {{capitalize($t('contest name'))}} </v-text-field></v-list-item-title>
                    </v-col>
                 </v-row>
                <v-row>
                    <v-col>
                        <v-select :label="capitalize($t('audience group'))" v-model="audienceGroup" :items="audienceGroups"></v-select>
                    </v-col>
                    <v-col>
                        <v-select :label="capitalize($t('language'))" v-model="language" :items="allLanguages"></v-select>
                    </v-col>
                    <v-col>
                        <v-select :label="capitalize($t('nomenclature'))" v-model="nomenclature" :items="nomenclaturesItems"></v-select>
                    </v-col>
               
                </v-row>
                <v-row>
                    <v-col>
                        <v-subheader>{{capitalize($t('contest duration (seconds)'))}}</v-subheader>
                        <v-slider
                            v-model="timeForQuestions.current"
                            class="align-center"
                            :max="timeForQuestions.max"
                            :min="timeForQuestions.min"
                            hide-details
                        >
                            <template v-slot:append>
                                <v-text-field
                                    v-model="timeForQuestions.current"
                                    class="mt-0 pt-0"
                                    hide-details
                                    single-line
                                    type="number"
                                    style="width: 50px"
                                ></v-text-field>
                            </template>
                        </v-slider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-subheader>{{capitalize($t('maximum number of tries'))}}</v-subheader>
                        <v-slider
                            v-model="maxNumberOfTries.current"
                            class="align-center"
                            :max="maxNumberOfTries.max"
                            :min="maxNumberOfTries.min"
                            hide-details
                        >
                            <template v-slot:append>
                                <v-text-field
                                    v-model="maxNumberOfTries.current"
                                    class="mt-0 pt-0"
                                    hide-details
                                    single-line
                                    type="number"
                                    style="width: 50px"
                                ></v-text-field>
                            </template>
                        </v-slider>
                    </v-col>

                </v-row>
                <v-row>
                    <v-subheader>{{capitalize($t('contest availability date/time'))}}</v-subheader>
                </v-row>
           
                    <v-date-picker full-width v-model="contestPeriod" elevation="15" range ></v-date-picker>
              
        <v-data-iterator
        :items="contestQuestions"
        :items-per-page.sync="itemsPerPage"
        :footer-props="{ itemsPerPageOptions }"
        >
        <template v-slot:header>
            <v-toolbar
            dark
            color="blue darken-3"
            >
            <v-toolbar-title>{{capitalize($t('questions'))}}</v-toolbar-title>    
            <v-spacer></v-spacer>
                <v-text-field
                  v-model="copyFromQuestion"
                  hide-details
                  single-line
                  type="number"
                  :min=1
                  :max="contestQuestions.length"
                  v-show = "contestQuestions.length > 0"
                />
                <v-btn fab small color="purple" @click="cloneFromQuestion" v-show = "contestQuestions.length > 0">
                    <v-icon dark>mdi-content-copy</v-icon>
                </v-btn>
                
                <v-spacer></v-spacer>
                <v-btn fab small color="green" @click="addQuestion">
                    <v-icon dark>mdi-plus</v-icon>
                </v-btn>
               
            </v-toolbar>
        </template>

        <template v-slot:default="props">
            <v-row>
            <v-col
                v-for="(question, number) in props.items"
                v-bind:key="number"
                cols="12"
                sm="12"
                md="12"
                lg="12"
            >
                <v-card>
                <v-card-title class="subheading font-weight-bold">{{capitalize($t('question'))}} {{(question.number + 1)}}/{{contestQuestions.length}}
                    <v-spacer></v-spacer>
                    <v-btn color="error" fab small @click="removeQuestion(number)"><v-icon dark class="mx-2">mdi-delete</v-icon> </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                            <v-col>
                                <v-select :label="capitalize($t('type'))" v-model="question.type" :items="questionTypes"></v-select>
                            </v-col>
                            <v-col v-if="question.type==0">
                                <v-select :label="capitalize($t('number of choices'))" v-model="question.numberOfChoices" :items="numberOfChoicesArray"></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2">
                                 <v-label>{{capitalize($t('image difficulty'))}}</v-label>
                            </v-col>
                            <v-col>
                                <v-range-slider
                                    v-model="question.imageDifficulty.range"
                                    :max="question.imageDifficulty.max"
                                    :min="question.imageDifficulty.min"

                                    class="align-center"
                                >
                                    <template v-slot:prepend>
                                            <v-text-field
                                                v-model="question.imageDifficulty.range[0]"
                                                class="mt-0 pt-0"
                                                single-line
                                                type="number"
                                                style="width: 60px"
                                            ></v-text-field>
                                    </template>
                                    <template v-slot:append>
                                        <v-text-field
                                            v-model="question.imageDifficulty.range[1]"
                                            class="mt-0 pt-0"
                                            hide-details
                                            single-line
                                            type="number"
                                            style="width: 60px"
                                        ></v-text-field>

                                    </template>
                                </v-range-slider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2">
                                <v-checkbox :label="capitalize($t('use all species'))"  v-model="question.useAllSpecies"></v-checkbox>
                            </v-col>
                            <v-col v-if="!question.useAllSpecies">
                                   <v-combobox
                                    v-model="question.species"
                                    :filter="filter"
                                    :hide-no-data="!searchSpecies"
                                    :items="allSpecies"
                                    :search-input.sync="searchSpecies"
                                    hide-selected
                                    :label="capitalize($t('search for a specie'))"
                                    multiple
                                    small-chips
                                    solo
                                    >

                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                        <v-chip
                                        v-if="item === Object(item)"
                                        v-bind="attrs"
                                        :color="`${item.color} lighten-3`"
                                        :input-value="selected"
                                        label
                                        small
                                        >
                                        <span class="pr-2">
                                            {{ item.text }}
                                        </span>
                                        <v-icon
                                            small
                                            @click="parent.selectItem(item)"
                                        >mdi-close</v-icon>
                                        </v-chip>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <v-chip
                                        :color="`${item.color} lighten-3`"
                                        dark
                                        label
                                        small
                                        >
                                        {{ item.text }}
                                        </v-chip>
                                        <v-spacer></v-spacer>
                                        <v-list-item-action @click.stop>

                                        </v-list-item-action>
                                    </template>
                                    </v-combobox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2">
                                <v-checkbox :label="capitalize($t('use all cladas'))"   v-model="question.useAllCladas"></v-checkbox>
                            </v-col>
                             <v-col v-if="!question.useAllCladas">
                                   <v-combobox
                                    v-model="question.cladaId"
                                    :filter="filter"
                                    :hide-no-data="!searchCladas"
                                    :items="allCladas"
                                    hide-selected
                                    solo
                                    >

                                    </v-combobox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-checkbox :label="capitalize($t('use alternatives'))" v-model="question.useAlternatives"></v-checkbox>
                            </v-col>
                        </v-row>
                </v-container>
                </v-card>
            </v-col>
            </v-row>
        </template>

        </v-data-iterator>

    </v-container>
<v-card-actions>
    <v-spacer>
    </v-spacer>
    <v-btn color="success" @click="createContest" v-if="localContestId==-1">{{capitalize($t('create'))}}</v-btn>
    <v-btn color="success" @click="createContest" v-else>{{capitalize($t('update'))}}</v-btn>
</v-card-actions>
</v-container>
</v-card>
</v-dialog>
</template>

<script>
import APIService from '@/services/APIService'
export default {
  props: ['contestId', 'dlg'],
  computed: {
    questionTypes: function () {
      return [
        {value: 0, text: this.capitalize(this.$t('multiple choice'))},
        {value: 1, text: this.capitalize(this.$t('text input'))}]
    },
    typeLabel: function () {
      return this.capitalize(this.$t('type'))
    }
  },
  data: () => ({
    localContestId: -1,
    dialog: false,
    numberOfPages: 0,
    allLanguages: [],
    page: 0,
    itemsPerPage: 1,
    itemsPerPageOptions: [1],
    contestName: '',
    nomenclature: 0,
    audienceGroup: 0,
    language: 1,
    searchSpecies: null,
    searchCladas: null,
    timeForQuestions: {
      min: 2,
      max: 6000,
      current: 100
    },
    contestPeriod: [],
    maxNumberOfTries: {
      min: 1,
      max: 100,
      current: 1
    },
    copyFromQuestion: 1,
    questionsPerPage: [1, 2, 3],
    contestQuestions: [],
    numberOfChoicesArray: [
      3,
      5,
      8
    ],
    audienceGroups: [],
    allSpecies: [],
    allCladas: [],
    currentQuestionInfo:
        {
          imageDifficulty: {
            min: 1,
            max: 6,
            range: [1, 6]
          },
          useAllSpecies: true,
          useAllCladas: true,
          useAlternatives: false,
          numberOfChoices: 3,
          type: 0,
          cladaId: {text: 'pasari', value: 1},
          species: []
        }
  }),
  mounted: function () {
      this.refreshData()
  },
  methods: {
    cloneFromQuestion() {
      const questionIndex = this.copyFromQuestion - 1
      if (questionIndex >= this.contestQuestions.length || questionIndex < 0)
        return;
         
      var content = this.contestQuestions[questionIndex]
      var newEntry = Object.assign({}, content)
      newEntry.number = this.contestQuestions.length
      this.contestQuestions.push(newEntry)
    },
    getDate (date) {
      return date
    },
    createNewContest(){
        this.dialog = true
        this.localContestId = -1
        this.refreshData()
    },
    refreshData() {
    this.contestName = ""
    this.id = -1
    this.nomenclature = 0
    this.audienceGroup = 0
    this.timeForQuestions.current = 100
    this.maxNumberOfTries.current = 1
    this.contestQuestions  = []
    var today = new Date()
    var date = today.toISOString()
    this.contestPeriod[0] = date
    this.contestPeriod[1] = date
    this.language = 1
    this.allLanguages = []
    APIService.getAudienceGroups(this.UserAuthToken).then((data) => {
      this.audienceGroups = data.map(x => {return {text:x.name, value:x.id}})
    })
    this.languagesDisp.forEach(x=> 
    {
      this.allLanguages.push({value: x.value, text: x.code})
    })
    
    APIService.getSpecies(this.UserAuthToken).then(species => {
      this.allSpecies = []
      species.forEach(specie => {
        this.allSpecies.push({
          text: specie.scientificName,
          value: specie.id
        })
      })
    })
    APIService.getCladas(this.UserAuthToken).then(cladas => {
      this.allCladas = []
      cladas.forEach(clada => {
        this.allCladas.push({
          text: clada.name,
          value: clada.id
        })
      })
    })
    },
    createContest () {
     
      var payload = {
        'contestData': {
          'id': this.localContestId,
          'name': this.contestName,
          'language': this.language,
          'nomenclature': this.nomenclature,
          'audienceGroup': this.audienceGroup,
          'timeForQuestion': this.timeForQuestions.current,
          'maxNumberOfTries': this.maxNumberOfTries.current,
          'availableInCertainPeriod': true,
          'startDateTime': this.contestPeriod[0],
          'endDateTime': this.contestPeriod[1]
        },
        'contestQuestions': this.contestQuestions.map(function (question, number) {
          var questionId = -1
          if('id' in question)
          {
              questionId = question.Id
          }
          return {
            'id': questionId,
            'number': question.number,
            'type': question.type,
            'minimumDifficulty': question.imageDifficulty.range[0],
            'maximumDifficulty': question.imageDifficulty.range[1],
            'useAllSpecies': question.useAllSpecies,
            'useAllCladas': question.useAllCladas,
            'useAlternatives': question.useAlternatives,
            'numberOfChoices': question.numberOfChoices,
            'cladaId': question.cladaId.value,
            'species': question.species.map(function (item) { return {'id': item.value, 'scientificName': item.text} })
          }
        })
      }
      console.log(JSON.stringify(payload, null, 2))
      APIService.createContest(this.UserAuthToken, payload).then(data => {
                this.populate(data)
                this.dialog = false
                this.$emit('done-contests')
        })
    },
    addQuestion () {
      var newEntry = Object.assign({}, this.currentQuestionInfo)
      newEntry.number = this.contestQuestions.length
      this.contestQuestions.push(newEntry)
    },
    removeQuestion (itemIdx) {
      this.contestQuestions.splice(itemIdx, 1)
      this.contestQuestions.forEach(function (item, number) {
        item['number'] = number
      })
    },
    edit (number, item) {
      if (!this.editing) {
        this.editing = item
        this.number = number
      } else {
        this.editing = null
        this.number = -1
      }
    },
    filter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    populate(data) {
        this.contestName = data.contestData.name
        this.language = data.contestData.language
        this.id = data.contestData.id
        this.nomenclature = data.contestData.nomenclature
        this.timeForQuestions.current = data.contestData.timeForQuestion
        this.maxNumberOfTries.current = data.contestData.maxNumberOfTries
        this.audienceGroup = data.contestData.audienceGroup
        this.contestPeriod[0] = data.contestData.startDateTime
        this.contestPeriod[1] = data.contestData.endDateTime
        this.contestQuestions = []
        data.contestQuestions.forEach(dataquestion => {
            this.contestQuestions.push({
            number: dataquestion.number,
            id: dataquestion.id,
            type: dataquestion.type,
            imageDifficulty: {range: [dataquestion.minimumDifficulty, dataquestion.maximumDifficulty]},
            useAllSpecies: dataquestion.useAllSpecies,
            useAllCladas: dataquestion.useAllCladas,
            useAlternatives: dataquestion.useAlternatives,
            numberOfChoices: dataquestion.numberOfChoices,
            cladaId: this.allCladas.find(el => el.value == dataquestion.cladaId),
            species: dataquestion.species.map(function (item) { return {'value': item.id, 'text': item.scientificName} })
            })
        })
    }

  },
  watch: {
    contestId (old, new_val) {
        this.dialog = true
        this.localContestId = old
        this.refreshData()
        if(this.localContestId != -1)
        {
            APIService.getContest(this.UserAuthToken, old).then(data => {
                this.populate(data)
            })
        }
    },
    dialog(new_val) {
    
      if(new_val == false) {
        this.$emit('close_dialog')
      }
    },
    dlg(new_val, old) {
        this.dialog = new_val
        
        this.localContestId = this.contestId
        this.refreshData()
        if(this.localContestId != -1)
        {
            APIService.getContest(this.UserAuthToken, this.localContestId).then(data => {
                this.populate(data)
            })
        }
    },
    model (val, prev) {
      if (val.length === prev.length) return

      this.model = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v,
            color: this.colors[this.nonce - 1]
          }

          this.items.push(v)

          this.nonce++
        }

        return v
      })
    }
  }

}
</script>
