<template>
  <v-app id="app">
    <app-appbar-component></app-appbar-component>
    <v-main>

    <v-container fluid>
      <app-auth-dialog-component></app-auth-dialog-component>
      <router-view></router-view>
    </v-container>
    </v-main>
    <gdpr-bottom-component></gdpr-bottom-component>
    <v-footer v-if="!IsInContest" fixed padless elevation=10>
      <v-col
      class="text-right"
      cols="12"
      >
       <a href="https://www.raduangelescu.com/">&copy;Radu Angelescu</a>  {{ new Date().getFullYear() }}
      </v-col>
      </v-footer>
  </v-app>

</template>

<script>
import AppBarComponent from '@/components/AppBarComponent.vue'
import AuthDialogComponent from '@/components/AuthDialogComponent.vue'
import GdprBottomComponent from '@/components/GdprBottomComponent.vue'
import axios from 'axios'
import APIService from '@/services/APIService'

export default {
  name: 'App',
  components: {
    'app-appbar-component': AppBarComponent,
    'app-auth-dialog-component': AuthDialogComponent,
    'gdpr-bottom-component': GdprBottomComponent},
  data: () => ({
    isRefreshing: false,
    subscribers: []
  }),
  created: function () {
    var that = this
    this.$store.dispatch("refreshUser");
    axios.interceptors.response.use((response) => {
      return response
      }, err => {
      const {
        config,
        response: {status}
      } = err

      const originalRequest = config
      if (originalRequest.url.includes('authenticate')) {
        return Promise.reject(err)
      }
      if (this.UserRefreshToken === '') {
        that.$store.commit('SET_USER_AUTH_TOKEN', {token: '', refreshToken: ''})
        that.$router.push({ name: 'HomeLayout' }).catch(err => {})
      }
      // todo message for expired token
      if (status === 401 /* expired token */) {
        if (!that.isRefreshing) {
          that.isRefreshing = true
          APIService.getAuthTokenRefresh(that.UserAuthToken,that.UserRefreshToken).then(response => {
            if (response.data.token == null) {
              that.$store.commit("RESET_USER_DATA")
              that.$store.commit('SET_USER_AUTH_TOKEN', {token: '', refreshToken: ''})
              that.$store.commit('SET_ERROR_MESSAGE', 'you have been signed out')
              that.$router.push({ name: 'HomeLayout' }).catch(err => {})
            }
            else {
              that.$store.commit('SET_USER_AUTH_TOKEN', response.data)
              that.subscribers.map(cb => cb())
              that.subscribers = []
              that.isRefreshing = false
            }
          })
            .catch(error => {
              console.error(error)
              that.isRefreshing = false
              that.$store.commit('SET_USER_AUTH_TOKEN', {token: '', refreshToken: ''})
              that.$router.push({ name: 'HomeLayout' }).catch(err => {})
            })
        }
        const requestSubscribers = new Promise(resolve => {
          this.subscribers.push(() => {
            originalRequest.headers.Authorization = `Bearer ${that.UserAuthToken}`
            resolve(axios(originalRequest))
          })
        })
        return requestSubscribers
      }
    })
  }
}
</script>

<style>
</style>
