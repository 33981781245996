import Vue from 'vue'
import VueI18n from 'vue-i18n'
import APIService from '@/services/APIService'
import i18nmessages from './i18nmessages'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'ro', // set locale
  messages: i18nmessages,
  missing: function (locale, path ,vue) {
    console.warn('Missing translation for: locale:' + locale + ' path:' + path)
    if (localStorage.authToken !== '' && localStorage.isAdmin === 'true') {
      var payload = {
        lookup: path,
        en: path + '_no_locale_en',
        ro: path + '_no_locale_ro',
        hu: path + '_no_locale_hu'}
  
      APIService.createLocalization(localStorage.authToken, payload)
    }
  }
})

export default i18n; 