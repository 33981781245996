 <template>
   <v-layout>
     
     <v-container  v-if="contestData" grid>
         <v-row>
           <v-col>
             <v-card elevation=0>
              <v-card-actions >
               <v-spacer/>
               
                <v-item-group
                  v-model="currentSlide"
                  class="text-center"
                  mandatory
                >

                  <v-item
                    v-for="(question,index) in contestData.questionInstances"
                    :key="`btn-${index}`"
                    v-slot="{ active, toggle }"
                  >
                    <v-btn
                      :input-value="active"
                      icon
                      :color="getColorQuestion(question)"
                     
                      @click="toggle"
                    >
                      <v-icon  v-if="!isAnswering(question)"> mdi-record</v-icon>
                       <v-progress-circular
                        indeterminate
                        color="warning"
                        size="15"
                        v-if="isAnswering(question)">
                      </v-progress-circular>
                    </v-btn>
         
              
                  </v-item>
                </v-item-group>
                <v-spacer/>
             </v-card-actions>
             <v-window
             dark
              v-model="currentSlide"
              show-arrows
             >
              <template v-slot:prev="{ on }">
                 <v-fab-transition>
                   
              <v-btn
                color="primary"
                v-on="on"
                fab
                :small="getChevronButtonSize()"
              ><v-icon>
                mdi-chevron-left
              </v-icon></v-btn>
              </v-fab-transition>
            </template>
            <template v-slot:next="{ on }">
              
               <v-fab-transition>
              <v-btn
                color="primary"
                v-on="on"
                fab
                :small="getChevronButtonSize()"
              >
              <v-icon>
                mdi-chevron-right
              </v-icon>
              
              </v-btn>

              </v-fab-transition>
            </template>
             <v-window-item
               v-for="(question,index) in contestData.questionInstances"
                v-bind:key="index"
              >
                <v-card>
                 

                    <v-container fluid>
                       
                        <v-row  align="center" justify="center">
                        
                            <v-col cols=12>
                           
                              <v-img  
                              :src= "question.imagePath"
                              :lazy-src = "getLazy(question.imagePath)"
                              :aspect-ratio="getAspectRatio()"
                              contain
                              class="align-end"
                              height="100%"
                              @click="onImageClick(question)"
                               >
                               
                                <p :class="'text-center white--text' + getCopyrightSize()">
                                 @{{question.copyright}}
                                 
                                </p>
                              
                                <template v-slot:placeholder>
                                    <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                    >
                                    <v-progress-circular
                                        indeterminate
                                        color="purple lighten-5"
                                    ></v-progress-circular>
                                    </v-row>
                                </template>
                              </v-img>              
                            </v-col>
                        </v-row>

                    </v-container>

                  </v-card>
             </v-window-item>
        
   
             </v-window>
             </v-card>
            </v-col>
    </v-row>

         
        <v-progress-linear
          :color="progressColor"
          :value="100 - timeRemainingPercent"
          rounded
          height = 18
        >
        <strong>{{timeRemainingDisplay }}</strong>
        </v-progress-linear>
      
      <v-autocomplete  
        v-show="contestData.questionInstances[currentSlide].type == 1"
        v-model="contestData.questionInstances[currentSlide].species"
        :items="allSpecies"
        :label="getLabelQuestion(contestData.questionInstances[currentSlide])"
        v-on:change="onChangeAnswer($event, contestData.questionInstances[currentSlide])"
        dense
        :menu-props = "{auto: false, maxHeight:100, offsetOverflow: false, offsetY: true}"
      ></v-autocomplete>
      <v-container>
          <v-item-group
            v-show="contestData.questionInstances[currentSlide].type != 1"
            v-model="slideModel"
            class="text-center"
            mandatory
          >
          <v-row no-gutters>
        
          <v-col v-for="(choice,index) in contestData.questionInstances[currentSlide].choices"  v-bind:key="index">
            <v-item
              v-slot="{ active, toggle }"
            >
   
          <v-card
            :color="getColorCard(active, index)"
            class="ma-2"
            :width="getCardWidth()"
            :height="getCardHeight()"
            @click="toggle"
     
          >
          <v-row/>
            
            <v-row  align="center"
              justify="center">
              <v-col align="center"
              justify="right" >
                <span class="body-1 text-center" size="12">
                  {{choice}}
                </span>
              </v-col>
            </v-row>
    
          </v-card>

          </v-item>
          </v-col>
          </v-row>
          </v-item-group>

      </v-container>
        <v-row  align="center"
                justify="center">
          <v-col  align="center"
                  justify="center">
          <v-btn 
              huge
              outlined
              color="success"
              right
              @click="onDone"> 
                {{capitalize($t("finalizare test"))}} 
              </v-btn>
          </v-col>
        </v-row>

 <v-dialog
    v-model="showFullImage"
    transition="dialog-bottom-transition"
    fullscreen
  >
      <v-toolbar short dense>

        <v-spacer/>
        <v-btn
            icon
            fab
            small
            @click="showFullImage = false"
        >
        <v-icon>mdi-close</v-icon>
        </v-btn>
    
      </v-toolbar>
    <v-card
      height="100%">
      <v-img
      :src="fullImagePath"
      class="align-end"
      contain
      :aspect-ratio="getAspectRatio()"
      max-height="100%"
      
      > 
      <p :class="'text-center white--text '+ getCopyrightSize()">
        @{{fullImageCopyright}}
                          
      </p>
      </v-img>

    </v-card>
  </v-dialog>
  <v-dialog
      v-model="endOfContest"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          {{resultTitleText}}
        </v-card-title>

        <v-card-text>
          <v-progress-linear
          :active="loadingResults"
          :indeterminate="loadingResults"
          absolute
          bottom
          color="deep-purple accent-4"
          v-if="loadingResults"></v-progress-linear>
          {{resultContent}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="finish()"
          >
            {{capitalize($t('continue'))}}
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
  <v-dialog
      v-model="areYouSure"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
        
        {{capitalize($t('are you sure?'))}}
        </v-card-title>
        <v-card-text>
          <v-container grid>
            <v-row>
              <v-col>
              <v-progress-circular    
                  :rotate="360"
                  :size="80"
                  :width="20"
                  :value="percentAnswered"
                  color="success"
                >
                  {{numAnsweredQuestions }}
                </v-progress-circular>
              </v-col>
              <v-col cols=8>
                <p class="text-caption">
                  {{capitalize($t('you only answered'))}} {{numAnsweredQuestions}}/{{totalQuestions}}  
                </p>
                <p class="text-h6">
                  {{capitalize($t('are you sure you want to finalize the test?'))}}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
           <v-btn
            color="secondary"
            @click="iamnotsure()"
          >
            {{capitalize($t('cancel'))}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="iamsure()"
          >
            {{capitalize($t('continue'))}}
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
    </v-container>
 </v-layout>
 

</template>

<script>
import APIService from '@/services/APIService'

export default {

  data: () => ({
    slideModel: 0,
    progressColor: "green",
    currentSlide: 0,
    areYouSure: false,
    uniqueIdx: 0,
    shaped: true,
    singleLine: true,
    switchImageInterval: 10000,
    timeRemainingPercent: 1,
    showFullImage: false,
    contestStartTimeInS: 0,
    contestEndTimeInS: 0,
    currentTime: 0,
    lastUpdateCallTime: 0, 
    loading: true,
    loadingResults: false,
    endOfContest: false,
    timer: null,
    resultTitleText: '',
    resultContent: '',
    searchSpecies: null,
    fullImagePath: '',
    fullImageCopyright: '',
    allSpecies: [],
    bottomSheet: true
  }),
  mounted: function () {
    this.timer = null
    this.updateCurrentQuestionDisplay()    
  },
  destroyed: function() {
    this.$store.commit("SET_IS_IN_CONTEST", false)
  },
  computed: {
    timeRemainingDisplay: function () {
      var contestDuration = this.contestEndTimeInS - this.contestStartTimeInS
      var date = new Date(0);
      date.setSeconds(Math.ceil((this.timeRemainingPercent / 100) * contestDuration)); // specify value for SECONDS here
      var timeString = date.toISOString().substring(11, 19);
      return timeString;
    },
    totalQuestions: function () {
      if (typeof (this.contestData['questionInstances']) === 'undefined') {
        return 0
      }
      return this.contestData.questionInstances.length
    },
    percentAnswered: function () {
      if (typeof (this.contestData['questionInstances']) === 'undefined') {
        return 0
      }
      return Math.ceil((this.numAnsweredQuestions / this.contestData.questionInstances.length) * 100)
    },
    numAnsweredQuestions: function () {
      if (this.contestData == null) {
        return 0
      }
      if (typeof (this.contestData['questionInstances']) === 'undefined') {
        return 0
      }
      var that = this
      return this.contestData.questionInstances.reduce(function (total, currentValue) {
        if(that.isUserAnswerValid(currentValue)) {
          return total + 1
        }
        return total
      }, 0)
    }
  },
  methods: {
    getChevronButtonSize() {
       switch (this.$vuetify.breakpoint.name) {
          case 'xs': return true
          case 'sm': return true
          case 'md': return false
          case 'lg': return false
          case 'xl': return false
        }
    },
    getAspectRatio(){
       switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 1.4
          case 'sm': return 1.4
          case 'md': return 2.0
          case 'lg': return 2.7
          case 'xl': return 2.7
        }
    },
    isSmallSize() {
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': return true
          case 'sm': return true
          case 'md': return false
          case 'lg': return false
          case 'xl': return false
        }
    },
    getCardHeight() {
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 100
          case 'sm': return 100
          case 'md': return 100
          case 'lg': return 50
          case 'xl': return 50
        }
    },
    getCardWidth(){
       switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 145
          case 'sm': return 145
          case 'md': return 300
          case 'lg': return 500
          case 'xl': return 500
        }
    },
    getCopyrightSize() {
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': return "text-h5"
          case 'sm': return "text-h5"
          case 'md': return "text-h4"
          case 'lg': return "text-h4"
          case 'xl': return "text-h4"
        }
      
    },
    getColorCard(active, index){
      if(active) {
        if(index < this.contestData.questionInstances[this.currentSlide].choices.length - 1)
        {
          return 'success'
        }
        return 'warning' 
      }
      return 'grey lighten-1'
    },
    iamsure() {
      this.areYouSure = false
      if(this.type != "exercise")
      {
        this.endOfContest = true
        clearInterval(this.timer)
        this.endOfContest = true
        this.loadingResults = true
        this.timer = null
        this.resultTitleText = this.capitalize(this.$t('grading quizz'))
        this.resultContent = ''
    
        APIService.finishContest(this.UserAuthToken, this.contestData.contestInstance.contestTemplateId).then(response => {
          this.loadingResults = false
          this.resultTitleText = this.capitalize(this.$t('contest results'))
          this.resultContent = this.capitalize(this.$t('your grade is')) +" "+ response.contestInstance.currentGrade
        })
      }
      else
      {
        this.$emit('done-exercise')
      }
    },
    iamnotsure() {
      this.areYouSure = false
    },
    getLazy(imagePath) {
      var imageFileName = imagePath.split(/(\\|\/)/g).pop()
      var fileWithoutExtension = imageFileName.split('.').slice(0, -1).join('.')
      return "/images/"+fileWithoutExtension+".svg"
    },
    isAnswering(question) {
      return question.inProgress
    },
    getColorQuestion(question){
      if(this.isUserAnswerValid(question))
      {
        return "success"
      }
      return "warning"
    },
    onImageClick(question) {
      this.fullImagePath=  question.imagePath
      this.fullImageCopyright = question.copyright
      this.showFullImage = true;
    },
    getLabelQuestion (question) {
      if(!this.isUserAnswerValid(question)) {
        return this.capitalize(this.$t('write the correct answer'))
      }

      return this.capitalize(this.$t('you picked:')) + question.userAnswer
    },
    filter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    finish: function () {
      this.$router.push({ name: 'ContestListLayout' }).catch(err => {})
    },
    onDone: function (/*event*/) {
   
      if(this.type != "exercise")
      {
        if(this.percentAnswered == 100 || this.timeRemainingPercent <= 0)
        {
          clearInterval(this.timer)
          this.endOfContest = true
          this.loadingResults = true
          this.timer = null
          this.resultTitleText = this.capitalize(this.$t('grading quizz'))
          this.resultContent = ''
          APIService.finishContest(this.UserAuthToken, this.contestData.contestInstance.contestTemplateId).then(response => {
            this.loadingResults = false
            this.resultTitleText = this.capitalize(this.$t('contest results'))
            this.resultContent = this.capitalize(this.$t('your grade is')) +" "+ response.contestInstance.currentGrade
          })
        }
        else {
          this.areYouSure = true
        }
       
      }
      else
      {
        if(this.percentAnswered == 100 || this.timeRemainingPercent <= 0)
        {
          this.$emit('done-exercise')
        }
        else {
          this.areYouSure = true
        }
        
       
      }
    },
    isUserAnswerValid:function(question) {
      var answer = question.userAnswer
      if(answer == null || 
        answer == undefined ||
        answer == '' ||
        answer == question.choices[question.choices.length-1] ||
        question.inProgress == true){
        return false
      }
      return true
    },
    updateCurrentQuestionDisplay: function () {
      if(this.contestData == null) {
        return
      }
      if(this.contestData.questionInstances == null 
      || this.contestData.questionInstances == undefined) {
        return
      }
       
      if(this.contestData.questionInstances.length < this.currentSlide) {
        return 
      }
      var question = this.contestData.questionInstances[this.currentSlide];
      
      if(question.type == 1) {
        return
      }
     
      var answers = question.choices;
      if(!this.isUserAnswerValid(question))
      {
          this.slideModel = answers.length -1;
      }
      else
      {
        this.slideModel = answers.indexOf(question.userAnswer)
      }
    },
    onChangeAnswer: function (answer, question) {
    
      var theAnswer = answer
      if (typeof (answer.text) !== 'undefined') {
        theAnswer = answer.text
      }
      var data = {
        id: question.id,
        answer: theAnswer
      }
      if(this.type != "exercise")
      {
        question.inProgress = true
        APIService.answerQuestion(this.UserAuthToken,this.contestData.contestInstance.contestTemplateId, question.id, data).then(response => {
          const resultType = Object.freeze({
            Ok: Symbol(0),
            InvalidQuestion: Symbol(1),
            ContestExpired: Symbol(2),
            NumberOfTriesExceeded: Symbol(3),
            ContestIsClosed: Symbol(4)
          })
          switch (parseInt(response.data)) {
            case resultType.Ok: // Ok
              break
            case resultType.InvalidQuestion: // InvalidQuestion
              break
            case resultType.ContestExpired: // ContestExpired
              break
            case resultType.NumberOfTriesExceeded: // NumberOfTriesExceeded
              break
            case resultType.ContestIsClosed: // ContestIsClosed
              break
          }
          question.userAnswer = theAnswer
          question.inProgress = false
        })
      }
      else
      {
        question.userAnswer = theAnswer
      }
    },
    getDateTimeFromSQL (date) {
      var t = date.split(/[- TZ :]/)
      // Apply each element to the Date function
      var dateTime = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]))
      return dateTime.getTime() / 1000.0
    }
  },
  props: ['contestData', 'type'],
  watch: {
    bottomSheet: function (newVal, oldVal) {
      
      var that = this
      if(newVal == false)
      {
        setTimeout(function(){ that.bottomSheet = true }, 200);
      }
    },
    slideModel: function(newVal, oldVal) {
      var question = this.contestData.questionInstances[this.currentSlide];
      var answers = question.choices;
      this.onChangeAnswer(answers[newVal], question)
    },
    currentSlide: function(newVal, oldVal) {
      this.updateCurrentQuestionDisplay()
    },
    contestData: function (newVal /*,oldVal*/) { // watch it
      
      this.contestStartTimeInS = new Date(newVal.contestInstance.startDateTime).getTime() / 1000.0
      this.contestEndTimeInS = new Date(newVal.contestInstance.endDateTime).getTime() / 1000.0
      var serverTime = new Date(newVal.contestInstance.currentDateTime).getTime() / 1000.0
      this.currentTime = serverTime
      this.lastUpdateCallTime = Date.now()
      var self = this
      if(this.timer != null) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(function() {
          var contestDuration = self.contestEndTimeInS - self.contestStartTimeInS
          var dt = (Date.now() - self.lastUpdateCallTime) / 1000.0
          self.lastUpdateCallTime = Date.now()
          self.currentTime = self.currentTime + dt
          self.timeRemainingPercent = ((self.contestEndTimeInS - self.currentTime)/contestDuration)*100
          if(self.timeRemainingPercent < 50 && self.timeRemainingPercent > 25)
          {
            self.progressColor = "warning"
          }
          else if (self.timeRemainingPercent < 25)
          {
            self.progressColor = "red"
          }
          else
          {
            self.progressColor = "green"
          }
         
          if (self.timeRemainingPercent <= 0) {
            self.onDone(null)
          }
      }, 1000)
      this.loading = false
      var nomenclature = this.contestData.contestInstance.nomenclature
      var shouldRequestSpecies = false
      this.contestData.questionInstances.forEach(question => {
        if(question.type == 1)
        {
          shouldRequestSpecies = true
        }
      })
      if(shouldRequestSpecies) {
        APIService.getSpecies(this.UserAuthToken).then(species => {
          this.allSpecies = []

          species.forEach(specie => {
            var nomenclatureMap = [
              specie.scientificName,
              specie.englishName,
              specie.romanianName,
              specie.hungarianName
            ]
            var _text = nomenclatureMap[nomenclature]
            if(nomenclature != 0){
              _text += " ("+specie.scientificName+")"  
            }
            this.allSpecies.push(_text)
          })
        })
      }
  
      this.updateCurrentQuestionDisplay()
    }
  }
}

</script>
<style lang='scss'>
  @import '~vuetify/src/styles/styles.sass';
  @import '~vuetify/src/components/VProgressCircular/_variables.scss';
  .v-progress-circular__underlay {
  stroke: #FB8C00 !important;
  }
  .my-span {
  color: white;
  font-weight: bold;
  margin-right: 0;
  float: right;
}
</style>