<template>
<v-card>
    <v-card-text>
        <v-form  
        ref="formMediu" 
        v-model="valid"
        :disabled="multiButton.state == 'edit' || isLoading">
        
        <v-container>
        <v-row>
                <v-col cols=9>
                </v-col>
                <v-col>
                <v-btn :color="multiButton.states[multiButton.state].color"
                :disabled="!canPress()"
                @click="onPress()">
                    {{capitalize($t(multiButton.states[multiButton.state].text))}}
                    <v-icon color="white">{{multiButton.states[multiButton.state].icon}}</v-icon>
                </v-btn>
                </v-col>
            </v-row>
        <v-row>
            <v-col>
                <v-checkbox v-model="planteInfo.valid" :label="capitalize($t('s-au plantat'))"/>
            </v-col>
            <v-col  v-if="planteInfo.valid">
                <v-combobox
                    v-model="planteInfo.selected"
                    :filter="filterSpeciiObservate"
                    :hide-no-data="!searchSpeciiPlante"
                    :items="tipuriPlante"
                    :search-input.sync="searchSpeciiPlante"
                    hide-selected
                    :label="capitalize($t('search for a specie'))"
                    multiple
                    small-chips
                    solo
                    >
                     <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip
                        v-if="item === Object(item)"
                        v-bind="attrs"
                        :color="`${item.color} lighten-3`"
                        :input-value="selected"
                        label
                        small
                        >
                        <span class="pr-2">
                            {{ item.text }}
                        </span>
                        <v-icon
                            small
                            @click="parent.selectItem(item)"
                        >mdi-close</v-icon>
                        </v-chip>
                    </template>
                    <template v-slot:item="{item}">
                        <v-chip
                        :color="`${item.color} lighten-3`"
                        dark
                        label
                        small
                        >
                        {{ item.text }}
                        </v-chip>
                        <v-spacer></v-spacer>
                        <v-list-item-action @click.stop>

                        </v-list-item-action>
                    </template>
                </v-combobox>
 
            </v-col>
        </v-row>
        <v-row  v-if="planteInfo.valid">
            <v-col>
            <image-upload-component
            :parent="planteInfo"
            v-on:modifyImage="changeImage"
            v-model="planteInfo.image"
            :disabled="multiButton.state == 'edit' || isLoading"
            
            />
            <v-text-field 
                read-only
                :rules="[required]" 
                v-model="planteInfo.imageURL">
            </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-checkbox v-model="cresteHranitoare.valid" :label="capitalize($t('creste hranitoare'))"/>
            </v-col>
        </v-row>
        <v-row v-if="cresteHranitoare.valid">
            <v-col>
                <image-upload-component
                :parent="cresteHranitoare"
                v-on:modifyImage="changeImage"
                v-model="cresteHranitoare.image"
                :disabled="multiButton.state == 'edit' || isLoading"
                />
                <v-text-field 
                read-only
                :rules="[required]" 
                v-model="cresteHranitoare.imageURL">
            </v-text-field>
            </v-col>

        </v-row>
        <v-row>
            <v-col >
                <v-checkbox v-model="cuiburiArtificialeInfo.valid" :label="capitalize($t('cuiburi artificiale'))"/>
            </v-col>
        </v-row>
        <v-row v-if="cuiburiArtificialeInfo.valid">
            <v-col cols=3>
                <v-text-field v-if="cuiburiArtificialeInfo.valid" 
                type="number"
                v-model="cuiburiArtificialeInfo.numarTipA"
                :label="capitalize($t('numar de cuiburi tip A'))"/>
            </v-col>
            <v-col cols=3>
                <v-text-field v-if="cuiburiArtificialeInfo.valid" 
                type="number"
                v-model="cuiburiArtificialeInfo.numarTipB"
                :label="capitalize($t('numar de cuiburi tip B'))"/>
            </v-col>
            <v-col cols=3>
                <v-text-field v-if="cuiburiArtificialeInfo.valid" 
                type="number"
                v-model="cuiburiArtificialeInfo.numarTipC"
                :label="capitalize($t('numar de cuiburi tip C'))"/>
            </v-col>
            <v-col cols=3>
                <v-text-field v-if="cuiburiArtificialeInfo.valid" 
                type="number"
                v-model="cuiburiArtificialeInfo.numarRandunica"
                :label="capitalize($t('numar de cuiburi randunica'))"/>
            </v-col>
            <v-col cols=3>
                <v-text-field v-if="cuiburiArtificialeInfo.valid" 
                type="number"
                v-model="cuiburiArtificialeInfo.numarLastun"
                :label="capitalize($t('numar de cuiburi lastun'))"/>
            </v-col>
        </v-row>
        <v-row v-if="cuiburiArtificialeInfo.valid"> 
                <v-checkbox v-if="cuiburiArtificialeInfo.valid" 
                v-model="cuiburiArtificialeInfo.igienizare"
                :label="capitalize($t('igienizare cuiburi'))"/>
        </v-row>
        <v-row  v-if="cuiburiArtificialeInfo.valid">
            <v-col>
                <image-upload-component
                :parent="cuiburiArtificialeInfo"
                v-on:modifyImage="changeImage"
                v-model="cuiburiArtificialeInfo.image"
                :disabled="multiButton.state == 'edit' || isLoading"
                />
                <v-text-field 
                read-only
                :rules="[required]" 
                v-model="cuiburiArtificialeInfo.imageURL">
            </v-text-field>
            </v-col>
        </v-row>
        <v-row v-if="cuiburiArtificialeInfo.valid">
            <v-col>
                <v-combobox
                    v-model="cuiburiArtificialeInfo.pasariCuibaritoare"
                    :filter="filterSpeciiObservate"
                    :hide-no-data="!searchSpeciiObservate"
                    :items="toateSpeciile"
                    :search-input.sync="searchSpeciiObservate"
                    hide-selected
                    :label="capitalize($t('specii cuibarite'))"
                    multiple
                    small-chips
                    solo
                    >
                     <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip
                        v-if="item === Object(item)"
                        v-bind="attrs"
                        :color="`${item.color} lighten-3`"
                        :input-value="selected"
                        label
                        small
                        >
                        <span class="pr-2">
                            {{ item.text }}
                        </span>
                        <v-icon
                            small
                            @click="parent.selectItem(item)"
                        >mdi-close</v-icon>
                        </v-chip>
                    </template>
                    <template v-slot:item="{item}">
                        <v-chip
                        :color="`${item.color} lighten-3`"
                        dark
                        label
                        small
                        >
                        {{ item.text }}
                        </v-chip>
                        <v-spacer></v-spacer>
                        <v-list-item-action @click.stop>

                        </v-list-item-action>
                    </template>
                </v-combobox>
       
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-checkbox v-model="materialeCuibarireInfo.valid" :label="capitalize($t('materiale cuibarire'))"/>
            </v-col>
        </v-row>
        <v-row v-if="materialeCuibarireInfo.valid">
            <v-col>
                <v-select 
                v-model="materialeCuibarireInfo.selected"
                :items="tipuriMaterialeCuibarire"/>
            </v-col>
            <v-col>
                 <v-text-field 
                    v-if="materialeCuibarireInfo.selected == 4"
                :label="capitalize($t('alte materiale'))"/>
            </v-col>
        </v-row>
           
        <v-row v-if="materialeCuibarireInfo.valid">
            <v-col>
                <image-upload-component
                :parent="materialeCuibarireInfo"
                v-on:modifyImage="changeImage"
                v-model="materialeCuibarireInfo.image"
                :disabled="multiButton.state == 'edit' || isLoading"
                />
                <v-text-field 
                read-only
                :rules="[required]" 
                v-model="materialeCuibarireInfo.imageURL">
            </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-checkbox v-model="sursaDeNoroi.valid" :label="capitalize($t('sursa de noroi'))"/>
            </v-col>
        </v-row>
        <v-row v-if="sursaDeNoroi.valid">
            <v-col>
                <image-upload-component
                :parent="sursaDeNoroi"
                v-on:modifyImage="changeImage"
                v-model="sursaDeNoroi.image"
                :disabled="multiButton.state == 'edit' || isLoading"
                
                />
                <v-text-field 
                read-only
                :rules="[required]" 
                v-model="sursaDeNoroi.imageURL"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-checkbox v-model="raftDeProtectie.valid" :label="capitalize($t('raft de protectie'))"/>
            </v-col>
        </v-row>
        <v-row v-if="raftDeProtectie.valid">
            <v-col>
                <image-upload-component
                :parent="raftDeProtectie"
                v-on:modifyImage="changeImage"
                v-model="raftDeProtectie.image"
                :disabled="multiButton.state == 'edit' || isLoading"
                />
                <v-text-field 
                read-only
                :rules="[required]" 
                v-model="raftDeProtectie.imageURL"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-checkbox v-model="adapatoareInfo.valid" :label="capitalize($t('adapatoare'))"/>
            </v-col>
        </v-row>
        <v-row v-if="adapatoareInfo.valid">
            <v-col>
            <image-upload-component
            :parent="adapatoareInfo"
            v-on:modifyImage="changeImage"
            v-model="adapatoareInfo.image"
            :disabled="multiButton.state == 'edit' || isLoading"
            />
            <v-text-field 
                read-only
                :rules="[required]" 
                v-model="adapatoareInfo.imageURL"/>
            </v-col>
        </v-row>
        <v-row v-if="adapatoareInfo.valid">
            <v-col>
                <v-checkbox  v-model="adapatoareInfo.primavara" :label="capitalize($t('primavara'))"/>
            </v-col>
            <v-col>
                <v-checkbox  v-model="adapatoareInfo.toamna" :label="capitalize($t('toamna'))"/>
            </v-col>
            <v-col>
                <v-checkbox  v-model="adapatoareInfo.iarna" :label="capitalize($t('iarna'))"/>  
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-checkbox v-model="baieDeNisip.valid" :label="capitalize($t('baie de nisip'))"/>
            </v-col>
        </v-row>
        <v-row v-if="baieDeNisip.valid">
            <v-col>
            <image-upload-component
            :parent="baieDeNisip"
            v-on:modifyImage="changeImage"
            v-model="baieDeNisip.image"
            :disabled="multiButton.state == 'edit' || isLoading"
            />
            <v-text-field 
                read-only
                :rules="[required]" 
                v-model="baieDeNisip.imageURL"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-checkbox v-model="refugiuBroaste.valid" :label="capitalize($t('refugiu broaste'))"/>
            </v-col>
        </v-row>
        <v-row v-if="refugiuBroaste.valid">
            <v-col>
                <image-upload-component
                :parent="refugiuBroaste"
                v-on:modifyImage="changeImage"
                v-model="refugiuBroaste.image"
                :disabled="multiButton.state == 'edit' || isLoading"
                />
                <v-text-field 
                read-only
                :rules="[required]" 
                v-model="refugiuBroaste.imageURL"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-checkbox v-model="adapostArici.valid" :label="capitalize($t('adapost arici'))"/>
            </v-col>
        </v-row>
        <v-row v-if="adapostArici.valid">
            <v-col>
                <image-upload-component
                :parent="adapostArici"
                v-on:modifyImage="changeImage"
                v-model="adapostArici.image"
                :disabled="multiButton.state == 'edit' || isLoading"
                />
                <v-text-field 
                read-only
                :rules="[required]" 
                v-model="adapostArici.imageURL"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-checkbox v-model="hotelInsecte.valid" :label="capitalize($t('hotel insecte'))"/>
            </v-col>
        </v-row>
        <v-row v-if="hotelInsecte.valid">
            <v-col>
                <image-upload-component
                :parent="hotelInsecte"
                v-on:modifyImage="changeImage"
                v-model="hotelInsecte.image"
                :disabled="multiButton.state == 'edit' || isLoading"
                />
                <v-text-field 
                read-only
                :rules="[required]" 
                v-model="hotelInsecte.imageURL"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-checkbox v-model="ladaDeCompost.valid" :label="capitalize($t('lada de compost'))"/>
            </v-col>
        </v-row>
         <v-row v-if="ladaDeCompost.valid">
            <v-col>
            <image-upload-component
            :parent="ladaDeCompost"
            v-on:modifyImage="changeImage"
            v-model="ladaDeCompost.image"
            :disabled="multiButton.state == 'edit' || isLoading"
            />
            <v-text-field 
                read-only
                :rules="[required]" 
                v-model="ladaDeCompost.imageURL"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-checkbox v-model="alteEvenimenteSOR.valid" :label="capitalize($t('alte evenimente sor'))"/>
            </v-col>
        </v-row>
        <v-row v-model="alteEvenimenteSOR.valid">
            <v-col>
                <list-text-items 
                :parent="alteEvenimenteSOR"
                :fields="alteEvenimenteSOR.fields"
                :v-on:modifyList="listChange"
                listName="toateEvenimentele"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-checkbox v-model="ziNatura.valid" :label="capitalize($t('zi natura'))"/>
            </v-col>
        </v-row>
        <v-row v-for="(item, idx) in ziNatura.zile" v-bind:key="idx">
            <v-container v-if="ziNatura.valid">
                <v-row>
                    <v-col>
                        <v-checkbox v-model="item.valid" :label="capitalize($t(item.text))"/>
                    </v-col>
                </v-row>
                 <v-row v-if="item.valid">
                    <v-col>
                        <image-upload-component
                        :parent="item"
                        v-on:modifyImage="changeImage"
                        v-model="item.image"
                        :disabled="multiButton.state == 'edit' || isLoading"
                        />
                        <v-text-field 
                        read-only
                        :rules="[required]" 
                        v-model="item.imageURL"/>
                    </v-col>
                </v-row>
                <v-row v-if="item.valid">
                    <v-col>
                        <v-textarea 
                        prepend-icon="mdi-comment"
                        :label="capitalize($t('modalitate celebrare'))" v-model="item.modalitate"></v-textarea>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>
        
        </v-container>
    </v-form>
    </v-card-text>
</v-card>
</template>
<script>
import APIService from '@/services/APIService'
import ListTextItems from '@/components/ListTextItems'
import ImageUploadComponent from '@/components/ImageUploadComponent'

export default {
    props:['model'],
    watch: {
        model: function (newVal) {
            this.planteInfo.valid = newVal.planteInfo.valid
            this.planteInfo.selected = newVal.planteInfo.selected
            this.planteInfo.imageURL = newVal.planteInfo.imageURL 
            this.cresteHranitoare.valid = newVal.cresteHranitoare.valid
            this.cresteHranitoare.imageURL = newVal.cresteHranitoare.imageURL
            this.cuiburiArtificialeInfo.valid = newVal.cuiburiArtificialeInfo.valid
            this.cuiburiArtificialeInfo.numarTipA = newVal.cuiburiArtificialeInfo.numarTipA
            this.cuiburiArtificialeInfo.numarTipB = newVal.cuiburiArtificialeInfo.numarTipB
            this.cuiburiArtificialeInfo.numarTipC = newVal.cuiburiArtificialeInfo.numarTipC
            this.cuiburiArtificialeInfo.numarRandunica = newVal.cuiburiArtificialeInfo.numarRandunica
            this.cuiburiArtificialeInfo.numarLastun = newVal.cuiburiArtificialeInfo.numarLastun
            this.cuiburiArtificialeInfo.igienizare = newVal.cuiburiArtificialeInfo.igienizare
            this.cuiburiArtificialeInfo.pasariCuibaritoare = newVal.cuiburiArtificialeInfo.pasariCuibaritoare
            this.cuiburiArtificialeInfo.imageURL = newVal.cuiburiArtificialeInfo.imageURL
            this.materialeCuibarireInfo.valid = newVal.materialeCuibarireInfo.valid
            this.materialeCuibarireInfo.selected = newVal.materialeCuibarireInfo.selected
            this.materialeCuibarireInfo.altmaterial = newVal.materialeCuibarireInfo.altmaterial
            this.materialeCuibarireInfo.imageURL = newVal.materialeCuibarireInfo.imageURL
            this.sursaDeNoroi.valid = newVal.sursaDeNoroi.valid
            this.sursaDeNoroi.imageURL = newVal.sursaDeNoroi.imageURL
            
            this.raftDeProtectie.valid = newVal.raftDeProtectie.valid
            this.raftDeProtectie.imageURL = newVal.raftDeProtectie.imageURL
            this.adapatoareInfo.valid = newVal.adapatoareInfo.valid 
            this.adapatoareInfo.primavara = newVal.adapatoareInfo.primavara 
            this.adapatoareInfo.toamna = newVal.adapatoareInfo.toamna
            this.adapatoareInfo.iarna = newVal.adapatoareInfo.iarna
            this.adapatoareInfo.imageURL = newVal.adapatoareInfo.imageURL
            this.baieDeNisip.valid = newVal.baieDeNisip.valid
            this.baieDeNisip.imageURL = newVal.baieDeNisip.imageURL
            this.refugiuBroaste.valid = newVal.refugiuBroaste.valid
            this.refugiuBroaste.imageURL = newVal.refugiuBroaste.imageURL
            this.adapostArici.valid = newVal.adapostArici.valid
            this.adapostArici.imageURL = newVal.adapostArici.imageURL
            this.hotelInsecte.valid = newVal.hotelInsecte.valid
            this.hotelInsecte.imageURL = newVal.hotelInsecte.imageURL
            this.ladaDeCompost.valid = newVal.ladaDeCompost.valid
            this.ladaDeCompost.imageURL = newVal.ladaDeCompost.imageURL
            this.alteEvenimenteSOR.valid = newVal.ladaDeCompost.valid
            this.alteEvenimenteSOR.toateEvenimentele = newVal.ladaDeCompost.toateEvenimentele
            this.ziNatura.valid = newVal.ziNatura.valid
            this.ziNatura.zile = []
            var that = this
            newVal.ziNatura.zile.forEach(
                zi =>{
                that.ziNatura.zile.push(
                    {text: zi.text,
                    valid: zi.valid,
                    imageURL: zi.imageURL,
                    modalitate: zi.modalitate}
                    )
                })
            this.isLoading = false
        }
    },
    components: {
    'image-upload-component':ImageUploadComponent,
    'list-text-items':ListTextItems
    },
    mounted: function () {
        this.refreshData()
    },
    data: () => ({
        multiButton:{
            states :{
                'edit':{
                    color: 'warning',
                    text: 'edit',
                    icon: 'mdi-pencil'
                },
                'save':{
                    color: 'success',
                    text:'save',
                    icon: 'mdi-floppy'
                }
            },
            state: 'edit'
        },
        valid: false,
        isLoading: false,
        planteInfo: {
            valid: false,
            selected:'',
            image:null,
            imageURL:''
        },
        searchSpeciiPlante: '',
        searchSpeciiObservate:'',
        toateSpeciile:[],
        cresteHranitoare:{
            valid:false,
            image:null,
            imageURL:''
        },
        cuiburiArtificialeInfo:{
            valid:false,
            numarTipA:0,
            numarTipB:0,
            numarTipC:0,
            numarRandunica:0,
            numarLastun:0,
            igienizare:false,
            pasariCuibaritoare:'',
            image:null,
            imageURL:''
        },
        materialeCuibarireInfo:{
            valid:false,
            selected: 1,
            altmaterial: '',
            image: null,
            imageURL: ''
        },
        sursaDeNoroi: {
            valid: false,
            image: null,
            imageURL:''
        },
        raftDeProtectie: {
            valid: false,
            image: null,
            imageURL: ''
        },
        adapatoareInfo:{
            valid: false,
            primavara: false,
            toamna: false,
            iarna: false,
            image: null,
            imageURL: ''
        },
        baieDeNisip:{
            valid:false,
            image: null,
            imageURL: ''
        },
        refugiuBroaste:{
            valid:false,
            image:null,
            imageURL: ''
        },
        adapostArici:{
            valid:false,
            image:null,
            imageURL: ''
        },
        hotelInsecte:{
            valid:false,
            image:null,
            imageURL: ''
        },
        ladaDeCompost:{
            valid:false,
            image:null,
            imageURL:''
        },
        alteEvenimenteSOR: {
            valid: false,
            fields:['name','modalitate'],
            toateEvenimentele:[]
        },
        ziNatura: {
            valid:false,
            zile:[{
                text:'zone umede', 
                valid:false,
                image: null,
                imageURL: '',
                modalitate: ''
                },
                {
                text:'ziua ariciului', 
                valid:false,
                image: null,
                imageURL: '',
                modalitate: ''
                },
                {
                text:'ziua polen', 
                valid:false,
                image: null,
                imageURL: '',
                modalitate: ''
                },
                {
                text:'ziua apei', 
                valid:false,
                image: null,
                imageURL: '',
                modalitate: ''
                },
                {
                text:'ziua vrabiilor', 
                valid:false,
                image: null,
                imageURL: '',
                modalitate: ''
                },
                {
                text:'luna padurii', 
                valid:false,
                image: null,
                imageURL: '',
                modalitate: ''
                },
                {
                text:'ziua pasarilor', 
                valid:false,
                image: null,
                imageURL: '',
                modalitate: ''
                },
                {
                text:'ziua pamantului', 
                valid:false,
                image: null,
                imageURL: '',
                modalitate: ''
                },
                {
                text:'ziua pasarilor si a arborilor', 
                valid:false,
                image: null,
                imageURL: '',
                modalitate: ''
                }
            ]
        },
        plantsRaw:["Abuta         ",
"Acacia        ",
"Acalypha      ",
"Acer          ",
"Adenanthos    ",
"Aerangis      ",
"Agave         ",
"Aiphanes      ",
"Albizia       ",
"Allium        ",
"Aloe          ",
"Amaranthus    ",
"Amomum        ",
"Angraecum     ",
"Annona        ",
"Anthurium     ",
"Antirrhinum   ",
"Arabis        ",
"Arceuthobium  ",
"Ardisia       ",
"Aristida      ",
"Asparagus     ",
"Astragalus    ",
"Attalea       ",
"Baccharis     ",
"Baeckea       ",
"bamboo        ",
"Banksia       ",
"Begonia       ",
"Berberis      ",
"Mahonia       ",
"Betula        ",
"Blechnum      ",
"Boronia       ",
"Brachyscome   ",
"Bryum         ",
"Bulbophyllum  ",
"Caladenia     ",
"Campanula     ",
"Canna         ",
"Cardamine     ",
"Carex         ",
"Castanopsis   ",
"Centaurea     ",
"Chamelaucium  ",
"Coccothrinax  ",
"Coleus        ",
"Commelina     ",
"Convolvulus   ",
"Cordia        ",
"Corymbia      ",
"Cousinia      ",
"Crepidium     ",
"Crinum        ",
"Crotalaria    ",
"Croton        ",
"Cryptocarya   ",
"Cuscuta       ",
"cycad         ",
"Cyperus       ",
"Dahlia        ",
"Delphinium    ",
"Dendrobium    ",
"Dendrochilum  ",
"Desmodium     ",
"Diospyros     ",
"Drosera       ",
"Durio         ",
"Elaeocarpus   ",
"Elaphoglossum ",
"elm           ",
"Encyclia      ",
"Epidendrum    ",
"Eragrostis    ",
"Eremophila    ",
"Erica         ",
"Erigeron      ",
"Erysimum      ",
"Eucalyptus    ",
"Eugenia       ",
"Eulophia      ",
"Euphorbia     ",
"Ficus         ",
"Fritillaria   ",
"Galium        ",
"Garcinia      ",
"Geranium      ",
"Geum          ",
"Goodenia      ",
"Grevillea     ",
"Grewia        ",
"Guzmania      ",
"Habenaria     ",
"hawthorn      ",
"Helichrysum   ",
"Hibbertia     ",
"Hieracium     ",
"Hippeastrum   ",
"Hoya          ",
"Hypericum     ",
"Ilex          ",
"Impatiens     ",
"Inga          ",
"Ipomoea       ",
"Iris          ",
"Isolepis      ",
"Ixora         ",
"Jasminum      ",
"Justicia      ",
"Lepanthes     ",
"Licania       ",
"Lilium        ",
"Lithocarpus   ",
"Lobelia       ",
"Lonchocarpus  ",
"Masdevallia   ",
"Maxillaria    ",
"Melaleuca     ",
"Miconia       ",
"Millettia     ",
"Myristica     ",
"Narcissus     ",
"Nepenthes     ",
"Nepenthes     ",
"Nicotiana     ",
"Oberonia      ",
"Ocotea        ",
"Oenothera     ",
"Omphalodes    ",
"Ornithogalum  ",
"Oxalis        ",
"Pabstiella    ",
"Pachypodium   ",
"Pandanus      ",
"Panicum       ",
"Passiflora    ",
"Pavonia       ",
"Pelargonium   ",
"Peperomia     ",
"Persea        ",
"Phacelia      ",
"Philodendron  ",
"Phyllanthus   ",
"Pilea         ",
"Pinguicula    ",
"Pinus         ",
"Piper         ",
"Planchonella  ",
"Pleurothallis ",
"Poales        ",
"Polygala      ",
"Potentilla    ",
"Prasophyllum  ",
"Protea        ",
"Prunus        ",
"Psychotria    ",
"Ptilotus      ",
"Pultenaea     ",
"Quercus       ",
"Ranunculus    ",
"Rhododendron  ",
"Ribes         ",
"Rosa          ",
"Rubus         ",
"Salix         ",
"Salvia        ",
"Saurauia      ",
"Schefflera    ",
"Scleria       ",
"Sedum         ",
"Senecio       ",
"Shorea        ",
"Silene        ",
"Sisyrinchium  ",
"Solanum       ",
"Sorbus        ",
"Sphagnum      ",
"Sporobolus    ",
"Stelis        ",
"Stylidium     ",
"Symphyotrichum",
"Syzygium      ",
"Taeniophyllum ",
"Terminalia    ",
"Teucrium      ",
"Thelymitra    ",
"Tillandsia    ",
"Trisetum      ",
"Utricularia   ",
"Uvaria        ",
"Vanilla       ",
"Vernonia      ",
"Verticordia   ",
"Viola         "],
        }),
    methods: {
        required (value) {
            if (!value) return this.capitalize(this.$t('required'))
            if (value == ' ') return this.capitalize(this.$t('required'))
            return true
        },
        onPress() {
            if(this.multiButton.state == 'edit') {
                this.multiButton.state = 'save'
            }
            else {
                var data ={
                    contentType: 'Mediu',
                    planteInfo: {
                        valid: this.planteInfo.valid,
                        selected: this.planteInfo.selected,
                        imageURL: this.planteInfo.imageURL
                    },
                    cresteHranitoare: {
                        valid: this.cresteHranitoare.valid,
                        imageURL: this.cresteHranitoare.imageURL
                    },
                    cuiburiArtificialeInfo: {
                        valid: this.cuiburiArtificialeInfo.valid,
                        numarTipA: this.cuiburiArtificialeInfo.numarTipA,
                        numarTipB: this.cuiburiArtificialeInfo.numarTipB,
                        numarTipC: this.cuiburiArtificialeInfo.numarTipC,
                        numarRandunica: this.cuiburiArtificialeInfo.numarRandunica,
                        numarLastun: this.cuiburiArtificialeInfo.numarLastun,
                        igienizare: this.cuiburiArtificialeInfo.igienizare,
                        pasariCuibaritoare: this.cuiburiArtificialeInfo.pasariCuibaritoare,
                        imageURL: this.cuiburiArtificialeInfo.imageURL
                    },
                    materialeCuibarireInfo: {
                        valid: this.materialeCuibarireInfo.valid,
                        selected: this.materialeCuibarireInfo.selected,
                        altmaterial: this.materialeCuibarireInfo.altmaterial,
                        imageURL: this.materialeCuibarireInfo.imageURL
                    },
                    sursaDeNoroi: {
                        valid: this.sursaDeNoroi.valid,
                        imageURL: this.sursaDeNoroi.imageURL,
                    },
                    raftDeProtectie: {
                        valid: this.raftDeProtectie.valid,
                        imageURL: this.raftDeProtectie.imageURL,
                    },
                    adapatoareInfo: {
                        valid: this.adapatoareInfo.valid,
                        primavara: this.adapatoareInfo.primavara,
                        toamna: this.adapatoareInfo.toamna,
                        iarna: this.adapatoareInfo.iarna,
                        imageURL: this.adapatoareInfo.imageURL
                    },
                    baieDeNisip: {
                        valid: this.baieDeNisip.valid,
                        imageURL: this.baieDeNisip.imageURL,
                    },
                    refugiuBroaste: {
                        valid: this.refugiuBroaste.valid,
                        imageURL: this.refugiuBroaste.imageURL,
                    },
                    adapostArici: {
                        valid: this.adapostArici.valid,
                        imageURL: this.adapostArici.imageURL,
                    },
                    hotelInsecte: {
                        valid: this.hotelInsecte.valid,
                        imageURL: this.hotelInsecte.imageURL,
                    },
                    ladaDeCompost: {
                        valid: this.ladaDeCompost.valid,
                        imageURL: this.ladaDeCompost.imageURL,
                    },
                    alteEvenimenteSOR: {
                        valid: this.alteEvenimenteSOR.valid,
                        toateEvenimentele: this.alteEvenimenteSOR.toateEvenimentele
                    },
                    ziNatura: {
                        valid: this.ziNatura.valid,
                        zile: []
                    }
                }
                this.ziNatura.zile.forEach(
                    zi =>{
                        data.ziNatura.zile.push({
                            text: zi.text,
                            valid: zi.valid,
                            imageURL: zi.imageURL,
                            modalitate: zi.modalitate
                        })
                    }
                )
                this.isLoading = true
                this.$emit("saveContent", data)
                this.multiButton.state = 'edit'
            }
        },
        canPress(){
            if(this.multiButton.state == 'edit') {
                return true
            }
            if(this.multiButton.state == 'save') {    
                return this.valid
            }
            return true
        },  
        listChange(list, parent, listName) {
            parent[listName] = list
        },
        changeImage(data){
            data.parent.imageURL = data.imageURL
        },
        refreshData() {
            APIService.getSpecies(this.UserAuthToken).then(species => {
            this.toateSpeciile = []

            species.forEach(specie => {
                var nomenclatureMap = [
                specie.scientificName,
                specie.englishName,
                specie.romanianName,
                specie.hungarianName
                ]
                var _text = nomenclatureMap[0]
                this.toateSpeciile.push({value:specie.id, text:_text})
            })
            })
        },
        filterSpeciiObservate (item, queryText, itemText) {
        if (item.header) return false

        const hasValue = val => val != null ? val : ''

        const text = hasValue(itemText)
        const query = hasValue(queryText)

        return text.toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) > -1
        },
    },
    computed: {
        tipuriMaterialeCuibarire() {
            return [
                {value:1, text:this.capitalize(this.$t("par animal om"))},
                {value:2, text:this.capitalize(this.$t("fan"))},
                {value:3, text:this.capitalize(this.$t("pene"))},
                {value:4, text:this.capitalize(this.$t("alt material"))}
            ]
        },
        tipuriPlante() {
            return this.plantsRaw.map((plantRaw, idx) =>{
                return {
                    text: plantRaw.trim(),
                    value: idx
                }
            })
        }
    }
}
</script>
