<template>
  
  <v-dialog v-if="AgreeGdpr" v-model="dialogAuthShow" persistent max-width="600px">
    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>
        <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon >
                <country-flag  :country="languagesDisp[CurrentLanguage].icon" >
                </country-flag>
                {{languagesDisp[CurrentLanguage].code.toUpperCase()}}
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="language in languagesDisp" @click="changeLanguage(language)" v-bind:key="language.code">
                <v-list-item-title>
                  <country-flag :country="language.icon" v-bind:squared=false />
                  </v-list-item-title>
                  <v-list-item-action-text>{{language.code.toUpperCase()}}</v-list-item-action-text>
              </v-list-item>
            </v-list>

          </v-menu>
          <v-spacer/>
          <v-btn icon @click="hideDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-card-title>
      <v-card-text>

        <v-tabs centered color="success"  icons-and-text>
          <v-tabs-slider color="green"></v-tabs-slider>
          <v-tab href="#tab-1">{{$t('login')}}</v-tab>
          <v-tab href="#tab-2">{{$t('register')}}</v-tab>

          <v-tab-item value="tab-1" key="1">
            <v-card flat>
              <v-card-title>
               
              </v-card-title>
              <v-card-text>
                <v-container fluid grid>

                 <v-row>
                 <v-col>
                 <v-alert 
                 outlined
                 dismissible
                 v-model="alert"
                  transition="scale-transition"
                 type="error" >{{validUsernameAndPassword()}}</v-alert>
                 </v-col>
                 </v-row>
                <v-form v-model="isValid">

                      <v-text-field
                        prepend-icon="mdi-email"
                        v-model="loginForm.email"
                        placeholder=" "
                        name="username"
                        autocomplete="username"
                        :label="capitalize($t('email'))"
                        type="text"
                        v-show="!isResetPressed"
                        :rules="[emailMatch]"
                      ></v-text-field>

                      <v-text-field v-if="!isForgot"
                        v-model="loginForm.password"
                        autocomplete="password"
                        prepend-icon="mdi-lock"
                        name="password"
                        id="password"
                        placeholder=" "
                        :label="capitalize($t('password'))"
                        :type="loginForm.showPassword ?  'text' : 'password'"
                        @click:append="() => (loginForm.showPassword = !loginForm.showPassword)"
                        :append-icon="loginForm.showPassword ? 'mdi-eye' : 'mdi-eye-off'"

                      ></v-text-field>

                
                </v-form>
                </v-container>
                <v-alert
                outlined
                transition="scale-transition"
                type="error" 
                v-if="isResetPressed">
                  {{capitalize($t('Check your email'))}} 
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" v-if="!isForgot" outlined @click="toResetPassword">{{$t('forgot_password')}}</v-btn>
                <v-btn color="warning" outlined v-if="isForgot" @click="toLoginPassword">{{$t('login')}}</v-btn>
                <v-btn color="success" @click="authenticate" v-if="!isForgot" :disabled="!isValid">{{$t('login')}}</v-btn>
                <v-btn color="success" @click="resetPassword" v-if="isForgot" :disabled="isResetPressed || !isValid">{{$t('reset_password')}}</v-btn>
              </v-card-actions>

            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-2" key="2">

            <profile-data-component mode="register" :userid="invalidUserId"></profile-data-component>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import APIService from '@/services/APIService'
import ProfileDataComponent from '@/components/ProfileDataComponent'
export default {
  components: {'profile-data-component': ProfileDataComponent},
  data: () => ({
    isValid: false,
    isForgot: false,
    isResetPressed: false,
    localCurrentLanguage: -1,
    invalidUserId: -1,
    alert: false,
    loginForm: {
      email: '',
      password: '',
      isFailed: false,
      showPassword: false
    }
  }),
  computed: {
    isLoading() {
      return this.UserAuthStatus === 'loggingIn'
    }
  },
  mounted: function () {
    this.localCurrentLanguage = this.CurrentLanguage
  },
  watch: {
    User: function (newVal/*,oldVal*/) {
     
    },
    UserAuthStatus:function (newVal) {
      if(this.UserAuthStatus == 'error'){
        this.alert = true
      }
    },
    CurrentLanguage: function (newVal, oldVal) {

    }
  },

  methods: {
    hideDialog: function() {
      this.$store.commit("SET_DIALOG_AUTH_SHOW", false); 
    },
    changeLanguage: function (language) {
      this.$store.dispatch('setUserCurrentLanguage', 
        {currentLanguage: language.code})
   },
    toResetPassword () {
      this.isForgot = true
    },
    toLoginPassword () {
      this.isForgot = false
      this.isResetPressed = false
    },
    resetPassword () {
      APIService.startResetPassword(this.authToken, this.loginForm.email)
      this.isResetPressed = true
    },
    validUsernameAndPassword (/*value*/) {
      console.log(this.UserAuthStatus)
      if (this.UserAuthStatus == 'error') return this.capitalize(this.$t('bad username or password'))
      return []
    },
    required (value) {
      if (!value) return this.capitalize(this.$t('required'))
      return true
    },
    min (v) {
      if (v.length < 8) return this.capitalize(this.$t('needs at least 8 characters'))
      return true
    },
    emailMatch (v) {
      // eslint-disable-next-line no-useless-escape
      var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

      if (regEmail.test(v)) {
        return true
      } else {
        return this.capitalize(this.$t('invalid email'))
      }
    },
    register () {},
    authenticate () {
      this.$store.dispatch('loginUser',
        { email: this.loginForm.email,
          password: this.loginForm.password})
      
    }
  }
}
</script>
<style>

</style>
