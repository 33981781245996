<template>

<v-container grid-list-xl fluid>

  <v-row v-if="e1==2">
      <fullscreen-countdown :time="5" :enabled="true" v-on:doneTimer="onDoneTimer()"/>
      <question-data-component v-show="showQuestion" :contestData="contestData" type="exercise" 
              v-on:done-exercise="doneStep2"
              />
          
  </v-row>
  <v-row v-show="e1!==2">
    <v-col align="center"
      justify="center">
        <v-card
            class="mb-12"
            v-if="e1 == 2"
            :loading = "dataLoading"
          >
          <v-card-title>
          <v-spacer></v-spacer>

          </v-card-title>
            <v-card-actions>
               <v-btn
            color="primary"
            :disabled="!checkStep()"
            @click="nextStep(n)"
            v-if="getTextForButton() != ''"
          >
            {{getTextForButton()}}
          </v-btn>
            </v-card-actions>
         </v-card>
    <v-stepper v-model="e1" max-width="1000px"  v-show="e1!==2">
      <v-stepper-header>
        <template>
          <v-card v-for="n in steps">
          <v-stepper-step
            :key="`${n}-step`"
            :complete="e1 > n"
            :step="n"
            
          >
            {{ capitalize($t(stepsTitle[n-1])) }}
          </v-stepper-step>

          <v-divider
            v-if="n !== steps"
            :key="n"
          ></v-divider>
        </v-card>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          v-for="n in steps"
          :key="`${n}-content`"
          :step="n"
        >
          <v-card
            class="mb-12"
            v-if="e1 == 1"
          >
          
            
          <v-container >
            <v-form ref="formConfig">
              <v-row v-for="(error, index) in allErrorsForTest()" v-bind:key="index">
                  <v-col>
                    <v-alert border="left" 
                    transition="scale-transition" dense type="error">
                    {{error.msg}}</v-alert>
                  </v-col>
                </v-row>
              <v-row >
                
                <v-col v-show="!advancedConfig">
                  <v-select 
                  :label="capitalize($t('choose dificulty'))"
                  v-model="selectedDifficulty"
                  :items="allDifficulty"
                  :rules="[ruleRequiredSelect]"
                  > </v-select>
                </v-col>
                <v-col>
                  <v-select 
                  :label="capitalize($t('nomenclature'))" 
                  v-model="selectedNomenclature" 
                  :items="nomenclaturesItems"
                  :rules="[ruleRequiredSelect]"
                  ></v-select>
                </v-col>
              </v-row>
               <v-row>
                <v-col>
                <v-checkbox 
                v-model="advancedConfig"
                :label="capitalize($t('advanced config'))"></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
            <div v-show="advancedConfig">
                <v-row>
                    <v-col>
                        <v-subheader>{{capitalize($t('contest duration (seconds)'))}}</v-subheader>
                        <v-slider
                            v-model="timeForQuestions.current"
                            class="align-center"
                            :max="timeForQuestions.max"
                            :min="timeForQuestions.min"
                            hide-details
                        >
                            <template v-slot:append>
                                <v-text-field
                                    v-model="timeForQuestions.current"
                                    class="mt-0 pt-0"
                                    hide-details
                                    single-line
                                    type="number"
                                    style="width: 50px"
                                ></v-text-field>
                            </template>
                        </v-slider>
                    </v-col>
                </v-row>   
        <v-data-iterator
        :items="contestQuestions"
        :items-per-page.sync="itemsPerPage"
        :footer-props="{ itemsPerPageOptions }"
        >
        <template v-slot:header>
            <v-toolbar
            dark
            color="blue darken-3"
            >
            <v-toolbar-title>{{capitalize($t('questions'))}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn fab small color="green" @click="addQuestion">
                    <v-icon dark>mdi-plus</v-icon>
                </v-btn>

            </v-toolbar>
        </template>

        <template v-slot:default="props">
         
            <v-row>
            <v-col
                v-for="(question, number) in props.items"
                v-bind:key="number"
                cols="12"
                sm="12"
                md="12"
                lg="12"
            >

                <v-card>
                <v-card-title class="subheading font-weight-bold">{{capitalize($t('question'))}} {{(question.number + 1)}}/{{contestQuestions.length}}
                    <v-spacer></v-spacer>
                    <v-btn color="error" fab small @click="removeQuestion(number)"><v-icon dark class="mx-2">mdi-delete</v-icon> </v-btn>
                </v-card-title>
                
                <v-divider></v-divider>
                <v-container>
                    <v-row v-for="(error, index) in allErrorsForQuestion(question.number)" v-bind:key="index">
                      <v-col>
                        <v-alert border="left" 
                        transition="scale-transition" dense type="error">
                        {{error.msg}}</v-alert>
                      </v-col>
                    </v-row>
              
                      <v-row>
                            <v-col>
                                <v-select :label="capitalize($t('type'))" v-model="question.type" :items="questionTypes"></v-select>
                            </v-col>
                            <v-col v-if="question.type==0">
                                <v-select :label="capitalize($t('number of choices'))" v-model="question.numberOfChoices" :items="numberOfChoicesArray"></v-select>
                            </v-col>
                        </v-row>
                               <v-row>
                            <v-col cols="2">
                                <v-checkbox :label="capitalize($t('use all species'))"  v-model="question.useAllSpecies"></v-checkbox>
                            </v-col>
                            <v-col v-if="!question.useAllSpecies">
                                   <v-combobox
                                    v-model="question.species"
                                    :filter="filter"
                                    :hide-no-data="!searchSpecies"
                                    :items="allSpecies"
                                    :search-input.sync="searchSpecies"
                                    hide-selected
                                    :label="capitalize($t('search for a specie'))"
                                    multiple
                                    small-chips
                                    solo
                                    >

                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                        <v-chip
                                        v-if="item === Object(item)"
                                        v-bind="attrs"
                                        :color="`${item.color} lighten-3`"
                                        :input-value="selected"
                                        label
                                        small
                                        >
                                        <span class="pr-2">
                                            {{ item.text }}
                                        </span>
                                        <v-icon
                                            small
                                            @click="parent.selectItem(item)"
                                        >mdi-close</v-icon>
                                        </v-chip>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <v-chip
                                        :color="`${item.color} lighten-3`"
                                        dark
                                        label
                                        small
                                        >
                                        {{ item.text }}
                                        </v-chip>
                                        <v-spacer></v-spacer>
                                        <v-list-item-action @click.stop>

                                        </v-list-item-action>
                                    </template>
                                    </v-combobox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2">
                                <v-checkbox :label="capitalize($t('use all cladas'))"   v-model="question.useAllCladas"></v-checkbox>
                            </v-col>
                             <v-col v-if="!question.useAllCladas">
                                   <v-combobox
                                    v-model="question.cladaId"
                                    :filter="filter"
                                    :hide-no-data="!searchCladas"
                                    :items="allCladas"
                                    hide-selected
                                    solo
                                    >

                                    </v-combobox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-checkbox :label="capitalize($t('use alternatives'))" v-model="question.useAlternatives"></v-checkbox>
                            </v-col>
                        </v-row>
                </v-container>
                </v-card>
            </v-col>
            </v-row>
        </template>

        </v-data-iterator>
             </div>
          </v-container>
          
          </v-card>
          
      
         <v-card v-if="e1 == 3">
           <contest-results-details :contestData="contestData" />
         </v-card>
          <v-btn
            color="primary"
            :disabled="!checkStep()"
            @click="nextStep(n)"
            v-if="getTextForButton() != ''"
          >
            {{getTextForButton()}}
          </v-btn>

        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import APIService from '@/services/APIService'
import ContestDetailsModal from '@/components/ContestDetailsModal'
import QuestionComponent from '@/components/QuestionComponent'
import FullScreenCountdown from '@/components/FullScreenCountdown'
import ContestResultsDetails from '@/components/ContestResultsDetails'
export default {
  components: {
    'contest-details-modal': ContestDetailsModal,
    'question-data-component': QuestionComponent,
    'contest-results-details': ContestResultsDetails,
    'fullscreen-countdown': FullScreenCountdown
  },
  data: () => ({
      e1: 1,
      steps: 3,
      contestData: '',
      selectedDifficulty: -1,
      selectedNomenclature: -1,
      advancedConfig: false,
      dataLoading: false,
      showQuestion: false,
      stepsTitle:[
        "configure",
        "test",
        "results"
      ],
       numberOfPages: 0,
    allLanguages: [],
    page: 0,
    itemsPerPage: 1,
    itemsPerPageOptions: [1],
    contestName: '',
    nomenclature: 0,
    audienceGroup: 0,
    language: 1,
    searchSpecies: null,
    searchCladas: null,
    timeForQuestions: {
      min: 2,
      max: 6000,
      current: 100
    },
    contestPeriod: [],
    maxNumberOfTries: {
      min: 1,
      max: 100,
      current: 1
    },
    questionsPerPage: [1, 2, 3],
    contestQuestions: [],
    numberOfChoicesArray: [
      3,
      5,
      8
    ],
    allSpecies: [],
    allCladas: [],
    currentQuestionInfo:
        {
          imageDifficulty: {
            min: 1,
            max: 6,
            range: [1, 6]
          },
          useAllSpecies: true,
          useAllCladas: true,
          useAlternatives: false,
          numberOfChoices: 3,
          type: 0,
          cladaId: {text: 'pasari', value: 1},
          species: []
        }
  }),
  computed: {
    allErrors: function () {
      return this.checkQuestions()
    },

    questionTypes: function () {
      return [
        {value: 0, text: this.capitalize(this.$t('multiple choice'))},
        {value: 1, text: this.capitalize(this.$t('text input'))}]
    },
    typeLabel: function () {
      return this.capitalize(this.$t('type'))
    },
    allDifficulty: function ()
    {
      var difficulties = [
            {
              text: this.capitalize(this.$t("easy")),
              value: 0
            },
            {
              text: this.capitalize(this.$t("medium")),
              value: 1
            },
            {
              text: this.capitalize(this.$t("hard")),
              value: 2
            },
            {
              text:this.capitalize(this.$t("expert")),
              value: 3
            }
          ]
      return difficulties
    }
  },
  mounted: function () {
    this.refreshData()
  },
  watch: {
     steps (val) {
        if (this.e1 > val) {
          this.e1 = val
        }
      },
      e1(val) {
        if(val != 2)
        {
          this.$store.commit("SET_IS_IN_CONTEST", false)
        }
        else {
          var data = null
          if(!this.advancedConfig)
          {
            data = this.generateSimpleConfigContest()
          }
          else
          {
            data = this.populateFromAdvanced()
          }
          this.dataLoading = true
          var that = this
          APIService.getExercise(data).then((contest)=>{
            contest.questionInstances.map(q => {
              if (q.type === 0) {
                var choicesArray = JSON.parse(q.choices)
                choicesArray.push(this.capitalize(this.$t("don't know")))
                q.choices = choicesArray
                q.userAnswer = null
              }
            })
            that.contestData = contest
            that.dataLoading = false
          })
        

        }
      }
  },
  methods: {
    allErrorsForTest() {
      return this.allErrors.filter(w => w.number == -1)
    },
    allErrorsForQuestion(questionNum) {
      return this.allErrors.filter(w => w.number == questionNum)
    },
    refreshData() {
      this.contestName = ""
      this.id = -1
      this.nomenclature = 0
      this.audienceGroup = 0
      this.timeForQuestions.current = 100
      this.maxNumberOfTries.current = 1
      this.contestQuestions  = []
      var today = new Date()
      var date = today.toISOString()
      this.contestPeriod[0] = date
      this.contestPeriod[1] = date
      this.language = 1
      this.allLanguages = []
      this.languagesDisp.forEach(x=> 
      {
        this.allLanguages.push({value: x.value, text: x.code})
      })
      
      APIService.getSpeciesWithImages(this.UserAuthToken).then(species => {
        this.allSpecies = []
        species.forEach(specie => {
          this.allSpecies.push({
            text: specie.scientificName,
            value: specie.id
          })
        })
      })
      APIService.getCladas(this.UserAuthToken).then(cladas => {
        this.allCladas = []
        cladas.forEach(clada => {
          this.allCladas.push({
            text: clada.name,
            value: clada.id
          })
        })
      })
    },
    addQuestion () {
      var newEntry = Object.assign({}, this.currentQuestionInfo)
      newEntry.number = this.contestQuestions.length
      this.contestQuestions.push(newEntry)
    },
    removeQuestion (itemIdx) {
      this.contestQuestions.splice(itemIdx, 1)
      this.contestQuestions.forEach(function (item, number) {
        item['number'] = number
      })
    },
    edit (number, item) {
      if (!this.editing) {
        this.editing = item
        this.number = number
      } else {
        this.editing = null
        this.number = -1
      }
    },
    filter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    onDoneTimer() {
      this.$store.commit("SET_IS_IN_CONTEST", true)
      this.showQuestion = true
    },
    doneStep2() {
      this.e1 = 3
    },
    populateFromAdvanced() {
       var today = new Date()
        var date = today.toISOString()
     
       var ret =  {
        'ContestTemplate': {
            'Id': -1,
            'CreatorId': -1,
            'Name':"exercise",
            'TimeForQuestion': this.timeForQuestions.current,
            'MaxNumberOfTries': 1,
            'Nomenclature': this.selectedNomenclature,
            'availableInCertainPeriod': false,
            'Language': 0,
            'StartDateTime': date,
            'EndDateTime': date
          },
      'QuestionTemplates':this.contestQuestions.map(function (question, number) {
          var questionId = -1
          if('id' in question)
          {
              questionId = question.Id
          }
          return {
            'id': questionId,
            'number': question.number,
            'type': question.type,
            'minimumDifficulty': 1,
            'maximumDifficulty': 6,
            'useAllSpecies': question.useAllSpecies,
            'useAllCladas': question.useAllCladas,
            'useAlternatives': question.useAlternatives,
            'numberOfChoices': question.numberOfChoices,
            'cladaId': question.cladaId.value,
            'species': question.species.map(function (item) { return {'id': item.value, 'scientificName': item.text} })
          }
        })
      }
      return ret
    },
    generateSimpleConfigContest() {
      var timeForQuestion = 0
      var numberOfQuestions = 0
      var type = 0
      var numberOfChoices = 0
      switch(this.selectedDifficulty)
      {
        case 0:
          timeForQuestion = 20
          numberOfQuestions = 10
          type = 0
          numberOfChoices = 3
          break;
        case 1:
          timeForQuestion = 20
          numberOfQuestions = 10
          type = 0
          numberOfChoices = 5
          break;
        case 2:
          timeForQuestion = 20
          numberOfQuestions = 10
          type = 0
          numberOfChoices = 8
          break;
        case 3:
          timeForQuestion = 20
          numberOfQuestions = 10
          type = 1
          numberOfChoices = 0
          break;
      }
      var ret =  {
      'ContestTemplate': this.generateContestPart(timeForQuestion, this.selectedNomenclature),
      'QuestionTemplates': this.generateQuestionsPart(numberOfQuestions, type, numberOfChoices)
      }
      return ret
    },
    generateContestPart(timeForQuestion, nomenclature) {
      var today = new Date()
      var date = today.toISOString()
      var data = {
          'Id': -1,
          'CreatorId': -1,
          'Name':"exercise",
          'TimeForQuestion': timeForQuestion,
          'MaxNumberOfTries': 1,
          'Nomenclature': nomenclature,
          'Language': this.CurrentLanguage,
          'StartDateTime': date,
          'EndDateTime': date
        }
      return data
    },
    generateQuestionsPart(numberOfQuestions, type, numberOfChoices)
    {
      var questiontpl = {
          'Id': -1,
          'Number': 1,
          'Type': type,
          'UseAllSpecies': true,
          'UseAllCladas': true,
          'UseAlternatives': false,
          'MinimumDifficulty': 0,
          'MaximumDifficulty': 10,
          'NumberOfChoices': numberOfChoices,
          'CladaId': -1,
          'ContestId': -1,
          'Species': []
      }
      var questions = []
      for(var i = 0 ; i < numberOfQuestions; i++)
      {
        questions.push(questiontpl)
      }
      return questions
    },
    ruleRequiredSelect(v) {
      if(v == -1) {
        return this.capitalize(this.$t('required'))
      }
      return true
    },
    getTextForButton() {
      if(this.e1 == 1) {
        return this.capitalize(this.$t('continue'))
      }
      if(this.e1 == 3) {
        return this.capitalize(this.$t('restart'))
      }
      return ""
    },
    checkQuestions() {
      var errors = []
     
      if(this.selectedNomenclature == -1) {
            errors.push({number:-1,msg:this.$t("no nomenclature")})
      }
      if(this.advancedConfig == false && this.selectedDifficulty == -1) {
         errors.push({number:-1,msg:this.$t("no dificulty")})
      } 
      if(this.advancedConfig == true && this.contestQuestions.length == 0) {
        errors.push({number:-1,msg:this.$t("no questions")})
      }
      this.contestQuestions.forEach((question) =>{
        if(question.useAllSpecies == false && question.useAllCladas == false) {
          errors.push({number:question.number,msg:this.$t("cladas or species not both")})
        }
        if(question.useAllSpecies == false && question.species.length == 0) {
          errors.push({number:question.number,msg:this.$t("select at least one species")})
        }
      })
      return errors
    },
    checkStep(){
      if(this.e1 == 1)
      {
        if(this.advancedConfig == false) {
          if(this.selectedDifficulty == -1) {
            return false
          }
          if(this.selectedNomenclature == -1) {
            return false
          }
          return true;
        }
        else {
          if(this.selectedNomenclature == -1) {
            return false
          }
          var errors = this.checkQuestions()
          return errors.length == 0
        }
        return true

      }
      if(this.e1 == 3) {
        return true
      }
      return false
    },
    nextStep (n) {
      if (this.e1 == 1) {
        this.e1 = 2
      }
      if(this.e1 == 3) {
        this.e1 = 1
        this.selectedDifficulty = -1
        this.selectedNomenclature = -1
      }
    },
  }
}
</script>
<style>

</style>
