<template>
    <v-container>
      <v-card>
        <v-card-title>
          {{capitalize($t('strings'))}}
          <v-spacer></v-spacer>
          <v-text-field
                v-model="search"
                append-icon="mdi-text-search"
                :label="capitalize($t('search'))"
                single-line
                hide-details
              ></v-text-field>
          </v-card-title>
          <v-container>
              <v-data-table
                :headers="headers"
                :items="strings"
                :items-per-page="5"
                :search="search"
                :loading="isLoading"
                class="elevation-1"
              >
              <template v-slot:[`item.hu`]="props">
                    <v-edit-dialog
                    :return-value.sync="props.item.hu"
                    @save="save(props.item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                    > {{ props.item.hu }}
                        <template v-slot:input>
                            <v-text-field
                            v-model="props.item.hu"
                            :label="$t('edit')"
                            single-line
                            counter
                            ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:[`item.en`]="props">
                    <v-edit-dialog
                    :return-value.sync="props.item.en"
                    @save="save(props.item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                    >
                    {{ props.item.en }}
                        <template v-slot:input>
                            <v-text-field
                            v-model="props.item.en"
                            :label="$t('edit')"
                            single-line
                            counter
                            ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:[`item.ro`]="props">
                    <v-edit-dialog
                    :return-value.sync="props.item.ro"
                    @save="save(props.item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                    > {{ props.item.ro }}
                        <template v-slot:input>
                            <v-text-field
                            v-model="props.item.ro"
                            :label="$t('edit')"
                            single-line
                            counter
                            ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:[`item.action`]="{ item }">

                    <v-icon
                        small
                        color="red"
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
              </v-data-table>

             <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false">Close</v-btn>
    </v-snackbar>
            </v-container>
   </v-card>

    </v-container>
</template>
<script>
import APIService from '@/services/APIService'

export default {
  mounted: function () {
    this.isLoading = true
    var that = this
    APIService.getLocalizations().then((data) => {
      that.strings = data
      that.isLoading = false
    })
  },
  methods: {
    deleteItem (item) {
      APIService.deleteLocalization(this.UserAuthToken, item).then(response => {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.$t('data deleted')
        APIService.getLocalizations().then((data) => {
          this.strings = data
        })
      })
      this.snack = true
      this.snackColor = 'warning'
      this.snackText = this.$t('deleting')
    },
    save (item) {
      APIService.updateLocalization(this.UserAuthToken, item).then(response => {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.$t('data saved')
      })
      this.snack = true
      this.snackColor = 'warning'
      this.snackText = this.$t('saving')
    },
    cancel () {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = this.$t('canceled')
    },
    open () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = this.$t('dialog opened')
    },
    close () {
      console.log('Dialog closed')
    }
  },
  computed: {
    headers: function () {
      return [
        {text: this.capitalize(this.$t('id')), value: 'lookup'},
        {text: this.capitalize(this.$t('english')), value: 'en'},
        {text: this.capitalize(this.$t('romanian')), value: 'ro'},
        {text: this.capitalize(this.$t('hungarian')), value: 'hu'},
        { text: this.capitalize(this.$t('action')), value: 'action', sortable: false }]
    }
  },
  data: () => ({
    isLoading:true,
    strings: [],
    snack: false,
    snackColor: '',
    snackText: '',
    search: ''
  })
}
</script>
