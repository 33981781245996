<template>
    <v-container>
      <v-card>
        <v-card-title>
          {{capitalize($t('contests'))}}
          <v-spacer></v-spacer>
          <v-text-field
                v-model="search"
                append-icon="mdi-search"
                :label="capitalize($t('search'))"
                single-line
                hide-details
              ></v-text-field>
          </v-card-title>
          <v-container>
              <v-data-table
                :headers="headers"
                :items="contestsDisplay"
                :items-per-page="5"
                :search="search"
                :loading="isLoading"
                class="elevation-1"
              >
             <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-spacer></v-spacer>
                    <contest-details-modal 
                    v-on:done-contests="refreshOnDone"
                    v-on:close_dialog="closeEditDialog"
                    :contest-id="contestId" 
                    :dlg="dialog"/>
                </v-toolbar>

               </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn icon
                  @click="deleteItem(item)">
                    <v-icon
                        color="error"
                        
                    >
                        mdi-delete
                    </v-icon>
                  </v-btn>
                  <v-btn icon  @click="editItem(item)">
                    <v-icon
                        color="warning"
                       
                    >
                        mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn icon @click="duplicateContest(item)">
                    <v-icon
                        color="green"
                    >
                        mdi-content-duplicate
                    </v-icon>
                  </v-btn>
                  <v-btn icon @click="getResults(item)">
                    <v-icon
                      color = "purple">
                      mdi-chart-areaspline
                    </v-icon>
                  </v-btn>
                  <v-btn icon @click="startDeleteResults(item)"> 
                    <v-icon
                      color = "red">
                      mdi-chart-areaspline
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
             <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false">{{$t('close')}}</v-btn>
    </v-snackbar>
    <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        width="auto"
        v-model="confirmDialog"
      >
          <v-card>
            <v-toolbar
              color="red"
              title="Opening from the top"
            ></v-toolbar>
            <v-card-text>
              <div class="text-h2 pa-12">{{$t("Are you sure you want to delete all results from contest?")}}</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                color="success"
                @click="confirmDialog = false"
              >No</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                variant="text"
                color="error"
                @click="deleteResults"
              >Yes</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
    </v-col>
  </v-container>
   </v-card>

    </v-container>
</template>
<script>
import APIService from '@/services/APIService'
import ContestDetailsModal from '@/components/ContestDetailsModal'
export default {
  components: {
    'contest-details-modal': ContestDetailsModal
  },
  mounted: function () {
    this.refreshContests()
  },
  methods: {
    getAudienceGroup: function (audienceInt) {
      var audienceGroupObject = this.audienceGroups.find(x => x.id == audienceInt)
      if (audienceGroupObject)
        return audienceGroupObject.name
      return "unknown"
    },
    refreshOnDone: function() {
      this.refreshContests()
    },
    getResults:function(item) {
      this.$router.push({ path: 'contest_edit/' + item.id+'/results', params: { id: item.id } }).catch(err => {})
    },
    startDeleteResults: function(item)
    {
      this.confirmDialog = true
      this.contestIDForDelete = item.id
    },
    deleteResults: function() {
      APIService.deleteContestResults(this.UserAuthToken, this.contestIDForDelete).then(()=>{
        this.refreshContests();
        this.confirmDialog = false
      })
    },
    duplicateContest: function(item) {
      APIService.duplicateContest(this.UserAuthToken, item.id).then((/*response*/) => {
          this.snack = true
          this.snackColor = 'success'
          this.snackText = this.capitalize(this.$t('contest_duplicated'))
          this.refreshContests()
        })
        this.snack = true
        this.snackColor = 'warning'
        this.snackText = this.capitalize(this.$t('duplicating_contest'))
    },
    deleteItem: function (item) {
      APIService.deleteContest(this.UserAuthToken, item.id).then((/*response*/) => {
          this.snack = true
          this.snackColor = 'success'
          this.snackText = this.capitalize(this.$t('contest_deleted'))
          this.refreshContests()
        })
        this.snack = true
        this.snackColor = 'warning'
        this.snackText = this.capitalize(this.$t('deleting'))
    },
    editItem: function (item) {
      this.dialog = true 
      this.contestId = item.id
    },
    closeEditDialog: function() {
      this.contestId = -1;
      this.dialog = false
    },
    refreshContests: function () {
      this.isLoading = true
      var that = this
      APIService.getAudienceGroups(this.UserAuthToken).then((data) => {
        this.audienceGroups = data;
      
        APIService.getContests(this.UserAuthToken).then(contests => {
          that.contestsDisplay = []

          contests.forEach(contest => {
            that.contestsDisplay.push({
              name: contest.name,
              audienceGroup: that.getAudienceGroup(contest.audienceGroup),
              id: contest.id,
              language: that.Languages[contest.language],
              nomenclature: that.nomenclaturesItems[contest.nomenclature].text
            })
          })
          that.isLoading = false
        })
      })
    }
  },
  computed: {
    headers: function () {
      return [
        {text: this.capitalize(this.$t('contest name')), value: 'name'},
        {text: this.capitalize(this.$t('audience group')), value: 'audienceGroup'},
        {text: this.capitalize(this.$t('language')), value: 'language'},
        {text: this.capitalize(this.$t('nomenclature')), value: 'nomenclature'},
        {text: this.capitalize(this.$t('action')), value: 'action', sortable: false}]
    },
  },
  watch: {

  },
  data: () => ({
    audienceGroups: [],
    isLoading: false,
    contestId: -1,
    contestsDisplay: [],
    search: '',
    snack: false,
    snackColor: '',
    snackText: '',
    dialog: false,
    confirmDialog: false,
    contestIDForDelete: -1,
    newEntry:{}
  })
}
</script>
