<template>
    <v-container>
      <v-dialog v-model="dialog" max-width="800px" transition="dialog-bottom-transition">
      <template v-slot:activator="{ on }">
      </template>
      <v-card>
        <contest-results-details :contestData="contestData" />
      </v-card>
 
      </v-dialog>
      <v-card>
        <v-card-title>
          {{capitalize($t('available_contests'))}}
          <v-spacer></v-spacer>
          <v-text-field
                v-model="search"
                append-icon="mdi-text-search"
                :label="capitalize($t('search'))"
                single-line
                hide-details
              ></v-text-field>
          </v-card-title>
            <v-container>

              <v-data-table
                :headers="headers"
                :items="contestsDisplay"
                :items-per-page="5"
                :search="search"
                :loading="isLoading"
                class="elevation-1"
              >
              <v-text-field
                v-model="search"
                append-icon="mdi-text-search"
                :label="capitalize($t('search'))"
                single-line
                hide-details
              ></v-text-field>
               <template v-slot:[`item.description`]="{ item }">
              <v-icon :color="item.status.color" >{{item.status.icon}}</v-icon>
              </template>
              <template v-slot:[`item.action`]="{ item }">
              <v-btn color="success" @click="joinContest(item)" v-if="!item.exceededNumberOfTries && item.isActive">
              {{getJoinText(item)}}
              </v-btn>
              <v-alert v-if="!item.isActive || item.exceededNumberOfTries">
                <v-btn color="success" v-if="item.currentNumberOfTries != 0" @click="getDetails(item)" >
                  {{$t('see_results')}}
              </v-btn></v-alert>
              </template>
              </v-data-table>

            </v-container>
   </v-card>
    </v-container>
</template>
<script>
import APIService from '@/services/APIService'
import ContestResultsDetails from '@/components/ContestResultsDetails'

export default {
  mounted: function () {
    this.refreshContests()
  },
  components: {
    'contest-results-details': ContestResultsDetails,
  },
  methods: {
    getAudienceGroup: function (audienceInt) {
      var audienceGroupObject = this.audienceGroups.find(x => x.value == audienceInt)
      if (audienceGroupObject)
        return audienceGroupObject.text;
      return "unkonown"
    },
    getCurrentGrade: function (contest) {
      if (contest.startDateTime === '0001-01-01T00:00:00') {
        return '~'
      }
      return '' + contest.currentGrade
    },
    getContestNumberOfTries: function (contest) {
      if (contest.startDateTime === '0001-01-01T00:00:00') {
        return '0/' + contest.maxNumberOfTries
      }
      return '' + contest.currentNumberOfTries + '/' + contest.maxNumberOfTries
    },
    getExceededNumberOfTries (contest) {
      if (contest.startDateTime === '0001-01-01T00:00:00') {
        return false
      }
      return contest.currentNumberOfTries >= contest.maxNumberOfTries
    },
    getJoinText: function (contest) {
      if (contest.currentNumberOfTries == 0 && contest.startDateTime === '0001-01-01T00:00:00') {
        return this.$t('join')
      } else if (contest.isClosed) {
        return this.$t('retry')
      } else {
        return this.$t('continue')
      }
    },
    getDetails: function (contest) {
      var that = this;
      APIService.getContestResults(this.UserAuthToken, contest.id).then((contest) => {
        contest.questionInstances.map(q => {
              if (q.type === 0) {
                var choicesArray = JSON.parse(q.choices)
                choicesArray.push(this.capitalize(this.$t("don't know")))
                q.choices = choicesArray
                q.infoLink = ""
              }
            })
            that.contestData = contest
            that.dialog = true
      })
    },
    getContestStatusIcon: function (contest) {
      if (contest.startDateTime === '0001-01-01T00:00:00') {
        return {icon: 'mdi-stop', color: 'gray'}
      } else if (contest.currentNumberOfTries >= contest.maxNumberOfTries) {
        return {icon: 'mdi-check', color: 'green'}
      } else if (contest.closed) {
        return {icon: 'mdi-progress-alert', color: 'orange'}
      } else {
        return {icon: 'mdi-progress-clock', color: 'red'}
      }
    },
    refreshContests: function () {
      this.isLoading = true
      APIService.getAudienceGroups(this.UserAuthToken).then((data) => {
      this.audienceGroups = data.map(x => {return {text:x.name, value:x.id}})
      APIService.getContests(this.UserAuthToken).then(contests => {
        this.contestsDisplay = []

        contests.forEach(contest => {
          this.contestsDisplay.push({
            name: contest.name,
            id: contest.id,
            audienceGroup: this.getAudienceGroup(contest.audienceGroup),
            language: this.Languages[contest.language],
            nomenclature: this.nomenclaturesItems[contest.nomenclature].text,
            description: '',
            status: this.getContestStatusIcon(contest),
            numberOfTries: this.getContestNumberOfTries(contest),
            currentNumberOfTries: contest.currentNumberOfTries,
            exceededNumberOfTries: this.getExceededNumberOfTries(contest),
            grade: this.getCurrentGrade(contest),
            isClosed: contest.isClosed,
            time: contest.time,
            isActive : contest.isActive,
            startDateTime: contest.startDateTime,
            currentDateTime : contest.currentDateTime,
            endDateTime : contest.endDateTime
          })
        })
        this.isLoading = false
      })
      })
    },
    joinContest: function (item) {
      var pars = { id: item.id, isClosed: item.isClosed }
      this.$router.push({ name: 'QuizzLayout', params: pars }).catch(err => {})
    },
  },
  computed: {
    headers: function () {
      return [
        {text: this.capitalize(this.$t('contest name')), value: 'name'},
        {text: this.capitalize(this.$t('audience group')), value: 'audienceGroup'},
        {text: this.capitalize(this.$t('language')), value: 'language'},
        {text: this.capitalize(this.$t('nomenclature')), value: 'nomenclature'},
        {text: this.capitalize(this.$t('status')), value: 'description'},
        {text: this.capitalize(this.$t('tries')), value: 'numberOfTries'},
        {text: this.capitalize(this.$t('current grade')), value: 'grade'},
        {text: this.capitalize(this.$t('time')), value: 'time'},
        {text: this.capitalize(this.$t('action')), value: 'action', sortable: false}]
    },
  },
  data: () => ({
    isLoading: false,
    contestsDisplay: [],
    search: '',
    contestData: '',
    dialog: false,
    audienceGroups: []
  })
}
</script>
