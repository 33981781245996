<template>
        <v-overlay z-index =10000 opacity=0.9 v-show="isCounting">
            <v-fab-transition>
            <p v-show="show" :class="'text-h1 '+getText().color+ '--text'">
                
            {{getTextVal()}}
            </p>
            </v-fab-transition>
           
        </v-overlay>
</template>
<script>
export default {
    props: {
        time: {
            type: Number,
            default: 0
        },
        enabled: false
    },
    watch: {
    enabled: function (newVal /*,oldVal*/) {
     this.start()
    }
   
    },
    data: function () {
        return {
            isCounting: false,
            show: true,
            total: '',
            minutes: '--',
            seconds: '--',
            interval: 0,
            intervalAnim: 0,
            texts: [
                {text: 'get ready', color:'error'},
                {text: 'set', color:'warning'},
                {text:'go', color:'success'}
            ]
        }
    },
    mounted: function () {
        this.total = parseInt(this.time, 10)
        if(this.enabled)
        {
            this.start()
        }
    },
    methods: {
        start() {
        if(this.enabled) {
                this.interval = setInterval(() => {
                    this.tick()
                }, 1400)
                this.intervalAnim = setInterval(()=> {
                    this.anim()
                }, 700)
                this.tick()
            }
        },
        getTextVal() {
            if(this.total <= 2) {
            return this.capitalize(this.$t(this.getText().text))
            }
            return this.getText().text
        },
        getText(){
            if(this.seconds == '3') {
                return this.texts[0]
            }
            else if(this.seconds == '2' )
            {
                return this.texts[1]
            }
            else if(this.seconds == '1')
            {
                return this.texts[2]
            }
            return {text:""+this.total , color:"grey"}
        },
        str_pad_left: function (string, pad, length) {
            return (new Array(length+1).join(pad)+string).slice(-length)
        },
        anim: function() {
            this.show = !this.show
        },
        tick: function () {
            this.isCounting = true
            var minutes = Math.floor(this.total / 60)
            var seconds = this.total - minutes * 60
            this.minutes = this.str_pad_left(minutes, '0', 2)
            this.seconds = this.str_pad_left(seconds, '0', 1)
        
            if (this.total <= 0) {
                clearInterval(this.interval)
                clearInterval(this.intervalAnim)
                this.isCounting = false
                this.$emit("doneTimer")
            }

            this.total -= 1
        }
    }
}
</script>
