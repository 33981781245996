// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import i18nmessages from '@/plugins/i18nmessages'
import axios from 'axios'
import store from './store'
import globalMixin from './mixins/mix'
import FlagIcon from 'vue-flag-icon'
import CountryFlag from 'vue-country-flag'
import APIService from '@/services/APIService'
import 'vuetify/dist/vuetify.min.css'
import ImageUploader from 'vuetify-image-upload-resize'
import VueMask from 'v-mask'

// axios.defaults.baseURL = 'http://cloud.raduangelescu.ovh/api'
// axios.defaults.baseURL = 'http://localhost:8000/api'
// axios.defaults.baseURL = 'https://localhost:44324/api'
// axios.defaults.baseURL = 'https://localhost:5001/api'
// axios.defaults.baseURL = 'https://quizz.raduangelescu.ovh/api'
// Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_BASE_URI
Vue.mixin(globalMixin)
Vue.use(FlagIcon)
Vue.use(CountryFlag)
Vue.use(ImageUploader)
Vue.use(VueMask);

APIService.getLocalizations().then((data) => {
  data.forEach(item => {
    i18nmessages.en[item.lookup] = item.en
    i18nmessages.hu[item.lookup] = item.hu
    i18nmessages.ro[item.lookup] = item.ro
  })

  /* eslint-disable no-new */
  new Vue({
    vuetify,
    el: '#app',
    store,
    beforeCreate() {this.$store.commit('INITIALIZE_STORE')},
    router,
    components: { App },
    template: '<App/>',
    lang: {
      t: (key, ...params) => i18n.t(key, params)
    },
    i18n,
    render: h => h(App)
  })
})
