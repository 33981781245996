import Vue from 'vue'
import Router from 'vue-router'
import HomeLayout from '@/views/HomeLayout'
import ContestListLayout from '@/views/ContestListLayout'
import AdminContestListLayout from '@/views/AdminContestListLayout'
import CheckFormLayout from '@/views/CheckFormLayout'
import ProfileSettingsLayout from '@/views/ProfileSettingsLayout'
import QuizzLayout from '@/views/QuizzLayout'
import StringsLayout from '@/views/StringsLayout'
import SpeciesLayout from '@/views/SpeciesLayout'
import SpeciesDetailLayout from '@/views/SpeciesDetailLayout'
import FamilyLayout from '@/views/FamilyLayout'
import CladaLayout from '@/views/CladaLayout'
import SchoolsLayout from '@/views/SchoolsLayout'
import ResultsLayout from '@/views/ResultsLayout'
import UserListLayout from '@/views/UserListLayout'
import ListCheckLists from '@/views/ListCheckLists'
import ExerciseLayout from '@/views/ExerciseLayout'
import UserHistoryLayout from '@/views/UserHistoryLayout'
import AudienceGroupLayout from '@/views/AudienceGroupLayout'
 
import store from '../store'

const ifAuthenticated = (to, from, next) => {
  if (store.getters.IsLoggedIn) {
    next()
    return
  }
  next('/')
}

const ifIsSchool = (to, from, next) => {
  if (store.getters.IsUserSchoolAccount) {
    next()
    return
  }
  next('/')
}

const ifIsAdmin = (to, from, next) => {
  if (store.getters.IsUserAdmin) {
    next()
    return
  }
  next('/')
}


Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path:'/passwordreset/:id',
      name: 'HomeLayoutReset',
      component: HomeLayout
    },
    {
      path: '/exercise',
      name: 'ExerciseLayout',
      component: ExerciseLayout
    },
    {
      path:'/activate/:id',
      name: 'HomeLayoutActivate',
      component: HomeLayout
    },
    {
      path: '/',
      name: 'HomeLayout',
      component: HomeLayout
    },
    {
      path: '/clada',
      name: 'CladaLayout',
      component: CladaLayout,
      beforeEnter: ifIsAdmin
    },
    {
      path: '/schools',
      name: 'SchoolsLayout',
      component: SchoolsLayout,
      beforeEnter: ifIsAdmin
    },
    {
      path: '/family',
      name: 'FamilyLayout',
      component: FamilyLayout,
      beforeEnter: ifIsAdmin
    },
    {
      path: '/audiencegroup',
      name: 'AudienceGroupLayout',
      component: AudienceGroupLayout,
      beforeEnter: ifIsAdmin
    },
    {
      path: '/contest',
      name: 'ContestListLayout',
      component: ContestListLayout,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/contest_edit/:id/results',
      name: 'ResultsLayout',
      component: ResultsLayout,
      beforeEnter: ifIsAdmin
    },
    {
      path: '/contest_edit',
      name: 'AdminContestListLayout',
      component: AdminContestListLayout,
      beforeEnter: ifIsAdmin
    },
    {
      path: '/strings',
      name: 'StringsLayout',
      component: StringsLayout,
      beforeEnter: ifIsAdmin
    },
    {
      path: '/quizz/:id',
      name: 'QuizzLayout',
      component: QuizzLayout,
      props: true,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/species/:id',
      name: 'SpeciesDetailLayout',
      component: SpeciesDetailLayout,
      props: true,
      beforeEnter: ifIsAdmin
    },
    {
      path: '/species',
      name: 'SpeciesLayout',
      component: SpeciesLayout,
      beforeEnter: ifIsAdmin
    },
    {
      path: '/profile',
      name: 'ProfileSettingsLayout',
      component: ProfileSettingsLayout,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/users',
      name: 'UserListLayout',
      component: UserListLayout,
      beeforeEnter: ifIsAdmin
    },
    {
      path: '/userhistory/:id',
      name: 'UserHistoryLayout',
      component: UserHistoryLayout,
      beeforeEnter: ifIsAdmin
    },
    {
      path: '/checkform',
      name: 'CheckFormLayout',
      component: CheckFormLayout,
      beforeEnter: ifIsSchool
    },
    {
      path: '/checklists',
      name: 'ListCheckLists',
      component: ListCheckLists,
      beforeEnter: ifIsAdmin
    }
  ]
})
