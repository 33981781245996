<template>
    <v-container>
      <v-card>
        <v-card-title>
          {{capitalize($t('audiencegroups'))}}
          <v-spacer></v-spacer>
              <v-text-field
                  v-model="searchAudienceGroups"
                  append-icon="mdi-text-search"
                  :label="capitalize($t('search'))"
                  single-line
                  hide-details
                ></v-text-field>

          </v-card-title>
          <v-container>
              <v-data-table
                :headers="headersAudienceGroups"
                :items="audienceGroups"
                :items-per-page="5"
                :search="searchAudienceGroups"
                :loading="isLoading"
                class="elevation-1"
              >
               <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px" >
                        <template v-slot:activator="{ on }">
                            <v-btn iclass="mx-2" fab small color="primary" v-on="on">
                              <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ capitalize($t('create new audience group')) }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                          <v-text-field :label="capitalize($t('audience group name'))" v-model="newAudienceGroupName"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-combobox chips closable-chips multiple label="AudienceGroupItems" v-model="newSelectedGroupItems"
                                        :items="selectableAudienceGroupItems"
                                      ></v-combobox>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">{{capitalize($t('cancel'))}}</v-btn>
                                <v-btn color="blue darken-1" text @click="createNewAudienceGroup">{{capitalize($t('save'))}}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
              </template>
                <template v-slot:[`item.name`]="props">
                    <v-edit-dialog
                    :return-value.sync="props.item.name"
                    large
                    @save="save(props.item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                    > {{ props.item.name }}
                      <template v-slot:input>
                        <div class="mt-4 title">{{capitalize($t('update name'))}}</div>
                        <v-text-field
                          v-model="props.item.name"
                          :label="$t('edit')"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:[`item.audienceGroupItems`]="props">
                  <v-edit-dialog
                    :return-value.sync="props.item.audienceGroupItems"
                    large
                    @save="save(props.item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                    >  <v-combobox chips closable-chips multiple label="AudienceGroupItems" v-model="props.item.audienceGroupItems"
                                        :items="selectableAudienceGroupItems"
                                        disabled
                        ></v-combobox>
                      <template v-slot:input>
                        <div class="mt-4 title">{{capitalize($t('update name'))}}</div>
                        <v-combobox chips closable-chips multiple label="AudienceGroupItems" v-model="props.item.audienceGroupItems"
                                        :items="selectableAudienceGroupItems"
                        ></v-combobox>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-icon
                        small
                        color="red"
                        @click="deleteItem(item)"
                        align-end
                    >
                        mdi-delete
                    </v-icon>
                </template>
              </v-data-table>

              <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                {{ snackText }}
                <v-btn text @click="snack = false">{{capitalize($t('close'))}}</v-btn>
              </v-snackbar>
            </v-container>
   </v-card>
   <v-card>
        <v-card-title>
          {{capitalize($t('audiencegroupitems'))}}
          <v-spacer></v-spacer>
              <v-text-field
                  v-model="searchAudienceGroupItems"
                  append-icon="mdi-text-search"
                  :label="capitalize($t('search'))"
                  single-line
                  hide-details
                ></v-text-field>

          </v-card-title>
          <v-container>
              <v-data-table
                :headers="headersAudienceGroupItems"
                :items="audienceGroupItems"
                :items-per-page="5"
                :search="searchAudienceGroupItems"
                :loading="isLoadingItems"
                class="elevation-1"
              >
               <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialogItems" max-width="500px" >
                        <template v-slot:activator="{ on }">
                            <v-btn iclass="mx-2" fab small color="primary" v-on="on">
                              <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ capitalize($t('create new audience group item')) }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                          <v-text-field :label="capitalize($t('audience group item name'))" v-model="newAudienceGroupItemName"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">{{capitalize($t('cancel'))}}</v-btn>
                                <v-btn color="blue darken-1" text @click="createNewAudienceGroupItem">{{capitalize($t('save'))}}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
              </template>
                <template v-slot:[`item.name`]="props">
                    <v-edit-dialog
                    large
                    :return-value.sync="props.item.name"
                    @save="saveGroupItem(props.item)"
                    @cancel="cancel"
                    @open="open"
                    @close="closeGroupItem"
                    > {{ props.item.name }}
                      <template v-slot:input>
                        <div class="mt-4 title">{{capitalize($t('update name'))}}</div>
                        <v-text-field
                          v-model="props.item.name"
                          :label="$t('edit')"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <v-icon
                        small
                        color="red"
                        @click="deleteAudienceGroupItem(item)"
                        align-end
                    >
                        mdi-delete
                    </v-icon>
                </template>
              </v-data-table>

              <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                {{ snackText }}
                <v-btn text @click="snack = false">{{capitalize($t('close'))}}</v-btn>
              </v-snackbar>
            </v-container>
   </v-card>

    </v-container>
</template>
<script>
import APIService from '@/services/APIService'

export default {
  mounted: function () {
    this.refreshContent()
  },
  methods: {
    close () {
      this.dialog = false
    },
    closeGroupItem() {
      this.dialogItems = false
    },
    refreshContent() {
      this.isLoading = true
      this.isLoadingItems = true
      APIService.getAudienceGroupItems(this.UserAuthToken).then((data) => {
        this.audienceGroupItems = data;
        this.selectableAudienceGroupItems = data.map(x => {return {text:x.name, value:x.id}})
        this.isLoadingItems = false
        APIService.getAudienceGroups(this.UserAuthToken).then((data) => {
          var that = this;
          this.audienceGroups = data.map(x => {
            var audienceGroupItems = []
            if (x.audienceGroupItems)
            {
              audienceGroupItems  = x.audienceGroupItems.map(y => {
                var found = that.selectableAudienceGroupItems.find((e) => e.value == y);
                return {
                  text: found.text,
                  value: found.value
                }
              }) 
            }
            return {
              id: x.id,
              name : x.name,
              audienceGroupItems : audienceGroupItems 
            }
          })
          this.isLoading = false
        })
      })
    },
    createNewAudienceGroupItem: function () {
      var data = {name: this.newAudienceGroupItemName};
      APIService.createAudienceGroupItem(this.UserAuthToken, data).then(respons => {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.capitalize(this.$t('data created'))
        this.dialogItems = false
        this.refreshContent()
      })
    },
    createNewAudienceGroup: function () {
      var data = {name: this.newAudienceGroupName, audienceGroupItems: this.newSelectedGroupItems.map(x => x.value)}
      APIService.createAudienceGroup(this.UserAuthToken, data).then(respons => {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.capitalize(this.$t('data created'))
        this.dialog = false
        this.refreshContent()
      })
    },
    deleteAudienceGroupItem(item) {
      APIService.deleteAudienceGroupItem(this.UserAuthToken, item).then(response => {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.capitalize(this.$t('data deleted'))
        this.refreshContent()
      })
    },
    deleteItem (item) {
      APIService.deleteAudienceGroup(this.UserAuthToken, item).then(response => {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.capitalize(this.$t('data deleted'))
        this.refreshContent()
      })
      this.snack = true
      this.snackColor = 'warning'
      this.snackText = this.capitalize(this.$t('deleting'))
    },
    saveGroupItem(item) {
      APIService.updateAudienceGroupItem(this.UserAuthToken, item).then(response => {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.capitalize(this.$t('data saved'))
        this.refreshContent()
      })
      this.snack = true
      this.snackColor = 'warning'
      this.snackText = this.capitalize(this.$t('saving'))
    },
    save (item) {
      var audienceGroupItems = []
      if (item.audienceGroupItems)
        audienceGroupItems = item.audienceGroupItems.map(x => x.value)
      var payload = {
        id: item.id,
        name: item.name,
        audienceGroupItems: audienceGroupItems
      }
      APIService.updateAudienceGroup(this.UserAuthToken, payload).then(response => {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.capitalize(this.$t('data saved'))
      })
      this.snack = true
      this.snackColor = 'warning'
      this.snackText = this.capitalize(this.$t('saving'))
    },
    cancel () {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = this.capitalize(this.$t('canceled'))
    },
    open () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = this.capitalize(this.$t('dialog opened'))
    }
  },
  computed: {
    headersAudienceGroups: function () {
      return [
        {text: this.$t('name'), value: 'name'},
        {text: this.$t('audience groups'), value: 'audienceGroupItems'},
        {text: this.$t('actions'), value: 'action', sortable: false, align: 'right'}]
    },
    headersAudienceGroupItems: function () {
      return [
        {text: this.$t('name'), value: 'name'},
        {text: this.$t('actions'), value: 'action', sortable: false, align: 'right'}]
    }
  },
  data: () => ({
    isLoading: false,
    isLoadingItems: false,
    audienceGroups: [],
    snack: false,
    snackColor: '',
    snackText: '',
    searchAudienceGroups: '',
    searchAudienceGroupItems: '',
    dialog: false,
    dialogItems: false,
    audienceGroupItems: [],
    selectableAudienceGroupItems: [],
    newSelectedGroupItems: [],
    newAudienceGroupName: 'newAudienceGroup',
    newAudienceGroupItemName: 'newAudienceGroupItemName'
  })
}
</script>
