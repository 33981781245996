<template>
 <v-card :loading="isLoading">
    <v-card-text>
        <v-form ref="formTeam" 
        v-model="valid"
        :disabled="multiButton.state == 'edit' || isLoading">
            <v-container >
                 <v-row>
                    <v-col cols=9>
                    </v-col>
                    <v-col>
                    <v-btn :color="multiButton.states[multiButton.state].color"
                    :disabled="!canPress()"
                    @click="onPress()">
                        {{capitalize($t(multiButton.states[multiButton.state].text))}}
                        <v-icon color="white">{{multiButton.states[multiButton.state].icon}}</v-icon>
                    </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols=10>
                    <v-text-field 
                    v-model="coordonator"
                    :label="capitalize($t('cadru didactic coordonator'))"
                    :rules="[ruleRequired]"
                    />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols=4>
                        <v-text-field 
                        type="number"
                        v-model="numarElevi"
                        min=1
                        :rules="[ruleNumarElevi]"
                        :label="capitalize($t('numar prescolari/elevi'))"/>
                    </v-col>
                    <v-col cols=6>
                        <v-text-field 
                        type="number"
                        v-model="numarEleviTotal"
                        min=1
                        :label="capitalize($t('total prescolari/elevi inscrisi in institutie'))"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols=4>
                        {{capitalize($t('cadre didactice participante'))}}
                    </v-col>
                    <v-col>
                    <list-text-items
                    :valid="multiButton.state != 'edit'" 
                    :parent="cadreParticipant"
                    :fields="cadreParticipant.fields"
                    v-on:modifyList="listChange"
                    listName="list"/>
                    </v-col>
                </v-row>
               
            </v-container>
        </v-form>
    </v-card-text>
</v-card>
</template>
<script>
import APIService from '@/services/APIService'
import ListTextItems from '@/components/ListTextItems'
export default {
    props:['model'],
    watch: {
        model: function (newVal) {
            if(newVal == null){
                this.isLoading = false
                return
            }
            this.cadreParticipant.list =  newVal.cadreParticipant
            this.numarElevi = newVal.numarElevi
            this.numarEleviTotal = newVal.numarEleviTotal
            this.coordonator = newVal.coordonator
            this.isLoading = false
        }
    },
    components: {
        'list-text-items':ListTextItems
    },
    mounted: function () {
        this.refreshData()
        this.buttonText="edit"
    },

    methods: {
        onPress() {
            if(this.multiButton.state == 'edit') {
                this.multiButton.state = 'save'
            }
            else {
                var data ={
                    contentType: 'GeneralInfo',
                    cadreParticipant: this.cadreParticipant.list,
                    numarElevi: this.numarElevi,
                    numarEleviTotal: this.numarEleviTotal,
                    coordonator: this.coordonator
                }
                this.$emit("saveContent", data)
                this.isLoading = true
                this.multiButton.state = 'edit'
            }
        },
        canPress(){
            if(this.multiButton.state == 'edit') {
                return true
            }
            if(this.multiButton.state == 'save') {    
                return this.valid
            }
            return true
        },  
        listChange(list, parent, listName) {
            parent[listName] = list
        },
        refreshData(){

        },
        ruleNumarElevi(value) {
            if(value > this.numarEleviTotal) {
                return this.capitalize(this.$t("to many students"))
            }
            return true
        },
        ruleRequired(value){
            if(value){
                return true
            }
            return this.capitalize(this.$t("required"))
        },
  },
  computed: {
  },
  data: () => ({
    multiButton:{
        states :{
            'edit':{
                color: 'warning',
                text: 'edit',
                icon: 'mdi-pencil'
            },
            'save':{
                color: 'success',
                text:'save',
                icon: 'mdi-floppy'
            }
        },
        state: 'edit'
    },
    isLoading: false,
    coordonator:'',
    cadreParticipant:{
        valid:true,
        fields:['name'],
        list:[]
    },
    numarElevi:1,
    numarEleviTotal:1,
    valid: false
  })
}
</script>