<template>
    <v-container>
      <v-card>
        <v-card-title>
          {{capitalize($t('schools'))}}
          <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-text-search"
                  :label="capitalize($t('search'))"
                  single-line
                  hide-details
                ></v-text-field>
          </v-card-title>
          <v-container>
              <v-data-table
                :headers="headers"
                :items="schools"
                :items-per-page="100"
                :search="search"
                :loading="isLoading"
                class="elevation-1"
              >
              <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px" >
                        <template v-slot:activator="{ on }">
                            <v-btn iclass="mx-2" fab small color="primary" v-on="on">
                              <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ capitalize($t('create new school')) }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-form ref="formSchools" v-model="newSchool.valid">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                          <v-text-field 
                                          :label="capitalize($t('school name'))" 
                                          v-model="newSchool.name"
                                          :rules="[required]"
                                          />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col>
                                        <v-select
                                          v-on:change="changeRegion"
                                          :rules="[requiredSelect]"
                                          prepend-icon="mdi-earth-box"
                                          v-model="newSchool.region"
                                          name="region"
                                          :label="capitalize($t('region'))"
                                          :items="newSchool.regions"
                                          :loading = "newSchool.regionsLoading"
                                          type="text"
                                      ></v-select>
                                     </v-col>
                                  </v-row>           
                                  <v-row v-show="newSchool.region !=-1">
                                    <v-col>
                                      <v-select
                                        v-on:change="changeCounty"
                                        prepend-icon="mdi-earth-box"
                                        v-model="newSchool.county"
                                        name="judet"
                                        :label="capitalize($t('county'))"
                                        :items="newSchool.counties"
                                        :loading = "newSchool.countiesLoading"
                                        :rules="[requiredSelect]"
                                        type="text"
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                  <v-row v-show="newSchool.region !=-1 && newSchool.county !=-1">
                                    <v-col>
                                      <v-select
                                        prepend-icon="mdi-home-city"
                                        v-model="newSchool.city "
                                        name="localitate"
                                        :label="capitalize($t('city'))"
                                        :items="newSchool.cities"
                                        :rules="[requiredSelect]"
                                        :loading = "newSchool.citiesLoading"
                                        type="text"
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                 
                                </v-container>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">{{capitalize($t('cancel'))}}</v-btn>
                                <v-btn color="success" :disabled="!newSchool.valid"  @click="createNewSchool">{{capitalize($t('save'))}}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.name`]="props">
                    <v-edit-dialog
                    :return-value.sync="props.item.name"
                    @save="save(props.item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                    > {{ props.item.name }}
                      <template v-slot:input>
                        <div class="mt-4 title">{{$t('update name')}}</div>
                        <v-text-field
                          v-model="props.item.name"
                          :label="$t('edit')"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                    <v-icon
                        small
                        color="red"
                        @click="deleteItem(item)"
                        align-end
                    >
                        mdi-delete
                    </v-icon>
                </template>
         
              </v-data-table>

             <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false">{{capitalize($t("close"))}}</v-btn>
    </v-snackbar>
            </v-container>
   </v-card>

    </v-container>
</template>
<script>
import APIService from '@/services/APIService'

export default {
  mounted: function () {
    this.refreshContent()
  },
  methods: {
     changeRegion(value) {
      this.newSchool.county = -1
      this.newSchool.schoolId = -1
      this.newSchool.city = -1
      this.getCounties(value)
    },
    changeCounty(value) {
      this.newSchool.schoolId = -1
      this.newSchool.city = -1
      this.getCities(value)
    },
    getRegions() {
      this.regionsLoading = true
      this.regions = []
      return APIService.getRegions().then(data => {
        this.newSchool.regions = data.map(el =>  {return { text:el.name, value:el.id}})
        this.newSchool.regionsLoading = false
      })
    },
    getCounties (region) {
      this.newSchool.countiesLoading = true
      this.newSchool.counties = []
      return APIService.getCounties(region).then(data => {
        this.newSchool.counties = data.map(el =>  {return { text:el.name, value:el.id}})
        this.newSchool.countiesLoading = false
      })
    },
    getCities (county) {
      this.newSchool.citiesLoading = true
      this.newSchool.cities = []
      return APIService.getCities(county).then(data => {
        this.newSchool.cities = data.map(el =>  {return { text:el.name, value:el.id}})
        this.newSchool.citiesLoading = false
      })
    },
    getSchools (city) {
      this.schoolsLoading = true
      this.schools = []
      return APIService.getSchools(city).then(data => {
        this.newSchool.schools = data.map(el =>  {return { text:el.name, value:el.id}})
        this.newSchool.schoolsLoading = false
      })
    },
    required(value) {
      if(value)
        return true;
      return this.$t("required")
    },
    requiredSelect (value) {
      if(value == -1) {
       return this.$t("required")
      }
      return true
    },
    refreshContent() {
      this.isLoading = true
      var that = this
      this.getRegions()
      APIService.getAllSchools(that.UserAuthToken).then((data) => {
                that.schools = data
                that.isLoading = false
              })
     
     
    },
    close () {
      this.dialog = false
      this.resetNew()
    },
    createNewSchool: function () {
      this.$refs.formSchools.validate()
      var that = this
      APIService.createSchool(this.UserAuthToken, 
      {
        name: this.newSchool.name,
        cityId: this.newSchool.city,
        stateId: this.newSchool.county}).then((response) => {
      }).then(data =>{
        that.snack = true
        that.snackColor = 'success'
        that.snackText = this.capitalize(this.$t('data created'))
        that.dialog = false
        that.refreshContent()
        that.resetNew()
      })
      this.snack = true
      this.snackColor = 'warning'
      this.snackText = this.capitalize(this.$t('creating school'))
      
    },
    deleteItem (item) {
      APIService.deleteSchool(this.UserAuthToken, item).then((/*response*/) => {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.capitalize(this.$t('data deleted'))
        this.refreshContent()
      })
      this.snack = true
      this.snackColor = 'warning'
      this.snackText = this.capitalize(this.$t('deleting'))
    },
    save (item) {
      this.resetNew()
      APIService.updateSchool(this.UserAuthToken, item).then((/*response*/) => {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = this.capitalize(this.$t('data saved'))
      })
      this.snack = true
      this.snackColor = 'warning'
      this.snackText = this.capitalize(this.$t('saving'))
    },
    cancel () {
      this.resetNew()
      this.snack = true
      this.snackColor = 'error'
      this.snackText = this.capitalize(this.$t('canceled'))
    },
    resetNew() {
      this.newSchool.name = ''
      this.newSchool.schools= []
      this.newSchool.counties= []
      this.newSchool.cities= []
      this.newSchool.citiesLoading = false
      this.newSchool.countiesLoading = false
      this.newSchool.schoolsLoading = false
      this.newSchool.region = -1
      this.newSchool.city = -1
      this.newSchool.county = -1
      this.newSchool.school = -1
    },
    open () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = this.capitalize(this.$t('dialog opened'))
    },
  },
  computed: {
    headers: function () {
      return [
        {text: this.capitalize(this.$t('name')), value: 'name'},
        {text: this.capitalize(this.$t('city')), value: 'city'},
        {text: this.capitalize(this.$t('county')), value: 'county'},
        {text: this.capitalize(this.$t('region')), value: 'region'},
        {text: this.capitalize(this.$t('actions')), value: 'action', sortable: false, align: 'right'}]
    }
  },
  data: () => ({
    newSchool:{
      name: '',
      schools: [],
      counties: [],
      cities: [],
      citiesLoading: false,
      countiesLoading: false,
      schoolsLoading: false,
      region: -1,
      city: -1,
      county: -1,
      school: -1,
      valid: false
    },
    isLoading: false,
    dialog: false,
    newSchoolName: 'school',
    newCity: -1,
    newState: -1,
    schools: [],
    snack: false,
    snackColor: '',
    snackText: '',
    search: ''
  })
}
</script>
