<template>
    <v-container>
        <v-row>
            <v-col>
            <v-date-picker
                full-width
                v-model="dates"
                range
                show-current
                show-week
            ></v-date-picker>
            </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="success" @click="getLog">{{$t('refresh')}}</v-btn>
        </v-col>
        </v-row>
        <v-row>
       
        <v-col>
            <v-select
              :items="environments"
              label="Environment"
              v-model="currentEnvironment"
              v-on:change="changeContestSelection"
            ></v-select>

        </v-col>
        <v-col>
          <v-select :items="contestsDisplay" label="Contest" v-model="currentContest" item-text="name"
          item-value="idx" v-on:change="changeContestSelection"></v-select>
        </v-col>
        </v-row>
        <v-row v-if ="currentContest != 0">
          <v-col>
            {{ $t("User Number Of tries/maximum number of tries")}}
          </v-col>
          <v-col>
            {{ contestsDisplay[currentContest].currentNumberOfTries}}/ {{ contestsDisplay[currentContest].maxNumberOfTries }}
          </v-col>
          <v-col>
            <v-btn color="success" @click="addTry">{{$t('add try')}}</v-btn>
          </v-col>
        </v-row>
        <v-row>
            <v-tabs v-model="tab" bg-color="primary">
              <v-tab value= "GradedResults"> {{ $t('Graded results') }} </v-tab>
              <v-tab value = "UserLogs"> {{ $t('User Logs') }} </v-tab>
            </v-tabs>
        </v-row>
        <v-row v-if="tab == 0">
            <v-col>
            <contest-results-details v-if="contestData != null" :contestData="contestData" />
            <p v-else>{{ $t('User did has no results at this contest') }}</p>
          </v-col>
        </v-row>
        <v-row v-if="tab ==1">
            <v-col>
            <v-data-table
            :headers="headers"
            :items="logs"
            :items-per-page="5"
            :loading = "isLoading"
            dense
            class="elevation-1"
          ></v-data-table>
          </v-col>
        </v-row>
      
    </v-container>
</template>

<script>
import APIService from '@/services/APIService'
import ContestResultsDetails from '@/components/ContestResultsDetails'

export default {
  mounted: function () {
    this.isLoading = true;
    this.currentUserId = this.$route.params.id;
    this.currentContest = 0;     
    this.getData();
  },
  components: {
    'contest-results-details': ContestResultsDetails,
  },
  methods: {
    changeContestSelection() {
      this.getLog();
      if (this.currentContest == 0)
      {
        this.contestData = null
      }
      else
      {
        this.contestData = null
        var that = this
        APIService.getContestResultsForUser(this.UserAuthToken, this.currentUserId, this.contestsDisplay[this.currentContest].id).then( contest => {
          if (!contest)
            return;
          contest.questionInstances.map(q => {
                if (q.type === 0) {
                  var choicesArray = JSON.parse(q.choices)
                  choicesArray.push(this.capitalize(this.$t("don't know")))
                  q.choices = choicesArray
                  q.infoLink = ""
                }
              })
              that.contestData = contest
        })
      }
    },
    addTry() {
      var that = this;
      APIService.giveTry(this.UserAuthToken, this.contestsDisplay[this.currentContest].id, this.currentUserId).then((res) => {
        that.getData();
      })
    },
    getLog() {
      this.isLoading = true;
      var that = this;
      var today = new Date();
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      
      var real_dates = [yesterday, today];
      if (this.dates.length == 1) {
          var second = new Date(this.dates[0]); 
          var first = new Date();
        
          first.setDate(second.getDate() - 1);
          real_dates = [first, second]
          console.log("dates are bad 1")
       }
      
       if (this.dates[0] > this.dates[1]) {
        var sw = this.dates[0];
        real_dates[0] = new Date(this.dates[1]);
        real_dates[1] = new Date(sw);
        console.log("dates are bad 2")
      }
      else {
        real_dates[0] = new Date(this.dates[0]);
        real_dates[1] = new Date(this.dates[1]);
      }
      
      
      var queryData = {query:`{Userid="${this.currentUserId}", Environment="${this.currentEnvironment}"}`, start:real_dates[0].toISOString(), end:real_dates[1].toISOString()};
      if (this.currentContest != 0) {
        queryData.query = `{Userid="${this.currentUserId}", Environment="${this.currentEnvironment}", ContestId="${ this.contestsDisplay[this.currentContest].id}"}`;
      }
      APIService.getUserLogs(queryData).then((res) => {
        var data = res['data'];
        that.logs.splice(0);
        if (!('status' in data))
        {
          that.isLoading = false;
          return;
        }
        that.has_data = data['status'];
        if (!('data' in data))
        {
          that.isLoading = false;
          return;
        }
        if (!('result' in data['data']))
        {
          that.isLoading = false;
          return;
        }
        var results = data['data']['result'];
        
        for (const result of results) {
          var values = result['values'];
          for(const value of values) {
              that.logs.push({time: new Date(Number(value[0])/(1000 * 1000)).toISOString(), action: value[1]});
          }
        }
        that.isLoading = false
      })
    },
   getData() {
    var that = this;
    APIService.getContestsForUser (this.UserAuthToken, this.currentUserId).then(contests => {
        that.contestsDisplay = [{name:"All Logs", id: -1, idx: 0, currentNumberOfTries: 0, maxNumberOfTries: 0}]
        contests.forEach(contest => {
          that.contestsDisplay.push({
            name: contest.name,
            id: contest.id,
            idx: that.contestsDisplay.length,
            currentNumberOfTries: contest.currentNumberOfTries,
            maxNumberOfTries: contest.maxNumberOfTries,
          })
        })
        that.getLog();
      })
  }
  },
  computed: {
    dateRangeText () {
        return this.dates.join(' ~ ')
      },
  },
  data: () => ({
    isLoading:false,
    logs: [],
    dates: [new Date().toISOString(), new Date().toISOString()],
    currentEnvironment: 'Production',
    environments: ['Development', 'Production'],
    headers: [{text: 'time', value: 'time'}, {text: 'action', value: 'action'}],
    currentUserId:-1,
    userLogs: [],
    search: '',
    contestsDisplay: [],
    currentContest: 0,
    contestData: null,
    tab :0
  })
}
</script>