<template>
    <v-container>
      <v-card>
        <v-card-title>
          {{capitalize($t('species'))}}
          <v-spacer></v-spacer>
          <v-text-field
                v-model="search"
                append-icon="mdi-search"
                :label="capitalize($t('search'))"
                single-line
                hide-details
              ></v-text-field>
          </v-card-title>
          <v-container>
              <v-data-table
                :headers="headers"
                :items="species"
                :items-per-page="5"
                :search="search"
                :loading="isLoading"
                class="elevation-1"
              >
             <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px" >
                        <template v-slot:activator="{ on }">
                            <v-btn iclass="mx-2" fab small color="primary" v-on="on">
                              <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ capitalize($t('create new species')) }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                        <v-text-field :label="capitalize($t('english name'))" v-model="newEntry.englishName"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                                <v-text-field :label="capitalize($t('romanian name'))" v-model="newEntry.romanianName"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field :label="capitalize($t('hungarian name'))" v-model="newEntry.hungarianName"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field :label="capitalize($t('scientific name'))" v-model="newEntry.scientificName"></v-text-field>
                                        </v-col>
                                        <v-col>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">{{capitalize($t('cancel'))}}</v-btn>
                                <v-btn color="blue darken-1" text @click="createNewSpecies">{{capitalize($t('save'))}}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>

               </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-icon
                        small
                        color="error"
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>

                    <v-icon
                        small
                        color="warning"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>

                </template>
              </v-data-table>

             <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false">{{$t('close')}}</v-btn>
    </v-snackbar>
  </v-container>
   </v-card>

    </v-container>
</template>
<script>
import APIService from '@/services/APIService'

export default {
  mounted: function () {
    var that = this
    this.isLoading = true
    APIService.getSpecies(this.UserAuthToken).then((data) => {
      that.species = data
      that.isLoading = false
    })
  },
  methods: {
    close () {
      this.dialog = false
    },
    editItem: function (item) {
      this.$router.push({ path: 'species/' + item.id, params: { id: item.id } }).catch(err => {})
    },
    deleteItem: function (item) {
      this.snack = true
      this.snackText = this.$t('deleting')
      this.snackColor = 'warning'
      APIService.deleteSpecies(this.UserAuthToken, item).then(response => {
        APIService.getSpecies(this.UserAuthToken).then((data) => {
          this.species = data
          this.snack = true
          this.snackText = this.$t('done delete')
          this.snackColor = 'success'
        })
      })
    },
    createNewSpecies: function () {
      this.snack = true
      this.snackText = this.$t('create')
      this.snackColor = 'warning'
      APIService.createSpecies(this.UserAuthToken, this.newEntry).then(response => {
        APIService.getSpecies(this.UserAuthToken).then((data) => {
          this.species = data
          this.snack = true
          this.snackText = this.$t('created')
          this.snackColor = 'success'
          this.dialog = false
        })
      })
    }
  },
  computed: {
    headers: function () {
      return [
        {text: this.$t('english_name'), value: 'englishName'},
        {text: this.$t('romanian_name'), value: 'romanianName'},
        {text: this.$t('hungarian_name'), value: 'hungarianName'},
        {text: this.$t('scientific_name'), value: 'scientificName'},
        { text: 'Actions', value: 'action', sortable: false }]
    }
  },
  data: () => ({
    isLoading: true,
    strings: [],
    snack: false,
    snackColor: '',
    snackText: '',
    search: '',
    species: [],
    dialog: false,
    newEntry: {
      englishName: '',
      romanianName: '',
      hungarianName: '',
      scientificName: '',
      familyId: 1,
      cladaId: 1}
  })
}
</script>
