<template>
<v-container fluid>
  <v-row align="center"
      justify="center">
<v-card :max-width="getMaxWidth()" >
    <v-card-title  class="justify-center" >
      <v-container grid class="justify-center">
        <v-row  class="justify-center">
      <v-avatar v-if="mode=='profile'" :size="getAvatarSize()" >
            <v-icon :size="getAvatarSize()" :color="avatarColor">
              mdi-account-tie
              </v-icon>
              
        </v-avatar>
       </v-row>
       <v-row>
         <v-col>
        <v-alert :value="shouldDisplayAlert()" 
        readonly 
        type="error">
          {{capitalize($t('account is not activated'))}}
        </v-alert>
         </v-col>
        </v-row>
        <v-row justify="center" >
          <v-btn color="success" v-show="!shouldDisplayAlert() && mode=='profile' && this.localUserId == -1" @click="resetPassword()">{{capitalize($t('reset password'))}}</v-btn>
        </v-row>
        <v-row justify="center" >
          <v-btn color="success" v-show="shouldDisplayAlert()" @click="activateAccount()">{{capitalize($t('activate'))}}</v-btn>
        </v-row>
       </v-container>
     </v-card-title>
      <v-card-text v-show="shouldDisplayForm()">
           <v-card flat :loading="isLoading">
              <v-card-text >
                <v-form
                :disabled = "isLoading"
                ref="form"
                v-model="valid"
                lazy-validation
                >
                  <v-container grid fluid>
                   
                      <v-row>
                        <v-col  v-if="mode=='register'">
                          <v-text-field
                            v-model="InfoForm.email"
                            prepend-icon="mdi-email"
                            maxlength=64
                            name="email"
                            autocomplete="email"
                            :label="capitalize($t('email'))"
                            counter
                            type="text"
                            v-on:change="onEmailChange"
                            :rules="[required, emailMatch, ruleEmailUniqueF]"
                          ></v-text-field>
                        </v-col>
                        <v-col v-else>
                          <v-text-field v-model="localUserId"
                            v-show="shouldDisplayForm()"
                            prepend-icon="mdi-identifier"
                            name="email"
                            type="text"
                            readonly disabled></v-text-field>
                          <v-text-field 
                            v-model="InfoForm.email"
                            v-show="shouldDisplayForm()"
                            prepend-icon="mdi-email"
                            name="email"
                            autocomplete="email"
                            :label="capitalize($t('email'))"
                            type="text"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row xs12 sm12 v-if="mode =='register'">
                        <v-col>
                          <v-text-field
                            v-model="InfoForm.password"
                            autocomplete="password"
                            v-show="shouldDisplayForm()"
                            id="reg_password"
                            prepend-icon="mdi-lock"
                            name="password"
                            :rules="[required, min]"
                            :type="InfoForm.showTextPassword ? 'text' : 'password'"
                            :label="capitalize($t('password'))"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="InfoForm.surname"
                            v-show="shouldDisplayForm()"
                            name="surname"
                            autocomplete="given-name"
                            maxlength=64
                            :label="capitalize($t('real surname'))"
                            counter
                            :rules="[required, nameCounter, surnameRegexp, nameMin]"
                            type="text"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="InfoForm.name"
                            autocomplete="family-name"
                            v-show="shouldDisplayForm()"
                            maxlength=64
                            name="name"
                            :label="capitalize($t('real name'))"
                            counter
                            :rules="[required, nameCounter, nameRegexp, nameMin]"
                            type="text"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                        <v-switch 
                        v-show="shouldDisplayForm()"
                        v-model="InfoForm.isSchool" :label="capitalize($t('school'))"></v-switch>
                        </v-col>
                      </v-row>
                        <v-row xs4 sm12 v-show="InfoForm.isSchool">
                          <v-col>
                        <v-select
                          v-show="shouldDisplayForm()"
                          v-on:change="changeRegion"
                          :rules="[requiredSelect]"
                          prepend-icon="mdi-earth-box"
                          v-model="InfoForm.region"
                          name="region"
                          :label="capitalize($t('region'))"
                          :items="regions"
                          :loading = "regionsLoading"
                          type="text"
                        ></v-select>
                        </v-col>
                      </v-row>
                      <v-row xs4 sm12 v-show="InfoForm.isSchool && InfoForm.region !=-1">
                        <v-col>
                        <v-select
                        v-show="shouldDisplayForm()"
                          v-on:change="changeCounty"
                          prepend-icon="mdi-earth-box"
                          v-model="InfoForm.county"
                          name="judet"
                          :label="capitalize($t('county'))"
                          :items="counties"
                          :loading = "countiesLoading"
                          :rules="[requiredSelect]"
                          type="text"
                        ></v-select>
                        </v-col>
                      </v-row>
                      <v-row xs5 sm12 v-show="InfoForm.isSchool && InfoForm.region !=-1 && InfoForm.county !=-1">
                        <v-col>
                            <v-select
                            v-show="shouldDisplayForm()"
                            v-on:change="changeCity"
                              prepend-icon="mdi-home-city"
                              v-model="InfoForm.city "
                              name="localitate"
                              :label="capitalize($t('city'))"
                              :items="cities"
                              :rules="[requiredSelect]"
                              :loading = "citiesLoading"
                              type="text"
                            ></v-select>
                          </v-col>
                      </v-row>
                      <v-row xs12 sm12 v-show="InfoForm.isSchool && InfoForm.region !=-1 && InfoForm.county !=-1 &&  InfoForm.city !=-1">
                        <v-col>
                        <v-select
                          v-show="shouldDisplayForm()"
                            prepend-icon="mdi-chair-school"
                            v-model="InfoForm.schoolId"
                            name="school"
                            :label="capitalize($t('school'))"
                            :items="schools"
                            :rules="[requiredSelect]"
                            :loading = "schoolsLoading"
                            type="text"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row xs12 sm12 v-show="!InfoForm.isSchool">
                        <v-col>
                          <v-text-field
                          v-show="shouldDisplayForm()"
                            prepend-icon="mdi-briefcase"
                            v-model="InfoForm.afiliere"
                            name="afiliere"
                            :label="capitalize($t('affiliation'))"
                            type="text"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row xs12 sm12>
                        <v-col>
                         <div>
                         <v-menu
                         v-show="shouldDisplayForm()"
                            ref="Menu"
                            v-model="Menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                              v-show="shouldDisplayForm()"
                                v-model="InfoForm.dateOfBirth"
                                label="Birthday date"
                                prepend-icon="mdi-cake-layered"
                                readonly
                                :rules ="[required]"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                            v-show="shouldDisplayForm()"
                              v-model="InfoForm.dateOfBirth"
                              :active-picker.sync="ActivePicker"
                              :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                              min="1950-01-01"
                              max="2019-01-01"
                              @change="save"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                        </v-col>
                      </v-row>

                      <v-row xs12 sm12 v-show="InfoForm.isSchool"
                          >
                          <v-col>
                        <v-text-field
                        v-show="shouldDisplayForm()"
                          prepend-icon="mdi-phone"
                          v-model="InfoForm.telephone"
                          name="telephone"
                          v-mask="'############'"
                          :label="capitalize($t('telephone number'))"
                          type="text"
                          :rules="[ruleTelephone]"
                        ></v-text-field>
                          </v-col>
                      </v-row>
                       <v-row xs4 sm12 v-show="InfoForm.isSchool">
                         <v-col>
                        <v-select
                        v-show="shouldDisplayForm()"
                          prepend-icon="mdi-account-arrow-left-outline"
                          v-model="InfoForm.role"
                          :label="capitalize($t('role'))"
                          :items="possibleRoles"
                          type="text"
                        ></v-select>
                         </v-col>
                      </v-row>
                      <v-row xs12 sm12 v-show="InfoForm.isSchool && InfoForm.role == 1">
                        <v-col>
                        <v-text-field
                        v-show="shouldDisplayForm()"
                          prepend-icon="mdi-school"
                          v-model="InfoForm.actingTeacher"
                          maxlength=128
                          :label="capitalize($t('acting teacher'))"
                          counter
                          :rules="[required, fullNameCounter, nameRegexp, nameMin]"
                        ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row xs4 sm12 v-show="InfoForm.isSchool && InfoForm.role == 1">
                        <v-col>
                        <v-select
                        v-show="shouldDisplayForm()"
                          prepend-icon="mdi-book-open-page-variant-outline"
                          v-model="InfoForm.class"
                          :label="capitalize($t('class'))"
                          :items="possibleClasses"
                          :rules="[requiredSelect]"
                          type="text"
                        ></v-select>
                        </v-col>
                      </v-row>
                       <v-row>
                         <v-col>
                          <v-checkbox
                          v-show="shouldDisplayForm()"
                            v-model="InfoForm.newsletter"
                            :label="capitalize($t('i want to receive the newsletter'))"
                          ></v-checkbox>
                         </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-checkbox
                          v-show="shouldDisplayForm()"
                            v-model="InfoForm.agreement"
                            :label="capitalize($t('agreement'))"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row v-if="isEditByAdmin">
                        <v-col >
                          <v-checkbox
                            :disabled="!isEditByAdmin"
                            v-show="shouldDisplayForm()"
                            v-model="InfoForm.isAdmin"
                            :label="capitalize($t('is admin'))"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row v-if="isEditByAdmin">
                        <v-col>
                          <v-checkbox
                            :disabled="!isEditByAdmin"
                            v-show="shouldDisplayForm()"
                            v-model="InfoForm.isActivated"
                            :label="capitalize($t('is active'))"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row v-if="isEditByAdmin">
                        <v-col>
                          <v-checkbox
                            :disabled="!isEditByAdmin"
                            v-show="shouldDisplayForm()"
                            v-model="InfoForm.isSchoolAccount"
                            :label="capitalize($t('is school account'))"
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" v-if="mode=='register'" @click="register()" :disabled='isDisabledRegister || !valid || !IsValidSchool()'>{{capitalize($t('register'))}}</v-btn>
                <v-btn color="success" v-show="(mode=='profile' || mode == 'adminedit') && IsUserActive==true" @click="updateProfile()" :disabled="!valid || !IsValidSchool()">{{capitalize($t('update'))}}</v-btn>
              </v-card-actions>
            </v-card>
         </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    </v-row>
</v-container>
</template>
<script>
import APIService from '@/services/APIService'
import goTo from 'vuetify/lib/services/goto'

export default {
  props: ['mode', 'userid'],
  data: () => ({
    isEditByAdmin: false,
    localUserId: -1,
    isDisabledRegister: false,
    ruleEmailUnique: '',
    valid: false,
    isLoading: false,
    ActivePicker: null,
    regions: [],
    counties: [],
    cities: [],
    schools: [],
    schoolsLoading: false,
    regionsLoading: false,
    countiesLoading: false,
    citiesLoading: false,
    Menu: false,
    InfoForm: {
      password: '',
      isSchool: true,
      email: '',
      surname: '',
      name: '',
      region: -1,
      county: -1,
      city: -1,
      school: -1,
      afiliere: '',
      dateOfBirth: '',
      telephone: '',
      actingTeacher: '',
      role: 1,
      class: -1,
      newsletter: true,
      showTextPassword: false,
      agreement: true,
      isAdmin: false,
      isActivated: false,
      isSchoolAccount: false
    }
  }),
  computed: {
    avatarColor : function () {
      if(this.shouldDisplayAlert())
        return "red"
      return "gray"
    },
    possibleClasses: function () {
      return [
        {value: 1, text: this.$t('class 1')},
        {value: 2, text: this.$t('class 2')},
        {value: 3, text: this.$t('class 3')},
        {value: 4, text: this.$t('class 4')},
        {value: 5, text: this.$t('class 5')},
        {value: 6, text: this.$t('class 6')},
        {value: 7, text: this.$t('class 7')},
        {value: 8, text: this.$t('class 8')},
        {value: 9, text: this.$t('class 9')},
        {value: 10, text: this.$t('class 10')},
        {value: 11, text: this.$t('class 11')},
        {value: 12, text: this.$t('class 12')}
      ]
    }
  },
  mounted: function () {

    if (this.mode === 'profile') {
      this.isEditByAdmin = false
      this.localUserId = this.User.id
      this.isLoading = true
      this.refreshUserInfo()
    }
    else if(this.mode == 'adminedit') {
      this.isEditByAdmin = true
      this.localUserId = this.userid
      this.refreshUserInfo()
    }
    else {
      this.isEditByAdmin = false
      this.localUserId = this.userid
      this.getRegions()
    }
  },
  watch: {
    userid: function(newVal, oldVal ) {
          this.localUserId = newVal
          this.refreshUserInfo()   
  },
  Agreement: function (newVal/*,oldVal*/) {
      this.InfoForm.agreement = newVal
    },
  Menu (val) {
      val && setTimeout(() => (this.ActivePicker = 'YEAR'))
    },
  },
  methods: {
    getAvatarSize() {
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': return "100px"
          case 'sm': return "100px"
          case 'md': return "150px"
          case 'lg': return "150px"
          case 'xl': return "150px"
      }
    },
    getMaxWidth() {
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': return "400px"
          case 'sm': return "400px"
          case 'md': return "800px"
          case 'lg': return "800px"
          case 'xl': return "800px"
      }
    },
    refreshUserInfo() {
      this.isLoading = true
      APIService.getUserInfo(this.UserAuthToken, this.localUserId).then(
        data => {
          this.InfoForm.email = data.email
          this.InfoForm.isSchool = data.school !== ''
          this.InfoForm.surname = data.firstName
          this.InfoForm.name = data.lastName
          this.InfoForm.schoolId = data.schoolId
          this.InfoForm.afiliere = data.otherAffiliation
          this.InfoForm.dateOfBirth = this.formatDate(new Date(data.dateOfBirth))
          this.InfoForm.actingTeacher = data.actingTeacher
          this.InfoForm.telephone = data.telephoneNumber
          this.InfoForm.role = data.ocupation
          this.InfoForm.class = data.grade
          this.InfoForm.agreement = data.signedAgreement
          this.InfoForm.isSchoolAccount = data.isSchoolAccount
          this.InfoForm.isAdmin = data.isAdmin
          this.InfoForm.isActivated = data.isActivated
          var form = this.InfoForm
          var that = this
          that.getRegions().then(p => {
            if (data.schoolId == -1 || data.isSchool == false)
            {
              that.isLoading = false
            }
            else
            {
              APIService.getSchoolLink(data.schoolId).then(dt => {
                if (dt) {
                    var holdData = dt
                    if ('regionId' in holdData)
                    {
                        form.region = holdData.regionId
                        that.getCounties(holdData.regionId).then(d => {
                          if ('countyId' in holdData)
                          {
                            form.county = holdData.countyId
                            that.getCities(holdData.countyId).then(c => {
                              form.city = holdData.cityId
                              that.getSchools(holdData.cityId).then(c => {
                                form.schoolId = holdData.id
                                that.isLoading = false
                              })
                            })
                          }
                          else {
                            that.isLoading = false
                          }
                      })
                    }
                    else {
                      that.isLoading = false
                    }
                }
              })
            }
          })
        },
        error => {
          this.isLoading = false
         
          console.log(error)
        }
      )
    },
    onEmailChange(newvalue) {
      this.ruleEmailUnique = ''
    },
    resetForm() {
      this.InfoForm.password = ''
      this.InfoForm.isSchool = true
      this.InfoForm.email = ''
      this.InfoForm.surname = ''
      this.InfoForm.name = ''
      this.InfoForm.region = -1
      this.InfoForm.county = -1
      this.InfoForm.city = -1
      this.InfoForm.school = -1,
      this.InfoForm.afiliere = ''
      this.InfoForm.dateOfBirth = ''
      this.InfoForm.telephone =  ''
      this.InfoForm.actingTeacher = ''
      this.InfoForm.role = 1
      this.InfoForm.class = -1
      this.InfoForm.newsletter = true
      this.InfoForm.showTextPassword = false
      this.InfoForm.agreement = true
    },
    resetPassword() {
      APIService.startResetPassword(this.authToken, this.User.email)
    },
    activateAccount(){
      APIService.startActivateAccount(this.UserAuthToken)
    },
    shouldDisplayAlert() {
      if(this.mode=='profile' && this.User.isActivated==false)
      {
        return true
      }
      return false;
    },
    shouldDisplayForm(){
      if((this.mode=='profile' && this.IsUserActive)
      || this.mode != 'profile')
      {
        return true
      }
      return false
    },
    formatDate: function (date) {
      date = new Date(date);

      var day = ('0' + date.getDate()).slice(-2);
      var month = ('0' + (date.getMonth() + 1)).slice(-2);
      var year = date.getFullYear();

      return year + '-' + month + '-' + day;
    },
    save (date) {
        this.$refs.Menu.save(date)
    },
    changeRegion(value) {
      this.InfoForm.county = -1
      this.InfoForm.schoolId = -1
      this.InfoForm.city = -1
      this.getCounties(value)
    },
    changeCounty(value) {
      this.InfoForm.schoolId = -1
      this.InfoForm.city = -1
      this.getCities(value)
    },
    changeCity(value) {
      this.InfoForm.schoolId = -1
      this.getSchools(value)
    },
    getRegions() {
      this.regionsLoading = true
      this.regions = []
      return APIService.getRegions().then(data => {
        this.regions = data.map(el =>  {return { text:el.name, value:el.id}})
        this.regionsLoading = false
      })
    },
    getCounties (region) {
      this.countiesLoading = true
      this.counties = []
      return APIService.getCounties(region).then(data => {
        this.counties = data.map(el =>  {return { text:el.name, value:el.id}})
        this.countiesLoading = false
      })
    },
    getCities (county) {
      this.citiesLoading = true
      this.cities = []
      return APIService.getCities(county).then(data => {
        this.cities = data.map(el =>  {return { text:el.name, value:el.id}})
        this.citiesLoading = false
      })
    },
    getSchools (city) {
      this.schoolsLoading = true
      this.schools = []
      return APIService.getSchools(city).then(data => {
        this.schools = data.map(el =>  {return { text:el.name, value:el.id}})
        this.schoolsLoading = false
      })
    },
    requiredSelect (value) {
      return value != -1
    },
    ruleEmailUniqueF (value) {
      if(this.ruleEmailUnique == '')
        return true
      return this.capitalize(this.$t(this.ruleEmailUnique))
    },
    required (value) {
      if (!value) return this.capitalize(this.$t('required'))
      if (value == ' ') return this.capitalize(this.$t('required'))
      return true
    },
    ruleTelephone(v) {
      return true
    },
    nameCounter (v) {
       return v.length <= 128 || this.$t('max 128 characters')
    },
    fullNameCounter (v) {
       return v.length <= 256 || this.$t('max 256 characters')
    },
    nameRegexp(v) {
      var regName = /^[a-zA-ZșțîâăĂÂȘȚ\- \s]*$/
      if(regName.test(v)){
        return true
      }
      return this.$t('invalid name')
    },
    surnameRegexp(v) {
      var regName = /^[a-zA-ZșțîâăĂÂȘȚ\- \s]*$/
      if(regName.test(v)){
        return true
      }
      return this.$t('invalid name')
    },
    nameMin(v) {
      if (v.length < 2) return this.$t('Min 2 characters')
      return true
    },
    min (v) {
      if (v.length < 8) return this.$t('Min 8 characters')
      return true
    },
    emailMatch (v) {
      // eslint-disable-next-line no-useless-escape
      var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

      if (regEmail.test(v)) {
        return true
      } else {
        return this.$t('invalid_email')
      }
    },
    register () {
      this.$refs.form.validate()

      if(this.valid == false || this.emailMatch(this.InfoForm.email) != true)
      {
        goTo(0)
        return;
      }
      
      this.isLoading = true
      if (this.InfoForm.isSchool === false) {
        this.InfoForm.schoolId = -1
      }
      var payload = {
        'LastName': this.InfoForm.surname,
        'FirstName': this.InfoForm.name,
        'Email': this.InfoForm.email,
        'Password': this.InfoForm.password,
        'CurrentLanguage': this.CurrentLanguage,
        'DateOfBirth': this.InfoForm.dateOfBirth,
        'ActingTeacher':this.InfoForm.actingTeacher,
        'Newsletter': this.InfoForm.newsletter,
        'AvatarPic': '',
        'SignedAgreement': this.InfoForm.agreement,
        'OtherAffiliation': this.InfoForm.afiliere,
        'SchoolId': this.InfoForm.schoolId,
        'TelephoneNumber': this.InfoForm.telephone,
        'Ocupation': this.InfoForm.role,
        'Grade': this.InfoForm.class}
      var that = this
      this.isDisabledRegister = true;
      APIService.createUser(payload)
        .catch(function (error) {
          alert(error.toJSON());
        })
        .then((response) => { 
          this.isDisabledRegister = false;
          if(response.status == 200)
          {
            that.isLoading = false
            that.$store.dispatch('loginUser',
            { email: that.InfoForm.email,
              password: that.InfoForm.password})
 
          }
          else
          {
            if(response.data.message == 'Email invalid')
            {
              that.ruleEmailUnique = "user with email already exists"
              that.$refs.form.validate()
            }
            else
            {
              that.ruleEmailUnique = response.data.message
            }
            goTo(0)
            that.isLoading = false
          }
          
        })
    },
    updateProfile () {
      this.$refs.form.validate();

      if(this.valid == false)
      {
        return;
      }
      if (this.InfoForm.isSchool === false) {
        this.InfoForm.schoolId = -1
      }
      this.isLoading = true
         
      var payload = {
        'LastName': this.InfoForm.surname,
        'FirstName': this.InfoForm.name,
        'Email': this.InfoForm.email,
        'Password': this.InfoForm.password,
        'CurrentLanguage': this.CurrentLanguage,
        'DateOfBirth': this.InfoForm.dateOfBirth,
        'ActingTeacher':this.InfoForm.actingTeacher,
        'Newsletter': this.InfoForm.newsletter,
        'AvatarPic': '',
        'SignedAgreement': this.InfoForm.agreement,
        'OtherAffiliation': this.InfoForm.afiliere,
        'SchoolId': this.InfoForm.schoolId,
        'TelephoneNumber': this.InfoForm.telephone,
        'Ocupation': this.InfoForm.role,
        'Grade': this.InfoForm.class,
        'IsSchoolAccount': this.InfoForm.isSchoolAccount,
        'IsAdmin': this.InfoForm.isAdmin,
        'IsActivated': this.InfoForm.isActivated}
      var that = this

      APIService.updateUserInfo(this.UserAuthToken, payload, this.localUserId).then((data) => {
        if(that.User.id == that.localUserId) {
          that.$store.dispatch('setUserGDPR', this.InfoForm.agreement)
        }
        that.isLoading = false
      },(error) => { that.isLoading = false} )
    },
    IsValidSchool() {
      if (!this.InfoForm.isSchool)
        return true;
      var elev_only  = (this.InfoForm.actingTeacher != '' && this.InfoForm.class != -1)
      var gen_check = this.InfoForm.schoolId != -1 && this.InfoForm.region != -1 && this.InfoForm.county != -1 && this.InfoForm.city != -1 && this.InfoForm.dateOfBirth != ''; 
      if (this.InfoForm.role == 0)
        return  gen_check
      return gen_check && elev_only
    
    },
    authenticate (email, password) {
      this.isLoading = true
      APIService.getAuthToken(
        email,
        password
      ).then(
        data => {
          this.$store.commit('SET_USER_AUTH_TOKEN', data)
          this.$store.dispatch("refreshUser");
          this.isLoading = false
          this.resetForm()
        },
        error => {
          this.loginForm.isFailed = true
          this.isLoading = false
          console.log(error)
        }
      )
    }
  }
}
</script>
