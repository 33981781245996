<template>
    <v-container class="align-center" justify="center">
        <v-row justify="center" class="align-center" v-show="imageURL != null && imageURL != ''">
            <v-card outlined rounded>
                <v-card-title>
                    <v-img :loading="true" contain :src="imageURL" max-height="512px">
                        <v-btn @click="reset()" v-show="!disabled && image == null && (imageURL != '' && imageURL != null)">
                            <v-icon color="red">
                                mdi-delete
                            </v-icon>
                        </v-btn>
                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                        </template>

                    </v-img>
                </v-card-title>
            </v-card>
        </v-row>
        <v-row>
        </v-row>
        <v-row justify="center" class="align-center" v-show="imageURL == '' || imageURL == null">
            <v-card width="100%" outlined rounded :loading="loadingColor">
                <v-card-subtitle>
                    <span>{{ capitalize($t(getStepText())) }}</span>
                </v-card-subtitle>
                <v-card-title justify="center" class="align-center">
                    <client-only>
                    <image-uploader ref="imgUploader" v-if="imageURL == '' || imageURL == null" :debug="1" :maxWidth="1080" :maxHeight="1080"
                        :quality="0.7" :autoRotate=true outputFormat="blob" :preview=true
                        :className="['fileinput', { 'fileinput--loaded': hasImage }]" :capture="false" accept="image/jpeg"
                        doNotResize="['gif', 'svg']" @input="setImage" prepend-icon="mdi-camera">
                    </image-uploader>
                    </client-only>
                </v-card-title>
                <v-card-actions v-show="hasImage && !disabled">
                    <v-spacer />
                    <v-btn :color="uploadColor" @click="uploadImage">
                        <v-icon color="white">
                            mdi-cloud-upload
                        </v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-row>

    </v-container>
</template>
<script>
import APIService from '@/services/APIService'

export default {
    props: ["parent", "rules", "disabled", "species"],
    data() {
        return {
            image: null,
            hasImage: false,
            imageURL: null,
            uploadSpecies: false,
            localRules: []
        }
    },
    mounted() {
        this.imageURL = this.parent.imageURL
        if (this.species === undefined) {
            this.uploadSpecies = false;
        }
        else {
            this.uploadSpecies = true;
        }
    },
    watch: {
        parent: {
            deep: true,
            handler: function (newVal) {
                if (newVal.imageURL != null && newVal.imageURL != '') {
                    this.image = null
                    this.imageURL = newVal.imageURL
                }
            }
        }
    },
    computed: {
        uploadColor() {
            return "error"
        },
        loadingColor() {
            if (this.uploadSpecies == false)
                return "error"
            return "none"
        }
    },
    methods: {
        getStepText() {
            if (this.image == null) {
                return 'pick image by pressing the camera icon'
            }
            return 'set picture by pressing the cloud button'
        },
        reset() {
            this.imageURL = null
            this.$emit("modifyImage", { parent: this.parent, imageURL: null })
        },
        resetOutside() {
            this.hasImage = false
            this.$refs.imgUploader.imagePreview = null
            this.$refs.imgUploader.currentFile = {}
            this.$refs.imgUploader.$children[0].reset()
            this.$refs.imgUploader.$forceUpdate()
            this.image = null
            this.imageURL = null
        },
        setImage(output) {
            this.hasImage = true
            this.image = output
        },
        uploadImage() {
            var that = this
            APIService.blobToBase64(this.image)
                .then(res => {
                    var blob = res.substr(res.indexOf(',') + 1)
                    if (!that.uploadSpecies) {
                        APIService.uploadSchoolFormImage(this.UserAuthToken,
                            {
                                id: -1,
                                image: blob
                            })
                            .then(data => {
                                that.imageURL = data.imagePath
                                that.image = null
                                that.hasImage = false
                                that.$emit("modifyImage", { parent: that.parent, imageURL: data.imagePath })
                            })
                    }
                    else {
                        that.imageURL = null
                        that.image = null
                        that.hasImage = false
                        that.$emit("modifyImageData", { parent: that.parent, imageData: blob })
                    }
                })

        }
    }
}
</script>
