<template>
    <v-card :loading="isLoading">
        <v-card-text>
            <v-form 
            ref="formHranitoare" 
            v-model="valid"
            :disabled="multiButton.state == 'edit' || isLoading">
            <v-container >
                 <v-row>
                    <v-col cols=9>
                    </v-col>
                    <v-col>
                    <v-btn :color="multiButton.states[multiButton.state].color"
                    :disabled="!canPress()"
                    @click="onPress()">
                        {{capitalize($t(multiButton.states[multiButton.state].text))}}
                        <v-icon color="white">{{multiButton.states[multiButton.state].icon}}</v-icon>
                    </v-btn>
                    </v-col>
                </v-row>
            <v-row>
                <v-col>
                <image-upload-component 
                :parent="hranitoareInfo"
                :disabled="multiButton.state == 'edit' || isLoading"
                v-on:modifyImage="changeImage"
                v-model="hranitoareInfo.image" />
                <v-text-field 
                read-only
                :rules="[required]" 
                v-model="hranitoareInfo.imageURL"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                <v-select :label="capitalize($t('tip'))" v-model="birdFeederType" :items="birdFeederTypes"/>
                </v-col>
                <v-col>
                <v-select :label="capitalize($t('loc amplasament'))" v-model="birdFeederLocation" :items="birdFeederLocations"/>
                </v-col>
            </v-row>
            <v-row>
            <v-divider/>
            </v-row>
            <v-row>
                <span class="headline">{{capitalize($t('pasarile au fost hranite cu'))}}</span>
            </v-row>
            <v-row>
            <v-col>
                <v-checkbox :label="capitalize($t('seminte uleioase'))" v-model="seminteUleioase" />
            </v-col>
            <v-col>
                <v-checkbox :label="capitalize($t('fructe'))" v-model="hranaFructe" />
            </v-col>
            </v-row>

            <v-row>
            <v-col>
                <v-checkbox :label="capitalize($t('grau, porumb, malai, ovaz, tarate'))" v-model="hranaGrau" />

            </v-col>
            <v-col>
                <v-checkbox :label="capitalize($t('osanza, slanina cruda'))" v-model="hranaSlanina" />
            </v-col>
            </v-row>
            <v-row>
            <v-col>
                <v-checkbox :label="capitalize($t('bile pentru pitigoi'))" v-model="hranaBile" />
            </v-col>
            <v-col>
                <v-textarea
                prepend-icon="mdi-comment"
                rows="2"
                :label="capitalize($t('altele'))" 
                v-model="hranaAltele"/>
            </v-col>
            </v-row>
            <v-row>
                <v-divider/>
            </v-row>
            <v-row>
                <span class="headline">{{capitalize($t('perioada hrana'))}}</span>
            </v-row>
            <v-row>
            <v-col>
            <v-date-picker :disabled="multiButton.state == 'edit'" full-width v-model="perioadaHrana" elevation="1" range ></v-date-picker>
            <v-text-field :rules="[ruleDate]" disabled v-model="perioadaHrana"></v-text-field>
         
            </v-col>
            <v-col>
                <v-checkbox :label="capitalize($t('hranite vacanta'))" v-model="hranaVacanta" />
            </v-col>
            </v-row>
            <v-row>
                <v-divider/>
            </v-row>
            <v-row>
                <span class="headline">{{capitalize($t('specii observate'))}}</span>
            </v-row>
            <v-row>
                <v-combobox
                    v-model="speciiObservate"
                    :filter="filterSpeciiObservate"
                    :hide-no-data="!searchSpeciiObservate"
                    :items="toateSpeciile"
                    :search-input.sync="searchSpeciiObservate"
                    hide-selected
                    :label="capitalize($t('search for a specie'))"
                    multiple
                    small-chips
                    solo
                    >
                     <template v-slot:selection="{ attrs, item, parent, selected }">
                                        <v-chip
                                        v-if="item === Object(item)"
                                        v-bind="attrs"
                                        :color="`${item.color} lighten-3`"
                                        :input-value="selected"
                                        label
                                        small
                                        >
                                        <span class="pr-2">
                                            {{ item.text }}
                                        </span>
                                        <v-icon
                                            small
                                            @click="parent.selectItem(item)"
                                        >mdi-close</v-icon>
                                        </v-chip>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <v-chip
                                        :color="`${item.color} lighten-3`"
                                        dark
                                        label
                                        small
                                        >
                                        {{ item.text }}
                                        </v-chip>
                                        <v-spacer></v-spacer>
                                        <v-list-item-action @click.stop>

                                        </v-list-item-action>
                                    </template>
                </v-combobox>
            
            </v-row>
            </v-container>
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
import APIService from '@/services/APIService'
import ImageUploadComponent from '@/components/ImageUploadComponent'

export default {
    components: {
    'image-upload-component':ImageUploadComponent
    },
    props:['model'],
    watch: {
        model: {
            deep: true,
            handler: function (newVal, oldValue) {
            if(newVal == null){
                this.isLoading = false
                return
            }
            this.hranitoareInfo.imageURL = newVal.hranitoareInfo
            this.birdFeederType = newVal.birdFeederType
            this.birdFeederLocation = newVal.birdFeederLocation
            this.seminteUleioase = newVal.seminteUleioase
            this.hranaFructe = newVal.hranaFructe 
            this.hranaGrau = newVal.hranaGrau 
            this.hranaSlanina = newVal.hranaSlanina 
            this.hranaBile = newVal.hranaBile
            this.hranaAltele = newVal.hranaAltele
            this.perioadaHrana = newVal.perioadaHrana
            this.hranaVacanta = newVal.hranaVacanta
            this.speciiObservate = newVal.speciiObservate
            this.isLoading = false
        }
        }
    },
    mounted: function () {
        this.refreshData()
    },
    data: () => ({
        multiButton:{
            states :{
                'edit':{
                    color: 'warning',
                    text: 'edit',
                    icon: 'mdi-pencil'
                },
                'save':{
                    color: 'success',
                    text:'save',
                    icon: 'mdi-floppy'
                }
            },
            state: 'edit'
        },
        hranitoareInfo:{
            valid:true,
            image: null,
            imageURL:''
        },
        valid: false,
        isLoading: false,
        birdFeederType: 0,
        birdFeederLocation: 0,
        seminteUleioase: false,
        hranaFructe: false, 
        hranaGrau: false, 
        hranaSlanina: false, 
        hranaBile: false,
        hranaAltele: '',
        perioadaHrana:[],
        hranaVacanta:false,
        speciiObservate:'',
        searchSpeciiObservate:'',
        toateSpeciile:[]
        }),
    methods: {
        required (value) {
            if (!value) return this.capitalize(this.$t('required'))
            if (value == ' ') return this.capitalize(this.$t('required'))
            return true
        },
        changeImage(data){
            data.parent.imageURL = data.imageURL
        },
        onPress() {
            if(this.multiButton.state == 'edit') {
                this.multiButton.state = 'save'
            }
            else {
                var data ={
                     contentType: 'Hranitori',
                     birdFeederType: this.birdFeederType,
                     birdFeederLocation: this.birdFeederLocation,
                     seminteUleioase: this.seminteUleioase,
                     hranaFructe: this.hranaFructe, 
                     hranaGrau: this.hranaGrau, 
                     hranaSlanina: this.hranaSlanina, 
                     hranaBile: this.hranaBile,
                     hranaAltele: this.hranaAltele,
                     perioadaHrana: this.perioadaHrana,
                     hranaVacanta: this.hranaVacanta,
                     speciiObservate: this.speciiObservate,
                     hranitoareInfo: this.hranitoareInfo.imageURL
                }
                this.isLoading = true
                this.$emit("saveContent", data)
                this.multiButton.state = 'edit'
            }
        },
        canPress(){
            if(this.multiButton.state == 'edit') {
                return true
            }
            if(this.multiButton.state == 'save') {    
                return this.valid
            }
            return true
        },  
        ruleDate(){
            if(this.perioadaHrana.length != 2)
            {
                return this.capitalize(this.$t("required"))
            }
            return true
        },
        filterSpeciiObservate (item, queryText, itemText) {
        if (item.header) return false

        const hasValue = val => val != null ? val : ''

        const text = hasValue(itemText)
        const query = hasValue(queryText)

        return text.toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) > -1
        },
        refreshData() {
            APIService.getSpecies(this.UserAuthToken).then(species => {
            this.toateSpeciile = []

            species.forEach(specie => {
                var nomenclatureMap = [
                specie.scientificName,
                specie.englishName,
                specie.romanianName,
                specie.hungarianName
                ]
                var _text = nomenclatureMap[0]
                this.toateSpeciile.push({value:specie.id, text:_text})
            })
            })
        }
    },
    computed: {
        birdFeederTypes() {
            return [
                {text:this.capitalize(this.$t("casuta")), value:0},
                {text:this.capitalize(this.$t("tava")), value:1}
            ]
        },
        birdFeederLocations() {
            return [
                {text:this.capitalize(this.$t("pe sol")), value:0},
                {text:this.capitalize(this.$t("suspendata")), value:1}
            ]
        }
    }
}
</script>
