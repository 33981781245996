import Vue from 'vue'
import Vuex from 'vuex'
import APIService from '@/services/APIService'
import i18n from '@/plugins/i18n'
import router from './router'

Vue.use(Vuex);

function parseJwt (token) {
  if(token === undefined){
    return null
  }
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

function getRolesFromUserState(userState) {
  var userRoles = []
  if(userState.IsAdmin)
  {
    userRoles.push('admin')
  }
  if(userState.IsActivated)
  {
    userRoles.push('active')
  }
  if(userState.IsSchoolAccount)
  {
    userRoles.push('school')
  }
  return userRoles
}

function getRolesFromToken(userState, tokenData) {
  var data = parseJwt(tokenData.token)
  if(data === null)
  {
    return []
  }
  var userState = JSON.parse(data.usr.Value)
  return getRolesFromUserState(userState)
}

function populateFromToken(state, tokenData) {
  if (tokenData.token !== undefined && tokenData.token.length > 0 ) {
    var data = parseJwt(tokenData.token)
    var userState = JSON.parse(data.usr.Value)
    state.user.authToken = tokenData.token
    state.user.refreshToken = tokenData.refreshToken
    localStorage.authToken = tokenData.token
    localStorage.refreshToken = tokenData.refreshToken
    localStorage.isAdmin = userState.IsAdmin
    state.user.data.id = userState.Id
    state.user.data.lastName = userState.LastName
    state.user.data.firstName = userState.FirstName
    state.user.data.email = userState.Email
    state.currentLanguage = userState.CurrentLanguage
    state.user.data.dateOfBirth = userState.DateOfBirth
    state.user.data.newsLetter =  userState.Newsletter
    state.user.data.signedAgreement =  userState.SignedAgreement
    state.agreeGdpr = userState.SignedAgreement
    localStorage.agreeGdpr = userState.SignedAgreement
    state.user.data.otherAffiliation = userState.otherAffiliation
    state.user.data.schoolId = userState.SchoolId
    state.user.data.telephoneNumber = userState.TelephoneNumber
    state.user.data.grade = userState.Grade
    state.user.data.ocupation = userState.Ocupation
    state.user.data.isAdmin = userState.IsAdmin
    state.user.data.actingTeacher = userState.ActingTeacher
    state.user.data.isActivated = userState.isActivated
    state.user.data.IsSchoolAccount = userState.IsSchoolAccount
    state.userRoles = getRolesFromUserState(userState)

    const languageValue = state.currentLanguage
    const languageIndex = state.languagesDisp.findIndex((language) => { return language.value === languageValue }) 
    const languageCode = state.languagesDisp[languageIndex].code
    i18n.locale = languageCode
  
  } else {
    state.userType = ''
    state.user.id = -1
    state.user.data.id = -1
    state.user.authToken = ''
  }
}

export const store = new Vuex.Store({
  state: {
    languagesDisp: [
      {icon: 'us', code: 'en', value: 0},
      {icon: 'ro', code: 'ro', value: 1},
      {icon: 'hu', code: 'hu', value: 2}
    ],
    msgError: null,
    isInContest: false,
    currentLanguage: 1,
    userRoles: [],
    email: '',
    isStaff: '',
    agreeGdpr: '',
    user: {
      authToken: '',
      refreshToken: '',
      authStatus: '',
      data:{
        id : -1,
        lastName : '',
        firstName : '',
        email: '',
        dateOfBirth: '',
        newsLetter: false,
        signedAgreement: false,
        otherAffiliation: '',
        schoolId: -1,
        telephoneNumber: '',
        grade:-1,
        ocupation: 1,
        isAdmin:false,
        actingTeacher: '',
        isActivated: false,
        isSchoolAccount: false
      },
    },
    Languages: {},
    dialogAuthShow: false,
    sideBarShow: false
  },
  mutations: {
    INITIALIZE_STORE(state) {
      if(localStorage.getItem("currentLanguage")) {
        state.currentLanguage = parseInt(localStorage.currentLanguage)
      } 
      else {
        state.currentLanguage = 1
      }
      if(localStorage.getItem("email")) {
        state.email = localStorage.email
      }
      else {
        state.email = ''
      }
      if(localStorage.getItem("isStaff")) {
        state.isStaff = localStorage.isStaff
      }
      else {
        state.isStaff = false
      }
      if(localStorage.getItem("agreeGdpr")) {
        state.agreeGdpr = localStorage.agreeGdpr
      }
      else {
        state.agreeGdpr = false
      }
     
    },
    SET_IS_IN_CONTEST(state, value) {
      state.isInContest = value
    },
    SET_ERROR_MESSAGE(state, value) {
      state.msgError = value
    },
    RESET_USER_DATA(state) {
      state.user.authToken= ''
      state.user.refreshToken = ''
      state.user.authStatus = ''
      state.user.data.id = -1
      state.user.data.lastName = ''
      state.user.data.firstName = ''
      state.user.data.email = ''
      state.user.data.dateOfBirth = ''
      state.user.data.newsLetter = false
      state.user.data.otherAffiliation = ''
      state.user.data.schoolId = -1
      state.user.data.telephoneNumber = '',
      state.user.data.grade = -1
      state.user.data.ocupation = 1
      state.user.data.isAdmin = false
      state.user.data.actingTeacher =  ''
      state.user.data.isActivated = false
      state.user.data.isSchoolAccount = false
      state.isInContest = false
      state.userRoles = []
      state.email = '',
      state.isStaff = '',
      localStorage.authToken = ''
      localStorage.refreshToken = ''
      localStorage.isAdmin = 'false'
    },
    SET_USER_AUTH_STATUS(state, status) {
      state.user.authStatus = status
    },
    SET_USER_AUTH_TOKEN (state, tokenData) {
      populateFromToken(state, tokenData)
    },
    SET_LANGUAGES (state, languages) {
      state.Languages = languages
    },
    SET_USER_CURRENT_LANGUAGE (state, payload) {
      const languageValue = payload.currentLanguage
      const languageIndex = state.languagesDisp.findIndex((language) => { return language.value === languageValue }) 
      const languageCode = state.languagesDisp[languageIndex].code
      i18n.locale = languageCode
      localStorage.currentLanguage = languageValue
      state.currentLanguage = languageValue
    },
    SET_DIALOG_AUTH_SHOW (state, dialogShow) {
      state.dialogAuthShow = dialogShow
    },
    SET_SIDE_BAR_SHOW (state, sidebarshow) {
      state.sideBarShow = sidebarshow
    }
  },
  getters: {
    User (state) {
      return state.user.data
    },
    UserRefreshToken(state) {
      return state.user.refreshToken
    },
    UserAuthToken(state) {
      return state.user.authToken
    },
    IsInContest(state) {
      return state.isInContest
    },
    MsgError(state) {
      return state.msgError
    },
    CurrentLanguage (state) {
      return state.currentLanguage
    },
    UserAuthStatus (state) {
      return state.user.authStatus
    },
    AgreeGdpr (state) {
      return state.agreeGdpr
    },
    Languages (state) {
      return state.Languages
    },
    dialogAuthShow (state) {
      return state.dialogAuthShow
    },
    sideBarShow (state) {
      return state.sideBarShow
    },
    IsLoggedIn (state) {
      populateFromToken(state, {
        token: localStorage.authToken,
        refreshToken: localStorage.refreshToken
      })
      return state.user.data.id != -1
    },
    IsUserAdmin(state) {
      populateFromToken(state, {
        token: localStorage.authToken,
        refreshToken: localStorage.refreshToken
      })
      return state.userRoles.includes("admin")
    },
    IsUserSchoolAccount(state) {
      populateFromToken(state, {
        token: localStorage.authToken,
        refreshToken: localStorage.refreshToken
      })
      return state.userRoles.includes("school")
    },
    languagesDisp(state){
      return state.languagesDisp
    },
    IsUserActive(state){
      return state.userRoles.includes("active")
    }

  },
  actions: {
    refreshUserToken(context) {
      var ctx = context
      APIService.getAuthTokenRefresh(ctx.state.user.authToken,
        ctx.state.user.refreshToken).then(response => {
        if (response.data.token == null) {
          ctx.dispatch('logoutUser')
        }
        else {
          ctx.commit('SET_USER_AUTH_TOKEN', response.data)
        }
      })
    },
    setUserCurrentLanguage(context, data) {
      var ctx = context
      
      var lCode = data.currentLanguage
      var index = context.state.languagesDisp.findIndex((language) => { return language.code === lCode })
      var val =  context.state.languagesDisp[index].value
      if(typeof(val) == 'string'){
        val = parseInt(val)
      }
      if(context.state.user.data.id != -1) {
      APIService.setUserLanguage(
        context.state.user.authToken,
        {
          'Language': val
        }
      ).then(data =>{
        ctx.commit('SET_USER_AUTH_TOKEN', data)
      })
    }
    else {
      ctx.commit('SET_USER_CURRENT_LANGUAGE', 
      {
        currentLanguage: val
      })
    }
      
    },
    fetchLanguages(context) {
      var ctx = context
      APIService.getLanguages().then(languages => {
        var languagesData = []
        var languagesMap = {}
        languages.forEach(lang => {
          languagesData.push({
            text: lang.languageName,
            value: lang.id,
            lookup: lang.languageName
          })

          languagesMap[lang.id] = lang.languageName.substring(0, 2)
        })
        
      })
    },
    logoutUser(context) {
      context.commit("RESET_USER_DATA")
      context.dispatch("refreshUser")
    },
    refreshUser(context) {
      if (context.state.user.authToken === '' || context.state.user.authToken == undefined) {
        if(localStorage.authToken === '' || localStorage.authToken == undefined)
        {
          if (!router.currentRoute.path.includes("passwordreset") && !router.currentRoute.path.includes("activate"))
          {
            router.push({ name: 'HomeLayout' }).catch(err => {})
          }
          return
        }
        else {
          context.commit("SET_DIALOG_AUTH_SHOW", false)
          context.commit('SET_USER_AUTH_TOKEN', {
            token: localStorage.authToken,
            refreshToken: localStorage.refreshToken
          })
        }
        context.commit("SET_USER_CURRENT_LANGUAGE",  {
          currentLanguage: context.state.currentLanguage
        })
      }
      else {
        context.commit("SET_USER_CURRENT_LANGUAGE",  {
          currentLanguage: context.state.currentLanguage
        })
      }
    },
    setUserGDPR(context, agree) {
      var payload = {
        'SignedGDPR': agree
      }
      var ctx = context
      if (context.state.user.authToken) {
        APIService.updateGDPRUserInfo(context.state.user.authToken, payload, context.state.user.id).then((data) => {
          localStorage.agreeGdpr = agree
          context.state.agreeGdpr = agree
          context.state.user.agreeGdpr = agree
          ctx.commit('SET_USER_AUTH_TOKEN', {
            token: data.token,
            refreshToken: data.refreshToken
          })
        })
      } else {
        context.state.user.authToken = ''
        localStorage.agreeGdpr = agree
        context.state.agreeGdpr = agree
        context.state.user.agreeGdpr = agree
      }
    },
    loginUser(context, data) {
      var email = data.email
      var password = data.password
      context.commit("SET_USER_AUTH_STATUS", 'loggingIn')
      APIService.getAuthToken(
        email,
        password
      ).then(
        data => {
          context.commit('SET_USER_AUTH_TOKEN', data)
          context.commit("SET_USER_AUTH_STATUS", 'loggedIn')
          context.commit("SET_DIALOG_AUTH_SHOW", false)
        },
        error => {
          context.commit("SET_USER_AUTH_STATUS", 'error')
          console.log(error)
        })
    }
  }

})
export default store
