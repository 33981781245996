<template>
<v-card>
    <v-form
    ref="formColiziune" 
    v-model="valid"
    :disabled="multiButton.state == 'edit' || isLoading">
    <v-card-text>
        <v-container>
             <v-row>
                <v-col cols=9>
                </v-col>
                <v-col>
                <v-btn :color="multiButton.states[multiButton.state].color"
                :disabled="!canPress()"
                @click="onPress()">
                    {{capitalize($t(multiButton.states[multiButton.state].text))}}
                    <v-icon color="white">{{multiButton.states[multiButton.state].icon}}</v-icon>
                </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols = "3">
                    <v-checkbox v-model="primaSemnalareInfo.valid" :label="capitalize($t('prima semnalare'))"/>
                </v-col>
            </v-row>
            <v-row v-if="primaSemnalareInfo.valid">
                <v-col>
                    <v-text-field v-model="primaSemnalareInfo.semnalareNoua.name" :label="capitalize($t('name'))"/>
                </v-col>
                 <v-col>
                    <v-text-field v-model="primaSemnalareInfo.semnalareNoua.email" :label="capitalize($t('email'))"/>
                </v-col>
                 <v-col>
                    <v-select 
                    v-model="primaSemnalareInfo.semnalareNoua.specie" 
                    :label="capitalize($t('name'))"
                    :items="toateSpeciile"
                    />
                </v-col>
                <v-col cols=2>
                    <v-btn icon fab small @click="addSemnalare()">
                        <v-icon>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row  v-if="primaSemnalareInfo.valid">
                <v-col>
                    <v-list dense>
                    <v-list-item
                        v-for="(item, i) in primaSemnalareInfo.semnalari"
                        :key="i">
                    <v-list-item-action-text>
                        <v-btn :disabled="multiButton.state == 'edit' || isLoading" fab small icon @click="removeSemnalare(i)">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </v-list-item-action-text>
                    <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <span>{{item.email}}</span>
                    <span>{{toateSpeciile[item.specie].text}}</span>
                    </v-list-item-content>
                    </v-list-item>
                    
                    </v-list>
                </v-col>
                
            </v-row>
        <v-row v-for="item in bifeCuPoze" v-bind:key="item.id">
            <v-container>
                <v-row>
                    <v-col> 
                        <v-checkbox 
                        v-model="item.valid" 
                        :label="capitalize($t(item.text))"/> 
                    </v-col>
                </v-row>
                <v-row v-if="item.valid">  
                <v-col v-if="item.valid">  
                    <image-upload-component 
                    :disabled="multiButton.state == 'edit' || isLoading"
                    :parent="item"
                    v-on:modifyImage="changeImage"
                    v-model="item.image" />
                    <v-text-field 
                        read-only
                        :rules="[required]" 
                        v-model="item.imageURL">
                    </v-text-field>
                
                </v-col>
                </v-row>
            </v-container>
        </v-row>
        <v-row>
            <v-container>
            <v-row>
                <v-col> 
                    <v-checkbox 
                    v-model="pasariMigratoare.valid" 
                    :label="capitalize($t('ziua mondiala a pasarilor migratoare'))"/> 
                </v-col>
            </v-row>
            <v-row v-if="pasariMigratoare.valid">  
            <v-col v-if="pasariMigratoare.valid">  
                <image-upload-component 
                :disabled="multiButton.state == 'edit' || isLoading"
                :parent="pasariMigratoare"
                v-on:modifyImage="changeImage"
                v-model="pasariMigratoare.image" />
                <v-text-field 
                    read-only
                    :rules="[required]" 
                    v-model="pasariMigratoare.imageURL">
                </v-text-field>
            </v-col>
            </v-row>
            <v-row v-if="pasariMigratoare.valid"><span>{{capitalize($t('numaratori de cuiburi'))}}</span></v-row>
           
            <v-row>
                <list-text-items 
                :parent="pasariMigratoare"
                :fields="pasariMigratoare.fields"
                :v-on:modifyList="listChange"
                listName="numaratoriDeCuiburi"
                />
            </v-row>
            <v-row v-if="pasariMigratoare.valid"><span>{{capitalize($t('alte activitati'))}}</span></v-row>
            <v-row>
                <list-text-items 
                :parent="pasariMigratoare"
                :fields="pasariMigratoare.fields"
                :v-on:modifyList="listChange"
                listName="alteActivitati"
                />
            </v-row>
        </v-container>
        </v-row>
        <v-row>
        <v-checkbox v-model="springAliveAlteActivitati.valid" :label="capitalize($t('alte activitati spring alive'))"/>
        </v-row>
        <v-row>
             <list-text-items 
                :parent="springAliveAlteActivitati"
                :fields="springAliveAlteActivitati.fields"
                :v-on:modifyList="listChange"
                listName="list"
                />
        </v-row>
        </v-container>
    </v-card-text>
    </v-form>
</v-card>
</template>
<script>
import APIService from '@/services/APIService'
import ImageUploadComponent from '@/components/ImageUploadComponent'
import ListTextItems from '@/components/ListTextItems'

export default {
    props:['model'],
    watch: {
        model: function (newVal) {
            this.pasariMigratoare.valid = newVal.pasariMigratoare.valid
            this.pasariMigratoare.imageURL = newVal.pasariMigratoare.imageURL
            this.pasariMigratoare.numaratoriDeCuiburi = newVal.pasariMigratoare.numaratoriDeCuiburi
            this.pasariMigratoare.alteActivitati = newVal.pasariMigratoare.alteActivitati
            this.primaSemnalareInfo.valid = newVal.primaSemnalareInfo.valid
            this.primaSemnalareInfo.semnalari = newVal.primaSemnalareInfo.semnalari
            this.concursDesen.valid = newVal.concursDesen.valid
            this.concursDesen.imageURL = newVal.concursDesen.imageURL
            this.springAliveAlteActivitati.valid = newVal.springAliveAlteActivitati.valid
            this.springAliveAlteActivitati.imageURL = newVal.springAliveAlteActivitati.imageURL
            this.springAliveAlteActivitati.list = newVal.springAliveAlteActivitati.list
            this.bifeCuPoze.forEach(v => {
                v.valid = newVal[v.text].valid
                v.imageURL = newVal[v.text].imageURL
            })
               
            this.isLoading = false
        }
    },
  components: {
    'image-upload-component':ImageUploadComponent,
    'list-text-items':ListTextItems
    },
    mounted: function () {
        this.refreshData()
    },
    data: () => ({
        multiButton:{
            states :{
                'edit':{
                    color: 'warning',
                    text: 'edit',
                    icon: 'mdi-pencil'
                },
                'save':{
                    color: 'success',
                    text:'save',
                    icon: 'mdi-floppy'
                }
            },
            state: 'edit'
        },
        bifeCuPoze:[
        {
            id: 1,
            text: 'concurs desen',
            valid: false,
            imageData: null,
            imageURL: ''
        },
        {
            id: 2,
            text: 'ziua randunicii',
            valid: false,
            imageData: null,
            imageURL: ''
        },
        {
            id: 3,
            text: 'ziua cucului',
            valid: false,
            imageData: null,
            imageURL: ''
        },
        {
            id: 4,
            text: 'ziua prigoriei',
            valid: false,
            imageData: null,
            imageURL: ''
        },
        {
            id: 5,
            text: 'serbarea primaverii',
            valid: false,
            imageData: null,
            imageURL: ''
        },

        ],
        pasariMigratoare: {
            image:null,
            imageURL: '',
            valid:false,
            fields: ['name'],
            numaratoriDeCuiburi:[],
            alteActivitati:[],
        },
        valid: false,
        isLoading: false,
        toateSpeciile:[],
        primaSemnalareInfo: {
            valid: false,
            semnalareNoua: {
                name: '',
                email: '',
                specie: 1
            },
            semnalari:[]
        },
        
        concursDesen:{
            valid: false,
            imageData: null,
            imageURL: ''
        },
        springAliveAlteActivitati:{
            valid:false,
            fields:['name'],
            list:[]
        }
        }),
    methods: {
        required (value) {
            if (!value) return this.capitalize(this.$t('required'))
            if (value == ' ') return this.capitalize(this.$t('required'))
            return true
        },
        onPress() {
            if(this.multiButton.state == 'edit') {
                this.multiButton.state = 'save'
            }
            else {
                var data ={
                    contentType: 'SpringAlive',
                    'pasariMigratoare':{ 
                        'valid':this.pasariMigratoare.valid,
                        'imageURL': this.pasariMigratoare.imageURL,
                        'numaratoriDeCuiburi':this.pasariMigratoare.numaratoriDeCuiburi,
                        'alteActivitati': this.pasariMigratoare.alteActivitati},
                    'primaSemnalareInfo': {
                        'valid': this.primaSemnalareInfo.valid,
                        'semnalari': this.primaSemnalareInfo.semnalari
                    },
                    'concursDesen': {
                        'valid': this.concursDesen.valid,
                        'imageURL': this.concursDesen.imageURL
                    },
                    'springAliveAlteActivitati': {
                        'valid': this.springAliveAlteActivitati.valid,
                        'list': this.springAliveAlteActivitati.list
                    }
                }

                this.bifeCuPoze.forEach(v => {
                    data[v.text] = {valid: v.valid, imageURL: v.imageURL}
                })
                this.isLoading = true
                this.$emit("saveContent", data)
                this.multiButton.state = 'edit'
            }
        },
        canPress(){
            if(this.multiButton.state == 'edit') {
                return true
            }
            if(this.multiButton.state == 'save') {    
                return this.valid
            }
            return true
        }, 
        listChange(list, parent, listName) {
            parent[listName] = list
        },
        changeImage(data){
            data.parent.imageURL = data.imageURL
        },
        addSemnalare() {
              if(this.primaSemnalareInfo.semnalareNoua.name 
              && this.primaSemnalareInfo.semnalareNoua.email
              )
            {
            var addobj = JSON.parse(JSON.stringify(this.primaSemnalareInfo.semnalareNoua))
            this.primaSemnalareInfo.semnalari.push(addobj)
            this.primaSemnalareInfo.semnalareNoua.name = ''
            this.primaSemnalareInfo.semnalareNoua.email = ''
            this.primaSemnalareInfo.semnalareNoua.specie = 1
            }
        },
        removeSemnalare(i) {
            this.primaSemnalareInfo.semnalari.splice(i,1)
 
        },
        refreshData() {
            APIService.getSpecies(this.UserAuthToken).then(species => {
            this.toateSpeciile = []

            species.forEach(specie => {
                var nomenclatureMap = [
                specie.scientificName,
                specie.englishName,
                specie.romanianName,
                specie.hungarianName
                ]
                var _text = nomenclatureMap[0]
                this.toateSpeciile.push({value:specie.id, text:_text})
            })
            })
        }
    },
    computed: {

    }
}
</script>
