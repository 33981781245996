<template>
<v-card>
    <v-card-text>
        <v-form
        ref="formAlteDetalii" 
        v-model="valid"
        :disabled="multiButton.state == 'edit' || isLoading">
        <v-container>
        <v-row>
            <v-col cols=9>
            </v-col>
            <v-col>
            <v-btn :color="multiButton.states[multiButton.state].color"
            :disabled="!canPress()"
            @click="onPress()">
                {{capitalize($t(multiButton.states[multiButton.state].text))}}
                <v-icon color="white">{{multiButton.states[multiButton.state].icon}}</v-icon>
            </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-checkbox v-model="facebook" :label="capitalize($t('poze pe facebook'))"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-select v-model="scopInstitutie"
                :items="scopuriInstitutie"
                :label="capitalize($t('scop institutie'))"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-select v-model="limbaInstitutie"
                :items="limbiInstitutie"
                :label="capitalize($t('limba placuta'))"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-textarea 
                prepend-icon="mdi-comment"
                :label="capitalize($t('observatii si recomandari'))"
                v-model="observatii"
                > </v-textarea>
            </v-col>
        </v-row>
        </v-container>
        </v-form>
    </v-card-text>
</v-card>
</template>
<script>
import APIService from '@/services/APIService'
export default {
    props:['model'],
    watch: {
        model: function (newVal) {
            this.isLoading = false
            this.facebook = newVal.facebook
            this.scopInstitutie = newVal.scopInstitutie
            this.limbaInstitutie = newVal.limbaInstitutie
            this.observatii = newVal.observatii
        }
    },
    mounted: function () {
        this.refreshData()
    },
    data: () => ({
        valid:true,
        multiButton:{
            states :{
                'edit':{
                    color: 'warning',
                    text: 'edit',
                    icon: 'mdi-pencil'
                },
                'save':{
                    color: 'success',
                    text:'save',
                    icon: 'mdi-floppy'
                }
            },
            state: 'edit'
        },
        isLoading: false,
        facebook: false,
        scopInstitutie: 0,
        limbaInstitutie: 0,
        observatii:''
        }),
    methods: {
        onPress() {
            if(this.multiButton.state == 'edit') {
                this.multiButton.state = 'save'
            }
            else {
                var data ={
                    contentType: 'AlteDetalii',
                    facebook: this.facebook,
                    scopInstitutie:this.scopInstitutie,
                    limbaInstitutie:this.limbaInstitutie,
                    observatii:this.observatii 
                }
                this.isLoading = true
                this.$emit("saveContent", data)
                this.multiButton.state = 'edit'
            }
        },
        canPress(){
            if(this.multiButton.state == 'edit') {
                return true
            }
            if(this.multiButton.state == 'save') {    
                return this.valid
            }
            return true
        },  
        refreshData() {
         
        },
        
    },
    computed: {
       scopuriInstitutie(){
           return [{value:0 ,text:this.capitalize(this.$t("castig placuta"))},
            {value:1 ,text:this.capitalize(this.$t("titlul scoala prietenoasa cu natura"))}]
        },
        limbiInstitutie(){
           return [{value:0 ,text:this.capitalize(this.$t("romana"))},
            {value:1 ,text:this.capitalize(this.$t("maghiara"))}]
        }
    }
}
</script>
